import styled, { css } from 'styled-components';
import { ButtonSubmit } from '../Common/Common';

export const AdminPanelWrapper = styled.div`
	position: relative;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const LoginContainer = styled.div`
	position: absolute;
	top: 22%;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 55rem;
	height: 58rem;
	background-color: ${({ theme }) => theme.colors.primary};
	filter: drop-shadow(3px 0px 8px rgba(0, 0, 0, 0.25));
	border: 2px solid rgba(0, 0, 0, 0.4);
	border-radius: 5px;
`;

export const LogoContainer = styled.div`
	position: relative;
	margin-top: ${({ applyMargin }) => applyMargin && 3.5}rem;
`;

export const TruckImage = styled.img`
	width: ${({ small }) => small ? 21 : 30}rem;
`;

export const GutexImage = styled.img`
	position: absolute;
		width: ${({ small }) => small ? 12 : 16}rem;
	top: 40%;
	left: 33%;
`;

export const ExcelImage = styled.img`
	width: 16rem;
	height: 16rem;
	margin-top: 4.5rem;
`;

export const AdminInput = styled.input`
	width: 40rem;
	height: 5.5rem;
	margin-bottom: 3rem;
	border-radius: 5px;
	border: 2px solid ${({ theme, invalid }) =>
	invalid ? theme.colors.fontRed : 'rgba(51, 51, 51, 0.4)'};
	font-family: Roboto, sans-serif;
	font-weight: 400;
	font-size: 2.8rem;
	padding: 0 2rem;
`;

export const LoginButton = styled(ButtonSubmit)`
	font-size: 2.8rem;
	font-weight: 400;
	width: 40rem;
	height: 5.5rem;
	border-radius: 5px;
`;

export const AdminSelectionContainer = styled.div`
	position: absolute;
	top: 30%;
	display: flex;
`;

export const AdminSelection = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 5rem 3rem 0;
	width: 36rem;
	height: 33rem;
	border: 5px solid ${({ theme, excel }) =>
	excel ? theme.colors.excelGreen : theme.colors.greyDark};
	border-radius: 12px;
	font-family: Roboto, sans-serif;
	font-weight: 700;
	font-size: 3.2rem;
	color: ${({ theme, excel }) =>
	excel ? theme.colors.excelGreen : theme.colors.greyDark};
	
	&:after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 10;
		transition: all 0.2s;
		background-color: transparent;
	}
	
	&:hover:after,
	&:focus:after {
		background-color: ${({ theme, excel }) =>
			excel ? theme.colors.optionExcelHover : theme.colors.optionGreyHover};
	}
`;

export const TitleWrapper = styled.div`
	position: absolute;
	display: flex;
	bottom: 4.5rem;
`;

export const SelectionTitleLight = styled.div`
	margin-left: 1rem;
	font-weight: 400;
`;

export const WarehouseControlContainer = styled.div`
	display: flex;
	align-items: flex-start;
	margin-bottom: 8rem;
	margin-top: 20rem;
	
	@media (max-width: 80rem) {
		flex-direction: column;
		align-items: center;
	}
`;

export const WarehouseComponent = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: ${({ theme }) => theme.colors.primary};
	border: 2px solid rgba(0, 0, 0, 0.4);
	border-radius: 5px;
	margin: 0 4rem;
	
	@media (max-width: 80rem) {
		margin: 0 4rem 4rem;
	}
`;

export const CurrentWarehouses = styled(WarehouseComponent)`
	width: 48rem;
`;

export const WarehouseProvision = styled(WarehouseComponent)`
	width: 57rem;
`;

export const Divider = styled.span`
	border-top: 3px solid black;
	width: calc(100% - 16rem);
	margin-top: 1rem;
	margin-bottom: 3.5rem;
`;

export const WarehouseOption = styled.div`
	font-family: Roboto, sans-serif;
	font-weight: 700;
	font-size: 3.8rem;
	margin-top: 8rem;
`;

export const WarehouseButton = styled(ButtonSubmit)`
	font-weight: 500;
	font-size: 2.5rem;
	border-radius: 5px;
	height: 5.5rem;
	width: calc(100% - 16rem);
	margin-bottom: 8rem;
`;

export const RemoveButton = styled(WarehouseButton)`
	background-color: ${({ theme }) => theme.colors.primaryRed};
	
	&:focus,
	&:hover {
		background-color: ${({ theme }) => theme.colors.primaryRedHover};
	}
`;

export const ProvideButton = styled(WarehouseButton)``;

export const WarehouseList = styled.div`
	display: flex;
	flex-direction: column;
	width: calc(100% - 16rem);
	font-family: Roboto, sans-serif;
	font-weight: 400;
	font-size: 3.2rem;
	line-height: 1;
`;

export const RadioWrapper = styled.div``;

export const ListRadio = styled.span`
	position: relative;
	display: inline-block;
	width: 2rem;
	height: 2rem;
	border: 1.5px solid ${({ theme, isDefault }) =>
	isDefault ? theme.colors.greyMid : 'black'};
	border-radius: 50%;
	margin-right: 2.5rem;
	background-color: ${({ theme, isDefault }) => isDefault && theme.colors.greyMid};
	
	${({ selected }) => selected && css`
		&:after {
			content: "";
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 1.2rem;
			height: 1.2rem;
			background-color: black;
			border-radius: 50%;
		}
	`};
`;

export const WarehouseListItem = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 2rem;
	margin-left: ${({ addMargin }) => addMargin && '4.5rem'};
	color: ${({ theme, isDefault }) => isDefault && theme.colors.greyMid};
	
	:last-child {
		margin-bottom: 3.5rem;
	}
`;

export const ExcelProvisionContainer = styled.div`
	display: flex;
	
	@media (max-width: 100rem) {
		flex-direction: column;
		align-items: center;
		margin-bottom: 4rem;
	}
`;

export const AddedSpace = styled.div`
	@media(max-width: 100rem) {
		height: 4rem;
	}
`;

export const DragDropZone = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 60rem;
	height: 55rem;
	background-color: ${({ theme }) => theme.colors.primary};
	border: 2px dashed rgba(0, 0, 0, 0.7);
	color: ${({ theme, isDrag }) => isDrag ? 'black' : theme.colors.primaryGreen};
	font-family: Roboto, sans-serif;
	font-weight: ${({ isDrag }) => isDrag ? 400 : 700};
	font-size: 3.2rem;
	margin-right: 8rem;
	
	@media (max-width: 100rem) {
		margin-right: 0;
		margin-bottom: 4rem;
	}
	
	${({ isDrag }) => isDrag && css`
		&:after {
			position: absolute;
			top: 0;
			left: 0;
			content: "";
			width: 100%;
			height: 100%;
			background-color: rgba(99, 141, 46, 0.2);
		}
	`};
`;

export const FileNameWrapper = styled.div`
	position: relative;
	margin: 0 7.5rem;
	text-align: center;
`;

export const RemoveFileButton = styled.button`
	position: absolute;
	top: 0;
	right: 0;
	height: 3rem;
	width: 3rem;
	border-radius: 50%;
	transform: translate(4rem, -2rem);
	background-color: ${({ theme }) => theme.colors.primaryRed};
	border: none;
	transition: all .2s;
	
	&:hover,
	&:focus {
		outline: 0;
		background-color: ${({ theme }) => theme.colors.primaryRedHover};		
	}
`;

export const XSpan = styled.span`
	position: absolute;
	display: inline-block;
	width: 2rem;
	height: 2px;
	background-color: white;
	top: 50%;
	left: 50%;
`;

export const XSpanLeft = styled(XSpan)`
	transform: translate(-50%, -50%) rotate(45deg);
`;

export const XSpanRight = styled(XSpan)`
	transform: translate(-50%, -50%) rotate(-45deg);
`;

export const DiffBox = styled.div`
	width: 85rem;
	height: 55rem;
	background-color: ${({ theme }) => theme.colors.primary};
	border: 2px solid rgba(0, 0, 0, 0.4);
	border-radius: 5px;
`;

export const DiffTitleContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	font-family: Roboto, sans-serif;
	font-weight: 700;
	font-size: 3.7rem;
	padding: 5.5rem 3rem 4.5rem 8rem;
	width: 100%;
	height: 100%;
`;

export const DiffDivider = styled(Divider)`
	width: 35rem;
	transform: translateX(-2.5rem);
	margin-top: 0.5rem;
`;

export const DiffTitle = styled.div`
	font-size: 3.2rem;
`;

export const ApplyChangesButton = styled(ButtonSubmit)`
	width: 30rem;
	height: 6rem;
	font-size: 3.2rem;
	font-weight: 500;
`;

export const DiffContainer = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
`;

export const ApplyButton = styled(ButtonSubmit)`
	font-weight: 400;
	font-size: 3.2rem;
	height: 9rem;
	width: 28rem;
	margin-top: 6rem;
`;

export const Message = styled.p`
	font-family: Roboto, sans-serif;
	font-size: 3.2rem;
	font-weight: 400;
	margin: 0;
`;

export const ChangesError = styled(Message)`
	font-weight: 700;
	color: ${({ theme }) => theme.colors.fontRed};
`;

export const ErrorMessage = styled(Message)`
	color: ${({ theme }) => theme.colors.fontRed};
	width: 100%;
`;

export const ChangesList = styled.div`
	height: 100%;
	overflow-y: scroll;
`;

export const DecorationWrapper = styled.span``;

export const ListItemDecoration = styled.span`
	display: inline-block;
	height: 1.5rem;
	width: 1.5rem;
	background-color: black;
	margin-right: 2.5rem;
	border-radius: 50%;
`;

export const ListItem = styled(Message)`
	display: flex;
	color: black;
	margin-right: 3rem;
`;

export const ExcelWrapper = styled.div`
	display: grid;
	position: absolute;
	top: 18rem;
`;

export const DownloadExcel = styled.button`
	font-family: Roboto, sans-serif;
	font-weight: 500;
	font-size: 2.5rem;
	color: ${({ theme }) => theme.colors.greyLight5};
	justify-self: flex-end;
	background-color: transparent;
	border: none;
	border-bottom: 2px solid currentColor;
	display: flex;
	align-items: center;
	margin-bottom: 1.5rem;
	padding-bottom: 0.3rem;
	
	svg {
		width: 2.5rem;
		height: 2.5rem;
		margin: -0.5rem 1rem 0 0;
		fill: currentColor;
	}
	
	@media(max-width: 100rem) {
		justify-self: center;
	}
	
	&:focus {
		color: ${({ theme }) => theme.colors.primaryRed};
	}
`;
