import styled, { css } from 'styled-components';

export const ScannerScreenWrapper = styled.div`
	position: relative;
	height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: ${({ theme, red }) => red ? theme.colors.primaryPink : theme.colors.primary};
`;

export const ScannerImage = styled.img`
	width: 32rem;
`;

export const ScanContainer = styled.div`
	display: grid;
	justify-items: center;
	grid-row-gap: 3rem;
	
	${({ isMobile }) => isMobile && css`
		${ScannerImage} {
			width: 9rem;
			margin-top: 6rem;
		}
	`}
`;

export const BarcodeField = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 44rem;
	height: 7.5rem;
	background-color: ${({ theme }) => theme.colors.primaryWhite};
	border-radius: 3px;
	border: 3px solid ${({ theme }) => theme.colors.primaryGrey};
	
	svg	{
		width: 8rem;	
		height: 4rem;
		fill: ${({ theme }) => theme.colors.primaryGrey};
		margin-right: 3rem;
	}
`;

export const BarcodeInput = styled.input`
	background-color: transparent;
	font-family: Roboto, sans-serif;
	font-weight: 400;
	font-size: 4.2rem;
	color: ${({ theme }) => theme.colors.primaryGrey};
	border: none;
	width: ${({ isScanned }) => isScanned ? '40rem' : '16rem'};
	text-align: center;

	::placeholder {
		color: ${({ theme }) => theme.colors.primaryGrey};
		opacity: 1;
	}
	
	&:focus {
		outline: none;
	}
`;

export const QuickModeMessage = styled.div`
	font-family: Roboto, sans-serif;
	font-size: 3.2rem;
	color: black;
	font-weight: 400;
	text-align: center;
	
	strong {
		font-weight: 700;
		color: ${({ theme }) => theme.colors.primaryRed};
	}
`;

export const CheckDeliveryButton = styled.button`
  position: absolute;
	top: 12rem;
	right: 5rem;
	font-weight: 700;
	font-family: Roboto, sans-serif;
	font-size: 2.7rem;
	color: black;
	background: none;
	border: none;
	border-bottom: 2px solid black;
`;
