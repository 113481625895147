import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
	AdminPanelWrapper,
	AdminSelection,
	AdminSelectionContainer, ExcelImage, GutexImage, LogoContainer,
	SelectionTitleLight, TitleWrapper, TruckImage
} from '../../styles/AdminPanel/AdminPanel';
import TruckIcon from '../../../../icons/truck.png';
import GutexLogo from '../../../../icons/gutex_logo.png';
import ExcelIcon from '../../../../icons/excel.png';
import { PATHS, STRINGS } from '../../../constants';
import { destinationActions } from '../../../state/ducks/destination';

const AdminMenu = () => {
	const history = useHistory();
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(destinationActions.fetchAdminDestinations());
	}, [dispatch]);

	const handleWarehouseClicked = () => {
		history.push(PATHS.WAREHOUSE);
	};

	const handleExcelClicked = () => {
		history.push(PATHS.EXCEL);
	};

	return (
		<AdminPanelWrapper>
			<AdminSelectionContainer>
				<AdminSelection onClick={handleWarehouseClicked}>
					<LogoContainer applyMargin>
						<TruckImage small src={TruckIcon}/>
						<GutexImage small src={GutexLogo}/>
					</LogoContainer>
					<TitleWrapper>
						{STRINGS.WAREHOUSE}
						<SelectionTitleLight>{STRINGS.MANAGE}</SelectionTitleLight>
					</TitleWrapper>
				</AdminSelection>
				<AdminSelection excel onClick={handleExcelClicked}>
					<LogoContainer>
						<ExcelImage src={ExcelIcon}/>
					</LogoContainer>
					<TitleWrapper>
						{STRINGS.EXCEL}
						<SelectionTitleLight>{STRINGS.UPLOAD}</SelectionTitleLight>
					</TitleWrapper>
				</AdminSelection>
			</AdminSelectionContainer>
		</AdminPanelWrapper>
	);
};

export default AdminMenu;
