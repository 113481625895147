import styled, { css } from 'styled-components';

export const BottomBarWrapper = styled.div`
	display: flex;
	align-items: center;
	position: fixed;
	width: 100%;
	height: 9rem;
	bottom: 0;
	box-shadow: 0 2px 9px 5px rgba(0, 0, 0, 0.15);
	background-color: ${({ theme }) => theme.colors.primary};
`;

export const SwitchesWrapper = styled.div`
	display: flex;
	margin-right: auto;
	margin-left: 4.8rem;
`;

export const Switch = styled.div`
	display: flex;
	align-items: center;
	height: 6rem;
	margin-right: 2.5rem;
	border-radius: 100px;
	width: 12rem;
	padding: 0 5px;
	border: 2px solid ${({ theme }) => theme.colors.primaryGrey};
	
	svg {
		width: 4rem;
		fill: ${({ theme }) => theme.colors.primaryGrey};
	}
`;

export const AppThemeSwitch = styled(Switch)``;

export const QuickModeSwitch = styled(Switch)`
	border: 2px solid ${({ theme, quickMode }) => quickMode ? theme.colors.primaryRed : theme.colors.primaryGrey};
`;

export const IconWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 4.8rem;
	width: 4.8rem;
	border-radius: 100px;
	margin-left: 0;
	margin-right: calc(100% - 4.8rem);
	transition: all .3s;
	
	${({ toggled }) => toggled && css`
		margin-left: calc(100% - 4.8rem);
		margin-right: 0;
	`};
`;

export const SleepIconWrapper = styled(IconWrapper)`
	background-color: ${({ theme }) => theme.colors.greyLight};
	border: 2px solid ${({ theme }) => theme.colors.primaryGrey};
`;

export const QuickIconWrapper = styled(IconWrapper)`
	background-color: ${({ theme, toggled }) => toggled ? theme.colors.primaryRed : theme.colors.greyLight};
	border: 2px solid ${({ theme, toggled }) => toggled ? theme.colors.primaryRed : theme.colors.primaryGrey};
	
	svg {
		width: ${({ toggled }) => toggled ? 4 : 3.6}rem;
		fill: ${({ theme, toggled }) => toggled ? theme.colors.primaryWhite : theme.colors.primaryGrey};
	}
`;

export const InfoText = styled.div`
	display: flex;
	align-items: center;
	height: 100%;
	color: ${({ theme, black }) => black ? 'black' : theme.colors.fontGrey};
	font-size: 4.2rem;
	font-weight: 500;
	font-family: Roboto, sans-serif;
	margin-right: 4.8rem;
`;
