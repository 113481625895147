import * as TYPES from './types';

export const fetchRides = () => ({
	type: TYPES.FETCH_RIDES
});

export const setRides = (payload) => ({
	type: TYPES.SET_RIDES,
	payload
});

export const deleteRide = (payload) => ({
	type: TYPES.DELETE_RIDE,
	payload
});

export const deleteRideFromList = (payload) => ({
	type: TYPES.DELETE_RIDE_FROM_LIST,
	payload
});

export const sendRideToIndexedDb = (payload) => ({
	type: TYPES.SEND_RIDE_TO_INDEXED_DB,
	payload
});

export const setCurrentRideId = (payload) => ({
	type: TYPES.SET_CURRENT_RIDE_ID,
	payload
});

export const setCurrentRideTimestamp = (payload) => ({
	type: TYPES.SET_CURRENT_RIDE_TIMESTAMP,
	payload
});

export const updateRide = (payload) => ({
	type: TYPES.UPDATE_RIDE,
	payload
});

export const removeFromDb = (payload) => ({
	type: TYPES.REMOVE_FROM_DB,
	payload
});

export const resendRides = (payload) => ({
	type: TYPES.RESEND_RIDES,
	payload
});
