import { createSelector } from 'reselect';
import { DATABASE } from '../../../constants';

export const getPreviousRides = createSelector(
	[({ rides }) => rides.previousRides],
	(rides) => rides
);

export const getCurrentRideId = createSelector(
	[({ rides }) => rides.currentRideId],
	(id) => id
);

export const getCurrentRideTimestamp = createSelector(
	[({ rides }) => rides.currentRideTimestamp],
	(timestamp) => timestamp
);

export const getOfflineRidesAmount = createSelector(
	[getPreviousRides],
	(rides) => rides?.filter(({ dbType }) =>
		[DATABASE.TYPE_POST, DATABASE.TYPE_PUT].includes(dbType)).length || 0
);
