import React from 'react';
import { useSelector } from 'react-redux';
import { pluck } from 'ramda';
import moment from 'moment';
import PreviousRidesItem from '../../components/PreviousRidesScreen/PreviousRidesItem';
import { trucksSelectors } from '../../../state/ducks/trucks';
import { destinationSelectors } from '../../../state/ducks/destination';
import { RideItemWrapper } from './ModalSlowInternet';

const NotSendRides = ({ ridesData = [] }) => {
	const trucks = useSelector(trucksSelectors.getTrucks) || [];
	const locations = useSelector(destinationSelectors.getDestinations) || [];

	const getTimeValues = (timestamp) => {
		const date = moment(timestamp).format('DD.MM.yyyy').toLocaleString();
		const hour = moment(timestamp).format('HH:mm').toLocaleString();

		return [hour, date];
	};

	const addDetailsToRide = (ride) => {
		const {
			truck,
			start_time: startTime,
			start_location: startLocation,
			ride_items: rideItems
		} = ride;

		const mapRideItems = (items) => items.map(({
			product,
			amount,
			end_location: endLocation,
			product_details: productDetails,
			...rest
		}) => ({
			product: {
				...productDetails,
				amount
			},
			end_location: locations.find(({ id }) => id === endLocation),
			...rest
		}));

		return {
			truck: trucks.find(({ id }) => id === truck),
			start_time: startTime,
			start_location: locations.find(({ id }) => id === startLocation),
			ride_items: mapRideItems(rideItems)
		};
	};

	return (
		<RideItemWrapper>
			{ridesData.map((item, index) => {
				const withDetails = addDetailsToRide(item);
				const articles = pluck('product', withDetails.ride_items);
				const destinations = withDetails.ride_items.map(({ end_location: { name } = {} }) => name);
				const [hour, date] = getTimeValues(item.start_time);

				return (
					<PreviousRidesItem
						key={index}
						destinations={destinations}
						articles={articles}
						hour={hour}
						date={date}
						item={withDetails}
					/>
				);
			})}
		</RideItemWrapper>
	);
};

export default NotSendRides;
