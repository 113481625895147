import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
	DropdownWrapper,
	IconsContainer,
	InputContainer,
	LoginButton,
	LoginContainer,
	LoginScreenWrapper,
	LogoImage,
	PasswordInput,
	TruckImage
} from '../../styles/LoginScreen/LoginScreen';
import GutexLogo from '../../../../icons/logo_full.png';
import TruckIcon from '../../../../icons/truck-loaded.png';
import DropdownCustom from '../Dropdown/DropdownCustom';
import { PATHS, STRINGS } from '../../../constants';
import DelayedButton from '../Common/DelayedButton';
import { ReactComponent as DownArrow } from '../../../../icons/down_arrow2.svg';
import { authActions, authSelectors } from '../../../state/ducks/authentication';

const LoginScreen = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const passwordValid = useSelector(authSelectors.getPasswordValid);
	const token = useSelector(authSelectors.getToken);
	const usernames = useSelector(authSelectors.getUsernames);

	const [selectedUser, setSelectedUser] = useState('');
	const [password, setPassword] = useState('');
	const [loginAttempted, setLoginAttempted] = useState(false);

	const attemptLogin = useCallback(() => {
		document.activeElement.blur();
		setLoginAttempted(true);
		dispatch(authActions.attemptLogin({ username: selectedUser, password }));
	}, [dispatch, password, selectedUser]);

	useEffect(() => {
		if (loginAttempted && passwordValid && token) {
			history.push(PATHS.RIDES_MENU);
		}
	}, [history, loginAttempted, passwordValid, token]);

	const handleSubmitOnEnter = useCallback((event) => {
		if (event.key === 'Enter') {
			attemptLogin();
		}
	}, [attemptLogin]);

	useEffect(() => {
		window.addEventListener('keyup', handleSubmitOnEnter);
		return () => window.removeEventListener('keyup', handleSubmitOnEnter);
	}, [handleSubmitOnEnter]);

	return (
		<LoginScreenWrapper>
			<LoginContainer>
				<IconsContainer>
					<LogoImage src={GutexLogo}/>
					<TruckImage src={TruckIcon}/>
				</IconsContainer>
				<InputContainer>
					<DropdownWrapper selected={!!selectedUser}>
						<DropdownCustom
							items={usernames}
							placeholder={STRINGS.USER_ROLE}
							updateValue={setSelectedUser}
							downArrow={DownArrow}
						/>
					</DropdownWrapper>
					<PasswordInput
						type="password"
						placeholder={STRINGS.PASSWORD}
						onChange={(e) => setPassword(e.target.value)}
						valid={passwordValid}
					/>
					<DelayedButton onClick={attemptLogin} component={LoginButton}>
						{STRINGS.LOG_IN}
					</DelayedButton>
				</InputContainer>
			</LoginContainer>
		</LoginScreenWrapper>
	);
};

export default LoginScreen;
