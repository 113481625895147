import { createSelector } from 'reselect';

export const getToken = createSelector(
	[({ authentication }) => authentication.token],
	(token) => token
);

export const getAdminToken = createSelector(
	[({ authentication }) => authentication.adminToken],
	(token) => token
);

export const getPasswordValid = createSelector(
	[({ authentication }) => authentication.passwordValid],
	(passwordValid) => passwordValid
);

export const getUsername = createSelector(
	[({ authentication }) => authentication.username],
	(username) => username
);

export const getGutexUsers = createSelector(
	[({ authentication }) => authentication.gutexUsers],
	(users) => users
);

export const getUsernames = createSelector(
	[getGutexUsers],
	(users) => users?.map(({ username }) => username) || []
);

export const getTruckId = createSelector(
	[({ authentication }) => authentication.truckId],
	(truckId) => truckId
);

export const getTruckTokens = createSelector(
	[({ authentication }) => authentication.truckTokens],
	(tokens) => tokens
);

export const getTruckToken = createSelector(
	[getTruckId, getTruckTokens],
	(id, tokens) => tokens[id]
);

export const isAdminLoggedIn = createSelector(
	[getUsername, getGutexUsers],
	(name, users) => users?.find(({ username }) => username === name).is_gutex_admin
);

export const isDriverLoggedIn = createSelector(
	[getUsername, getGutexUsers],
	(name, users) => users?.find(({ username }) => username === name).is_lkw_fahrer_only
);
