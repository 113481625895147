import React from 'react';
import { DatePickerContainer, DatePickerInput, FilterLabel } from '../../../styles/Modal/ModalReports';
import { STRINGS } from '../../../../constants';
import { useReportControlsContext } from './useReportControlsContext';

export const DATE_PICKER_TYPES = {
	START: 'start',
	END: 'end'
};

const DatePicker = ({
	type,
	updater = () => {
	}
}) => {
	const { startDate, setStartDate, endDate, setEndDate } = useReportControlsContext();

	const labels = {
		[DATE_PICKER_TYPES.START]: `${STRINGS.FROM}:`,
		[DATE_PICKER_TYPES.END]: `${STRINGS.TO}:`
	};

	const inputValue = {
		[DATE_PICKER_TYPES.START]: startDate,
		[DATE_PICKER_TYPES.END]: endDate
	};

	const onChangeHandlers = {
		[DATE_PICKER_TYPES.START]: setStartDate,
		[DATE_PICKER_TYPES.END]: setEndDate
	};

	return (
		<DatePickerContainer>
			<FilterLabel>{labels[type] || ''}</FilterLabel>
			<DatePickerInput
				type={'date'}
				value={inputValue[type]}
				onChange={(e) => {
					onChangeHandlers[type](e.target.value);
					updater(e.target.value);
				}}
			/>
		</DatePickerContainer>
	);
};

export default DatePicker;
