export const SET_TOKEN = 'SET_TOKEN';
export const SET_ADMIN_TOKEN = 'SET_ADMIN_TOKEN';
export const CHECK_STORAGE_FOR_TOKEN = 'CHECK_STORAGE_FOR_TOKEN';
export const ATTEMPT_LOGIN = 'ATTEMPT_LOGIN';
export const SET_PASSWORD_VALIDITY = 'SET_PASSWORD_VALIDITY';
export const SET_USERNAME = 'SET_USERNAME';
export const ATTEMPT_LOGOUT = 'ATTEMPT_LOGOUT';
export const FETCH_GUTEX_USERS = 'FETCH_GUTEX_USERS';
export const SET_GUTEX_USERS = 'SET_GUTEX_USERS';
export const SET_TRUCK_TOKENS = 'SET_TRUCK_TOKENS';
export const SET_TRUCK_ID = 'SET_TRUCK_ID';
