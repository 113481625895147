import React from 'react';
import { useSelector } from 'react-redux';
import {
	ButtonApplyBig,
	ButtonsContainerCustom,
	ModalContent,
	ModalTextInvoiceZeroItems
} from '../../styles/Modal/Modal';
import DelayedButton from '../Common/DelayedButton';
import { MODAL, STRINGS } from '../../../constants';
import { modalSelectors } from '../../../state/ducks/modal';

const ModalDamagedInvoice = ({
	showModal,
	handleDismiss
}) => {
	const modalType = useSelector(modalSelectors.getModalType);

	const getInvoiceText = () => {
		switch (modalType) {
			case MODAL.INVOICE_ZERO_ITEMS:
				return STRINGS.INVOICE_ZERO_ITEMS;
			case MODAL.INVOICE_IN_PROGRESS:
				return STRINGS.INVOICE_IN_PROGRESS_MSG;
			default:
				return '';
		}
	};

	return (
		<ModalContent show={showModal}>
			<ModalTextInvoiceZeroItems dangerouslySetInnerHTML={{
				__html: getInvoiceText()
			}}/>
			<ButtonsContainerCustom marginTop={4}>
				<DelayedButton onClick={handleDismiss} component={ButtonApplyBig}>
					{STRINGS.END_PROCESS}
				</DelayedButton>
			</ButtonsContainerCustom>
		</ModalContent>
	);
};

export default ModalDamagedInvoice;
