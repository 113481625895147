import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { includes } from 'ramda';
import {
	ButtonNo,
	ButtonOkay,
	ButtonsContainer,
	ButtonYes,
	ModalContainer,
	ModalContent,
	ModalText
} from '../../styles/Modal/Modal';
import { modalActions, modalSelectors } from '../../../state/ducks/modal';
import { ADMIN_ROUTES, CACHE, MODAL, PATHS, STRINGS } from '../../../constants';
import { authActions, authSelectors } from '../../../state/ducks/authentication';
import { productsActions } from '../../../state/ducks/products';
import { ridesActions, ridesSelectors } from '../../../state/ducks/rides';
import { generalSelectors } from '../../../state/ducks/general';
import DelayedButton from '../Common/DelayedButton';
import ModalOfflineRidesContent from './ModalOfflineRidesContent';
import ModalDatabaseAccepted from './ModalDatabaseAccepted';
import ModalNotes from './ModalNotes';
import ModalSyncWithAbas from './ModalSyncWithAbas';
import ModalResetInvoice from './ModalResetInvoice';
import ModalInvoice from './ModalInvoice';
import ModalDamagedInvoice from './ModalDamagedInvoice';
import { deliveriesActions } from '../../../state/ducks/deliveries';
import { useEnterClicked } from '../../../utils/hooks/useEnterClicked';
import ModalSuccessfullySubmitted from './ModalSuccessfullySubmitted';
import ModalReports from './ModalReports/ModalReports';
import ModalSlowInternet from './ModalSlowInternet/ModalSlowInternet';
import ModalCheckDelivery from './ModalCheckDelivery';
import ModalRidesReports from './ModalReports/ModalRidesReports';

const Modal = () => {
	const history = useHistory();
	const { pathname } = useLocation();
	const dispatch = useDispatch();
	const showModal = useSelector(modalSelectors.getShowModal);
	const modalType = useSelector(modalSelectors.getModalType);
	const token = useSelector(authSelectors.getToken);
	const adminToken = useSelector(authSelectors.getAdminToken);
	const selectionId = useSelector(modalSelectors.getDeleteSelectionId);
	const rideData = useSelector(modalSelectors.getDeleteRideData);
	const username = useSelector(authSelectors.getUsername) || STRINGS.ADMIN;
	const isEditRide = useSelector(generalSelectors.isEditRide);
	const showOk = [MODAL.TOO_MUCH_PALLETS, MODAL.DATABASE_ACCEPTED].includes(modalType);
	const offlineRides = useSelector(ridesSelectors.getOfflineRidesAmount);

	const showGeneric = [
		MODAL.TOO_MUCH_PALLETS,
		MODAL.DELETE_RIDE,
		MODAL.CANCEL_RIDE,
		MODAL.LOGOUT,
		MODAL.DELETE_PRODUCT
	].includes(modalType);

	const isAdminRoute = useMemo(() =>
			ADMIN_ROUTES.some((route) => route === pathname),
		[pathname]
	);

	const handleYesClicked = async () => {
		switch (modalType) {
			case MODAL.LOGOUT: {
				await caches.delete(CACHE.CACHE_NAME);
				dispatch(authActions.attemptLogout({ token: token || adminToken }));
				window.location.reload();
				break;
			}
			case MODAL.CANCEL_RIDE: {
				dispatch(productsActions.cancelRide());
				if (isEditRide) {
					history.push(PATHS.PREVIOUS_RIDES);
				} else
					history.push(PATHS.RIDES_MENU);
				break;
			}
			case MODAL.DELETE_PRODUCT: {
				dispatch(productsActions.deleteProductFromCart({ selectionId }));
				break;
			}
			case MODAL.DELETE_RIDE: {
				dispatch(ridesActions.deleteRide(rideData));
				history.push(PATHS.PREVIOUS_RIDES);
				break;
			}
			case MODAL.TOO_MUCH_PALLETS:
			default:
				break;
		}

		dispatch(modalActions.hideModal());
	};

	const handleDismiss = () => {
		switch (modalType) {
			case MODAL.DATABASE_ACCEPTED:
				dispatch(modalActions.hideModal());
				dispatch(productsActions.setExcelChanges(null));
				break;
			case MODAL.INVOICE_IN_PROGRESS:
			case MODAL.INVOICE_IN_USE:
			case MODAL.INVOICE_QUANTITY_DIFFERS:
				dispatch(modalActions.hideModal());
				dispatch(deliveriesActions.clearInvoice());
				dispatch(deliveriesActions.setSubmissionStatus({ submissionStatus: null }));
				history.push(PATHS.SCAN_INVOICE);
				break;
			case MODAL.CHECK_DELIVERY:
				dispatch(modalActions.hideModal());
				dispatch(deliveriesActions.clearInvoiceData());
				break;
			default:
				dispatch(modalActions.hideModal());
		}
	};

	const getModalText = () => {
		switch (modalType) {
			case MODAL.TOO_MUCH_PALLETS:
				return STRINGS.TO_MUCH_PALLETS_MSG;
			case MODAL.LOGOUT:
				return `${username} ${STRINGS.LOGOUT_MSG}`;
			case MODAL.CANCEL_RIDE:
				return isEditRide ? STRINGS.CANCEL_RIDE_EDIT_MSG : STRINGS.CANCEL_RIDE_MSG;
			case MODAL.DELETE_PRODUCT:
				return STRINGS.DELETE_ARTICLE_MSG;
			case MODAL.DELETE_RIDE:
				return STRINGS.DELETE_RIDE_MSG;
			case MODAL.DATABASE_ACCEPTED:
				return STRINGS.DATABASE_ACCEPTED_MSG;
			default:
				return '';
		}
	};

	useEnterClicked(() => {
		if (includes(modalType, [
			MODAL.TOO_MUCH_PALLETS,
			MODAL.DATABASE_ACCEPTED,
			MODAL.INVOICE_ALREADY_USED,
			MODAL.INVOICE_NOT_EXISTS,
			MODAL.INVOICE_ZERO_ITEMS,
			MODAL.INVOICE_IN_PROGRESS,
			MODAL.SUCCESSFULLY_BOOKED,
			MODAL.INVOICE_IN_USE,
			MODAL.INVOICE_QUANTITY_DIFFERS
		])) {
			handleDismiss();
		}
	});

	return (
		<ModalContainer show={showModal}>
			{offlineRides && <ModalOfflineRidesContent
				handleDismiss={handleDismiss}
				offlineAmount={offlineRides}
				showModal={showModal && modalType === MODAL.LOGOUT}
			/>}
			<ModalDatabaseAccepted
				showModal={showModal && modalType === MODAL.DATABASE_ACCEPTED}
				handleDismiss={handleDismiss}
			/>
			<ModalNotes
				showModal={showModal && modalType === MODAL.MAKE_NOTE}
				handleDismiss={handleDismiss}
			/>
			<ModalSyncWithAbas
				showModal={showModal && modalType === MODAL.ABAS_SYNC}
				handleDismiss={handleDismiss}
			/>
			<ModalResetInvoice
				showModal={showModal && modalType === MODAL.RESET_INVOICE}
				handleDismiss={handleDismiss}
			/>
			<ModalInvoice
				showModal={showModal && includes(modalType, [
					MODAL.INVOICE_ALREADY_USED,
					MODAL.INVOICE_NOT_EXISTS,
					MODAL.INVOICE_IN_USE,
					MODAL.INVOICE_QUANTITY_DIFFERS
				])}
				handleDismiss={handleDismiss}
			/>
			<ModalDamagedInvoice
				showModal={showModal && includes(modalType, [
					MODAL.INVOICE_ZERO_ITEMS,
					MODAL.INVOICE_IN_PROGRESS
				])}
				handleDismiss={handleDismiss}
			/>
			<ModalSuccessfullySubmitted
				showModal={showModal && includes(modalType, [
					MODAL.SUCCESSFULLY_BOOKED,
					MODAL.SUCCESSFUL_CORRECTION
				])}
				handleDismiss={handleDismiss}
			/>
			<ModalReports
				showModal={showModal && modalType === MODAL.CORRECTION_REPORTS}
				handleDismiss={handleDismiss}
			/>
			<ModalSlowInternet
				showModal={showModal && modalType === MODAL.SLOW_INTERNET}
				handleDismiss={handleDismiss}
			/>
			<ModalCheckDelivery
				showModal={showModal && modalType === MODAL.CHECK_DELIVERY}
				handleDismiss={handleDismiss}
			/>
			<ModalRidesReports
				showModal={showModal && modalType === MODAL.RIDES_REPORTS}
				handleDismiss={handleDismiss}
			/>
			{!(modalType === MODAL.LOGOUT && offlineRides) && <ModalContent
				low={showOk.toString()}
				admin={isAdminRoute}
				show={showGeneric.toString() === 'true' && showModal}
			>
				<ModalText small={showOk} dangerouslySetInnerHTML={{
					__html: getModalText()
				}}/>
				<ButtonsContainer small={isAdminRoute || showOk}>
					{!showOk && <>
						<DelayedButton admin={isAdminRoute} onClick={handleDismiss} component={ButtonNo}>
							{STRINGS.NO}
						</DelayedButton>
						<DelayedButton admin={isAdminRoute} onClick={handleYesClicked} component={ButtonYes}>
							{STRINGS.YES}
						</DelayedButton>
					</>}
					{showOk && <DelayedButton onClick={handleDismiss} component={ButtonOkay}>
						{STRINGS.OKAY}
					</DelayedButton>}
				</ButtonsContainer>
			</ModalContent>}
		</ModalContainer>
	);
};

export default Modal;
