import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import { ModalText } from '../../styles/Modal/Modal';
import { CloseModalButton } from '../../styles/Modal/ModalReports';
import { MODAL, STRINGS } from '../../../constants';
import {
	CheckDeliveryStatusBar,
	DeliveryStatus,
	ModalCheckDeliveryContent,
	StatusIcon
} from '../../styles/Modal/ModalCheckDelivery';
import ScannerDarkIcon from '../../../../icons/scanner-dark.png';
import ScannerIcon from '../../../../icons/scanner.png';
import { BarcodeField, BarcodeInput, ScannerImage } from '../../styles/ScannerScreen/ScannerScreen';
import { generalSelectors } from '../../../state/ducks/general';
import { ReactComponent as BarcodeIcon } from '../../../../icons/barcode_icon.svg';
import availableIcon from '../../../../icons/available.png';
import availableIconDark from '../../../../icons/available_dark.png';
import notAvailableIcon from '../../../../icons/not_available.png';
import notAvailableIconDark from '../../../../icons/not_available_dark.png';
import { useEnterClicked } from '../../../utils/hooks/useEnterClicked';
import { deliveriesActions, deliveriesSelectors } from '../../../state/ducks/deliveries';
import { modalSelectors } from '../../../state/ducks/modal';
import { parseInvoiceNumber } from '../../../utils/deliveries/scanner';

const ModalCheckDelivery = ({
	handleDismiss,
	showModal
}) => {
	const dispatch = useDispatch();
	const darkTheme = useSelector(generalSelectors.isDarkTheme);
	const modalType = useSelector(modalSelectors.getModalType);
	const invoiceData = useSelector(deliveriesSelectors.getInvoiceInfo);
	const [, setInputFocused] = useState(false);
	const [inputClicked, setInputClicked] = useState(false);
	const [scannedValue, setScannedValue] = useState('');
	const inputRef = useRef(null);
	const available = darkTheme ? availableIconDark : availableIcon;
	const notAvailable = darkTheme ? notAvailableIconDark : notAvailableIcon;

	const {
		is_booked: isBooked,
		is_in_abas: isInAbas
	} = invoiceData || {};

	const handleInputFocused = () => {
		setInputFocused(true);
		inputRef?.current?.select();
	};

	const focusInput = () => {
		inputRef.current.focus();
		setInputFocused(true);
		setInputClicked(true);
	};

	const fetchInvoiceInfo = (value) => {
		if (value) {
			dispatch(deliveriesActions.fetchInvoiceData({
				invoiceNr: value
			}));
		}
	};

	const handleScan = debounce(() => {
		const { value } = inputRef.current;
		const parsed = parseInvoiceNumber(value);

		if (
			(Math.abs(parsed.length - scannedValue.length) > 1 && parsed.length) ||
			(parsed !== scannedValue && parsed.length && parsed.length === scannedValue.length)
		) {
			fetchInvoiceInfo(parsed);
		}

		setScannedValue(parsed);
	}, 10);

	const closeModal = () => {
		inputRef.current.value = '';
		setScannedValue('');
		handleDismiss();
	};

	useEnterClicked(() => {
		fetchInvoiceInfo(scannedValue);
	});

	useEffect(() => {
		if (modalType === MODAL.CHECK_DELIVERY) {
			setTimeout(() => {
				inputRef?.current?.focus();
			}, 100);
		}
	}, [modalType]);

	return (
		<ModalCheckDeliveryContent show={showModal}>
			<CloseModalButton onClick={closeModal}/>
			<ModalText small dangerouslySetInnerHTML={{
				__html: STRINGS.PROCEED_TO_CHECK_DELIVERY
			}}/>
			<ScannerImage src={darkTheme ? ScannerDarkIcon : ScannerIcon}/>
			<BarcodeField isScanned={scannedValue} onClick={focusInput}>
				{!scannedValue && !inputClicked && <BarcodeIcon/>}
				<BarcodeInput
					autoFocus
					ref={inputRef}
					placeholder={inputClicked ? '' : STRINGS.SCAN}
					isScanned={scannedValue}
					onChange={handleScan}
					onFocus={handleInputFocused}
					onBlur={() => {
						setInputFocused(false);
						setInputClicked(false);
					}}
				/>
			</BarcodeField>
			{invoiceData && <CheckDeliveryStatusBar>
				<DeliveryStatus>
					{isBooked ? <StatusIcon src={available}/> : <StatusIcon src={notAvailable}/>}
					{STRINGS.REQUIRED}
				</DeliveryStatus>
				<DeliveryStatus>
					{isInAbas ? <StatusIcon src={available}/> : <StatusIcon src={notAvailable}/>}
					{STRINGS.BOOKED}
				</DeliveryStatus>
			</CheckDeliveryStatusBar>}
		</ModalCheckDeliveryContent>
	);
};

export default ModalCheckDelivery;
