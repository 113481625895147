import styled from 'styled-components';

export const GoodRideScreenWrapper = styled.div`
	position: relative;
	height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: ${({ theme }) => theme.colors.primary};
`;

export const GoodRideContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-column-gap: 3rem;
	transform: translateY(3.5rem);
`;

export const GoodRideImage = styled.img`
	width: 48rem;
	margin-top: 2rem;
`;

export const TextWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-family: Roboto, sans-serif;
`;

export const GoodRideTitle = styled.h2`
	width: 100%;
	font-weight: 500;
	font-size: 5rem;
	color: black;
	text-align: center;
	margin: 3rem 0;
	line-height: 1;
`;

export const Divider = styled.span`
	display: inline-block;
	height: 3px;
	width: 30rem;
	background-color: black;
	margin: 0 auto;
`;

export const RideInfo = styled.div`
	font-weight: 400;
	font-size: 3.2rem;
	margin-top: 2rem;
	text-align: center;
	
	small {
		font-size: inherit;
		font-weight: 300;
		margin-top: 0;
	}
	
	strong {
		font-size: inherit;
		font-weight: 700;
	}
`;
