import { createSelector } from 'reselect';
import { always, groupBy, ifElse, pipe, sortBy, uniq, when } from 'ramda';
import { getDestinations } from '../destination/selectors';
import { INVOICE, STRINGS } from '../../../constants';
import { parseInvoiceNumber } from '../../../utils/deliveries/scanner';

export const getNote = createSelector(
	[({ deliveries }) => deliveries.note],
	(note) => note
);

export const getDeliveryLocation = createSelector(
	[({ deliveries }) => deliveries.location],
	(location) => location
);

export const getLocationNr = createSelector(
	[getDeliveryLocation, getDestinations],
	(location, destinations) => destinations?.find(({ name }) =>
		name === location).location_nr
);

export const getInitialInvoice = createSelector(
	[({ deliveries }) => deliveries.initialInvoice],
	(invoice) => invoice
);

export const getMappedInvoice = createSelector(
	[({ deliveries }) => deliveries.mappedInvoice],
	(invoice) => invoice || []
);

export const getInvoiceId = createSelector(
	[getInitialInvoice],
	(invoice) => parseInvoiceNumber(invoice?.invoice_number)
);

export const getInvoiceItems = createSelector(
	[getInitialInvoice],
	(invoice) => invoice?.items || []
);

export const isAnyItemSelected = createSelector(
	[getMappedInvoice],
	(invoice) => invoice.some(({ selected }) => selected)
);

export const getItemsGroupedByDestination = createSelector(
	[getMappedInvoice],
	(invoice) => {
		const sortByDestination = sortBy(({ destination }) => destination);
		const addIndex = (array) => array.map((item, index) => ([index + 1, item]));
		const groupByDestination = groupBy(([, { destination }]) => destination);

		return pipe(
			sortByDestination,
			addIndex,
			groupByDestination
		)(invoice);
	}
);

export const getSelectedDestinations = createSelector(
	[getMappedInvoice],
	(invoice) => uniq(invoice.map(({ destination }) => destination))
);

export const isSecondWarehousePresent = createSelector(
	[getMappedInvoice],
	(invoice) => invoice.some(({ destination }) => destination === STRINGS.FURTHER_LAGER)
);

export const getInvoicePayload = createSelector(
	[getMappedInvoice, getDestinations],
	(invoice, destinations) => {
		const isSecondWarehouse = (warehouse) => warehouse === STRINGS.FURTHER_LAGER;
		const getWarehouseNr = (warehouse) => destinations.find(({ name }) =>
			name === warehouse).location_nr;

		const getWarehouseName = when(isSecondWarehouse, always(INVOICE.UNKNOWN));
		const getWarehouseId = ifElse(
			isSecondWarehouse,
			always(INVOICE.UNKNOWN_ID),
			getWarehouseNr
		);

		return invoice.map((item) => ({
			pos_number: item.posNumber,
			invoice_position_char: item.index,
			amount: item.currentAmount,
			unit: item.unit,
			warehouse: getWarehouseId(item.destination),
			warehouse_name: getWarehouseName(item.destination),
			article_nr: item.articleNr,
			article_short_description: item.shortName,
			article_size: item.dimensions,
			article_name: item.name,
			initial_item_id: item.initialId
		}));
	}
);

export const getSubmissionStatus = createSelector(
	[({ deliveries }) => deliveries.submissionStatus],
	(status) => (status)
);

export const isSplitInvoice = createSelector(
	[getMappedInvoice],
	(invoice) => invoice.some(({ isSplit }) => isSplit)
);

export const isImmediateBookingPending = createSelector(
	[({ deliveries }) => deliveries.immediateBookingPending],
	(pending) => pending
);

export const getInvoiceInfo = createSelector(
	[({ deliveries }) => deliveries.invoiceData],
	(data) => data
);
