import React from 'react';
import { DropdownControlWrapper } from '../../../styles/Modal/ModalReports';
import DropdownCustom from '../../Dropdown/DropdownCustom';
import { ReactComponent as FilterDownArrow } from '../../../../../icons/filter_down_arrow.svg';
import { DataDropdownWrapper } from '../../../styles/Modal/ModalRidesReport';

const DataDropdown = ({
	items,
	noSelectionLabel,
	updater
}) => {
	return (
		<DropdownControlWrapper>
			<DataDropdownWrapper>
				<DropdownCustom
					items={items}
					initialValue={noSelectionLabel}
					updateValue={updater}
					downArrow={FilterDownArrow}
				/>
			</DataDropdownWrapper>
		</DropdownControlWrapper>
	);
};

export default DataDropdown;
