import React from 'react';
import { useSelector } from 'react-redux';
import { LorryIcon, LorryWrapper, ProductsAmountText } from '../../styles/Lorry/Lorry';
import TruckIcon from '../../../../icons/lorry.png';
import TruckIconDark from '../../../../icons/lorry_dark.png';
import { productsSelectors } from '../../../state/ducks/products';
import { generalSelectors } from '../../../state/ducks/general';

const Lorry = () => {
	const palletsAmount = useSelector(productsSelectors.getPalletsAmount);
	const darkTheme = useSelector(generalSelectors.isDarkTheme);
	const piecesAvailable = useSelector(productsSelectors.isPiecesPresent);
	const isReportsContext = useSelector(generalSelectors.isReportsContext);

	return (
		<LorryWrapper>
			<LorryIcon src={darkTheme ? TruckIconDark : TruckIcon}/>
			<ProductsAmountText red={piecesAvailable}>
				{!isReportsContext && palletsAmount}
			</ProductsAmountText>
		</LorryWrapper>
	);
};

export default Lorry;
