import styled from 'styled-components';
import { DropdownButtonSmall, DropdownSmallList, DropdownSmallTitle, DropdownSmallWrapper } from '../Dropdown/Dropdown';
import { ReasonButton } from '../CorrectionScreen/CorrectionScreen';

export const ReportsScreenWrapper = styled.div`
	position: relative;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: ${({ theme }) => theme.colors.primary};
	font-family: Roboto, sans-serif;
	padding: 0 5rem;
	
	${ReasonButton} {
    position: absolute;
		top: 10rem;
		right: 5rem;
	}
`;

export const FilterSelectionWrapper = styled.div`
	width: 100%;
	margin-top: 12rem;
`;

export const FilterTitle = styled.div`
	display: flex;
	font-size: 3.2rem;
	font-weight: 400;
	align-items: center;
	
	svg	{
		height: 3rem;
		width: 3rem;
		margin-right: 1.5rem;
	}
`;

export const FilterItemsContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-column-gap: ${({ bigGap }) => bigGap ? 4 : 1}rem;
	width: 100%;
	margin-top: ${({ marginTop }) => marginTop && '0.5rem'};
`;

export const FilterDropdownWrapper = styled.div`
	color: ${({ theme }) => theme.colors.greyMedium2};
	${DropdownSmallWrapper} {
		width: 100%;
		margin-bottom: 0;
	}
	
	${DropdownButtonSmall} {
		border-radius: ${({ isOpen }) => isOpen ? 0 : '6px'};
		border: 2px solid currentColor;
		color: ${({ theme }) => theme.colors.greyMedium2};
		
		${DropdownSmallTitle} {
			color: inherit;
			margin-top: 0;
		}
		
		svg {
			width: 2.5rem;
			margin-top: 0;
			fill: ${({ theme }) => theme.colors.greyMedium2};
		}
	}
	
	${DropdownSmallList} {
		border-radius: 0 0 6px 6px;
		border: 2px solid currentColor;
		border-top: none;
		transform: translateY(-5px);
		max-height: 40rem;
		
		& > * {
			color: inherit;
		}
	}
`;

export const DateInput = styled.input`
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	height: 4.8rem;
	font-size: 3.2rem;
	font-family: Roboto, sans-serif;
	color: ${({ theme }) => theme.colors.greyMedium2};
	background-color: ${({ theme }) => theme.colors.primaryWhite};
	border-radius: 6px;
	border: 2px solid currentColor;
	padding-left: 2.5rem;
	
	&:focus {
		outline: none;
	}
`;

export const Divider = styled.span`
	display: inline-block;
	width: calc(100% + 4rem);
	border: 1px solid black;
`;

export const ReportContainer = styled.div`
	width: 100%;
`;

export const GeneralSummary = styled.div`
	width: 100%;
	font-size: 3.2rem;
	font-weight: 400;
	margin: 3rem 0 1.5rem;
	text-align: right;
`;

export const TimeSlotContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	margin-bottom: 1rem;
`;

export const TimeSlotHeader = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	margin: 1.5rem 0 3rem;
`;

export const TimeLabel = styled.div`
	font-size: 4.2rem;
	font-weight: 700;
	color: ${({ theme }) => theme.colors.primaryRed};
`;

export const AllDetails = styled.button`
	border: none;
	background-color: transparent;
	font-size: 3.2rem;
	font-weight: 500;
	color: ${({ theme }) => theme.colors.primaryGreen};
`;

export const DescriptionHeader = styled.div`
	display: grid;
	width: 100%;
	grid-template-columns: 30rem 1fr max-content;
	padding: 0 4.5rem 0 3rem;
	margin: -2rem 0 1rem;
`;

export const DescriptionTitle = styled.div`
	font-size: 3.2rem;
	font-weight: 400;
`;

export const RidesDataContainer = styled.div`
	display: grid;
	grid-template-columns: 28rem 1fr;
	width: 100%;
	border: 1px solid black;
	font-size: 3.2rem;
	font-weight: 400;
	padding: 1.2rem 4.5rem 1.2rem 3rem;
	margin-bottom: 4.2rem;
`;

export const RidesContainer = styled.div`
	width: 100%;
`;

export const RideItemContainer = styled.div`
	display: grid;
	grid-template-columns: max-content 1fr max-content;
	grid-column-gap: 3rem;
	align-items: flex-start;
	width: 100%;
	background-color: ${({ theme }) => theme.colors.lightGreenHover2};
	padding: 1rem 3rem 0;
	overflow: hidden;
	transition: height .6s .4s;
	height: ${({ expanded, generalHeight, routesHeight, moveTop }) =>
	expanded ? generalHeight + routesHeight - (moveTop * 48) : routesHeight + 18}px;
	min-height: ${({ min }) => min}px;
	
	&:not(:last-child) {
		margin-bottom: 1.2rem;
	}
	
	svg {
		grid-column: 3 / 4;
		width: 3rem;
		height: 3rem;
		margin-top: 0.7rem;
		transition: all .3s .3s;
		transform: rotate(${({ expanded }) =>  expanded ? 180 : 0}deg);
	}
`;

export const HourLabel = styled.div`
	font-size: 2.4rem;
	font-weight: 400;
	margin-top: 0.7rem;
`;

export const RideItemsList = styled.div`
	display: flex;
	flex-direction: column;
	grid-column: 1 / 4;
	grid-row: 2 / 3;
	padding-right: 2.5rem;
	transition: all .3s .3s;
	transform: ${({ expanded, moveTop }) =>
		expanded ? `translateY(-${moveTop * 58}px)` : 'translateY(0)'};
`;

export const ProductDetailsContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr max-content 1fr;
	width: 100%;
	margin-bottom: 4.5rem;
`;

export const ProductText = styled.div`
	grid-column: ${({ col }) => col || '1 / span 3'};
	width: 100%;
	font-size: 3rem;
	font-weight: 500;
	text-align: ${({ align }) => align || ''};
`;

export const ProductTextLight = styled(ProductText)`
	font-weight: 300;
	
	strong {
		font-weight: 700;
	}
`;

export const ProductsDivider = styled.span`
	display: inline-block;
	border: 1px solid black;
	width: 100%;
	margin: 1.5rem 0;
`;

export const RoutesList = styled.div`
	transition: opacity .3s .3s;
	opacity: ${({ expanded }) => expanded ? 0 : 1};
`;

export const RouteItem = styled.div`
	&:not(:last-child) {
		margin-bottom: 1rem;
	}
`;
