import styled, { css } from 'styled-components';
import { ButtonSubmit } from '../Common/Common';

export const InvoiceScreenWrapper = styled.div`
	position: relative;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: ${({ theme }) => theme.colors.primary};
`;

export const InvoiceTable = styled.div`
	border: 2px solid	${({ theme }) => theme.colors.primaryGrey};
	width: calc(100% - 3rem);
	margin-bottom: 2.5rem;
	margin-top: 1rem;
`;

export const InvoiceCard = styled.div`
	display: grid;
	align-items: center;
	width: 100%;
	grid-template-columns: max-content 32rem 13.5rem 1fr 33.5rem;
	padding: 1.5rem 2rem 2rem 0;
	font-size: 3rem;
	background-color: inherit;
	
	&:not(:last-child) {
		border-bottom: 2px solid ${({ theme }) => theme.colors.primaryGrey};
	}
`;

export const Checkbox = styled.span`
	display: inline-block;
	position: relative;
	width: 4.8rem;
	height: 4.8rem;
	background-color: ${({ theme }) => theme.colors.primaryWhite};
	border-radius: 1.5rem;
	border: 2px solid black;
	grid-column: 1 / 2;
	grid-row: 2 / span 2;
	margin: 1.4rem 2.8rem 0;
	
	${({ selected }) => selected && css`
		&:after {
			content: "";
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			background-color: black;
			width: 3.8rem;
			height: 3.8rem;
			border-radius: 1.3rem;
		}
	`}
`;

export const CheckedDot = styled.span`
	display: inline-block;
	width: 3rem;
	height: 3rem;
	border-radius: 50%;
	background-color: ${({ theme }) => theme.colors.primaryGreen};
	margin: 1.4rem 3.7rem 0;
	align-self: center;
	grid-column: 1 / 2;
	grid-row: 2 / span 2;
`;

export const SelectedDestination = styled.div`
	width: 32rem;
	height: 4.8rem;
	grid-column: 4 / 5;
	grid-row: 2 / span 2;
	align-self: center;
	justify-self: end;
	border: 2px solid ${({ theme }) => theme.colors.greyLight3};
	border-radius: 3px;
	margin-top: 1.4rem;
	font-family: Roboto, sans-serif;
	font-size: 3rem;
	font-weight: 400;
	padding-left: 2.5rem;
	color: ${({ theme }) => theme.colors.greyLight3};
	background-color: ${({ theme }) => theme.colors.primary};
`;

export const TextPrimary = styled.p`
	font-family: Roboto, sans-serif;
	font-weight: 500;
	font-size: 3rem;
	color: black;
	margin: 0;
	line-height: 1.2;
`;

export const Name = styled(TextPrimary)`
	grid-column: 2 / span 4;
	grid-row: 1 / 2;
	
	${({ expanded, expandedHeight }) => expanded && css`
		min-height: calc(${expandedHeight}px + 4rem);
	`}
`;

export const Id = styled(TextPrimary)`
	font-size: 3.2rem;
	grid-column: 1 / 2;
	grid-row: 1 / 2;
	justify-self: center;
	align-self: ${({ alignTop }) => alignTop && 'start'};
`;

export const ShortName = styled(TextPrimary)`
	font-weight: 300;
	grid-column: 2 / span 2;
	grid-row: 2 / 3;
	margin-top: 1.4rem;
`;

export const Dimensions = styled(TextPrimary)`
	grid-column: 2 / 3;
	grid-row: 3 / 4;
`;

export const ArticleNr = styled(TextPrimary)`
	font-weight: 300;
	grid-column: 3 / 4;
	grid-row: 3 / 4;
`;

export const AmountField = styled.div`
	position: relative;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	grid-column: 5 / 6;
	grid-row: 2 / span 2;
	margin-top: 1.4rem;
`;

export const Unit = styled(TextPrimary)`
	font-size: 2.5rem;

	&:before,
	&:after {
		position: absolute;
		width: 19rem;
		right: 0;	
		font-weight: 300;
		font-size: 2.5rem;
		color: ${({ theme }) => theme.colors.greyLight3};
	}
	
	${({ primaryType }) => primaryType && css`
		&:before {
			content: ${`"${primaryType}"`};
			text-align: right;
			transform: translate(-100%, -5.5rem);
			padding-right: 2rem;
		}
	`}
	
	${({ secondaryType }) => secondaryType && css`
		&:after {
			content: ${`"${secondaryType}"`};
			text-align: left;
			transform: translateY(-5.5rem);
		}
	`}
`;

export const AmountInput = styled.input`
	width: 12rem;
	height: 4.7rem;
	font-size: 3.7rem;
	font-weight: 400;
	font-family: inherit;
	border: 2px solid ${({ theme, disabled }) => disabled ? theme.colors.greyLight3 : 'black'};
	border-radius: 3px;
	text-align: center;
	line-height: 1;
	margin-left: 1.5rem;
	margin-right: 1rem;
	padding-bottom: 0.5rem;
	color: ${({ theme, disabled }) => disabled ? theme.colors.greyLight3 : 'black'};
	background-color: ${({ theme, disabled }) =>
	disabled ? theme.colors.primary : theme.colors.primaryWhite};
	
	::placeholder {
		color: black;
		opacity: 1;
	}
	
	::-webkit-inner-spin-button {
		-webkit-appearance: none;
	}
	
	&:focus {
		outline: none;
	}
`;

export const Capacity = styled(TextPrimary)`
	font-size: 3.7rem;
	font-weight: 400;
	color: ${({ theme }) => theme.colors.greyLight3};
`;

export const ApplyButton = styled(ButtonSubmit)`
	width: calc(100% - 3rem);
	height: 9rem;
	font-size: 3.2rem;
	margin-bottom: 5rem;
	margin-top: auto;
	transition: all .2s;
	
	background-color: ${({ theme, selected }) =>
	selected ? theme.colors.primaryRed : theme.colors.primaryGreen};
	
	&:hover,
	&:focus {
		background-color: ${({ theme, selected }) =>
	selected ? theme.colors.primaryRedHover : theme.colors.lightGreenHover};
	}
`;

export const DropdownWrapper = styled.div`
	grid-column: 4 / 5;
	grid-row: 2 / span 2;
	justify-self: end;
	align-self: center;
	margin-top: 1.4rem;
`;

export const ResetButtonsContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	width: calc(100% - 3.2rem);
	margin-top: 11rem;
`;

export const ResetButton = styled.button`
	font-family: Roboto, sans-serif;
	font-size: 3rem;
	font-weight: 500;
	color: ${({ theme }) => theme.colors.greyLight5};
	background-color: transparent;
	border: none;
	border-bottom: 2px solid ${({ theme }) => theme.colors.greyLight5};
	padding: 0;
	
	&:focus {
		outline: none;
	}
`;

export const VerticalDivider = styled.span`
	display: inline-block;
	height: 4rem;
	width: 1px;
	background-color: ${({ theme }) => theme.colors.greyLight5};
	margin: 0 1.5rem;
	transform: translateY(2px);
`;
