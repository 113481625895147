import produce from 'immer';
import { v4 as uuidv4 } from 'uuid';
import * as TYPES from './types';
import { STRINGS } from '../../../constants';
import { calculateConversion } from '../../../utils/deliveries/deliveries';

const deliveries = (state = {}, { type, payload }) => {
	switch (type) {
		case TYPES.SET_NOTE:
		case TYPES.SET_DELIVERY_LOCATION:
		case TYPES.SET_SUBMISSION_STATUS:
		case TYPES.SET_IMMEDIATE_BOOKING_PENDING:
			return {
				...state,
				...payload
			};
		case TYPES.SET_INVOICE_DATA:
			return produce(state, (draft) => {
				draft.invoiceData = { ...payload };
			});
		case TYPES.SET_INITIAL_INVOICE:
			return {
				...state,
				initialInvoice: payload
			};
		case TYPES.SET_MAPPED_INVOICE:
			return {
				...state,
				mappedInvoice: payload
			};
		case TYPES.TOGGLE_ITEM_SELECTED:
			return produce(state, (draft) => {
				const index = draft.mappedInvoice.findIndex(({ id }) => id === payload);
				draft.mappedInvoice[index].selected = !draft.mappedInvoice[index].selected;
			});
		case TYPES.SET_WAREHOUSE_FOR_SELECTED:
			return produce(state, (draft) => {
				const isMultipleChoice = draft.mappedInvoice.find(({ id }) => id === payload.id).selected;

				if (isMultipleChoice) {
					draft.mappedInvoice.filter(({ selected }) => selected)
						.forEach((draftItem) => {
							draftItem.destination = payload.destination;
							draftItem.selected = false;
						});
				} else {
					const index = draft.mappedInvoice.findIndex(({ id }) => id === payload.id);
					draft.mappedInvoice[index].destination = payload.destination;
				}
			});
		case TYPES.SET_FURTHER_WAREHOUSE_FOR_ALL_SELECTED:
			return produce(state, (draft) => {
				draft.mappedInvoice.filter(({ selected }) => selected)
					.forEach((draftItem) => {
						draftItem.destination = STRINGS.FURTHER_LAGER;
						draftItem.selected = false;
					});
			});
		case TYPES.UPDATE_ITEM_AMOUNT:
			return produce(state, (draft) => {
				const index = draft.mappedInvoice.findIndex(({ id }) => id === payload.id);
				draft.mappedInvoice[index].currentAmount = payload.amount;
			});
		case TYPES.SPLIT_ITEM:
			return produce(state, (draft) => {
				const index = draft.mappedInvoice.findIndex(({ id }) => id === payload);
				const item = draft.mappedInvoice.find(({ id }) => id === payload);
				const sum = draft.mappedInvoice.filter(({ splitId: id }) => id === item.splitId)
					.map(({ currentAmount }) => currentAmount)
					.reduce((acc, i) => acc + i, 0);
				const splitGroup = draft.mappedInvoice.filter(({ index: idx }) => {
					const [splitIdx] = idx.split('.');
					const [itemIdx] = item.index.split('.');

					return splitIdx === itemIdx;
				});

				draft.mappedInvoice[index].initialCapacity = item.currentAmount;
				draft.mappedInvoice.splice(index + 1, 0, {
					...item,
					id: uuidv4(),
					initialCapacity: item.capacity - sum,
					currentAmount: item.capacity - sum,
					destination: STRINGS.FURTHER_LAGER,
					index: `${item.index.split('.')[0]}.${splitGroup.length + 1}`,
					selected: false
				});

				if (splitGroup.length === 1) {
					splitGroup.forEach((draftItem, idx) => {
						const [num] = draftItem.index.split('.');

						draftItem.index = `${num}.${idx + 1}`;
					});
				}

				draft.mappedInvoice.forEach((draftItem) => {
					const { unit, stockUnit, conversionFactor, currentAmount } = draftItem;
					const {
						primaryType,
						secondaryType
					} = calculateConversion(unit, stockUnit, conversionFactor, currentAmount);

					draftItem.primaryType = primaryType;
					draftItem.secondaryType = secondaryType;
				});
			});
		case TYPES.CLEAR_INVOICE:
			return {
				location: state.location
			};
		case TYPES.CLEAR_INVOICE_DATA:
			return produce(state, (draft) => {
				delete draft.invoiceData;
			});
		default:
			return state;
	}
};

export default deliveries;
