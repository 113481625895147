import React from 'react';
import { useDispatch } from 'react-redux';
import {
	CloseModalButton,
	Divider,
	DownloadReportButton,
	ModalReportsContent,
	ReportFiltersContainer,
	ReportTitle
} from '../../../styles/Modal/ModalReports';
import { STRINGS } from '../../../../constants';
import { ReactComponent as ReportIcon } from '../../../../../icons/report.svg';
import ReportControls from './ReportControls';
import { DATE_PICKER_TYPES } from './DatePicker';
import { reportsActions } from '../../../../state/ducks/reports';

const ModalReports = ({
	showModal,
	handleDismiss
}) => {
	const dispatch = useDispatch();

	const updateCorrectionReport = (payload) =>
		dispatch(reportsActions.setCorrectionReport({ correctionReport: payload }));

	const handleDownloadReport = () =>
		dispatch(reportsActions.downloadCorrectionReport());

	return (
		<ModalReportsContent show={showModal}>
			<CloseModalButton onClick={handleDismiss}/>
			<ReportFiltersContainer>
				<ReportTitle>
					<ReportIcon/>
					{STRINGS.INDIVIDUAL_REPORT}
				</ReportTitle>
				<Divider/>
				<ReportControls updateFilters={updateCorrectionReport}>
					<ReportControls.DatePicker type={DATE_PICKER_TYPES.START}/>
					<ReportControls.DatePicker type={DATE_PICKER_TYPES.END}/>
					<ReportControls.ReasonDropdown/>
				</ReportControls>
				<Divider/>
				<DownloadReportButton onClick={handleDownloadReport}>
					{STRINGS.GENERATE_REPORT}
				</DownloadReportButton>
			</ReportFiltersContainer>
		</ModalReportsContent>
	);
};

export default ModalReports;
