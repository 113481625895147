import styled from 'styled-components';
import { ButtonSubmit, DeleteButton, EditButton } from '../Common/Common';
import { ReactComponent as ReplaceIcon } from '../../../../icons/replace.svg';

export const SummaryScreenWrapper = styled.div`
	position: relative;
	min-height: 100vh;
	width: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;
	background-color: ${({ theme }) => theme.colors.primary};
`;

export const TruckDataWrapper = styled.div`
	margin-top: 10rem;
	margin-bottom: 2rem;
	display: flex;
	align-items: center;
	width: 100%;
	padding: 0 6.5rem;
`;

export const DetailsLabel = styled.div`
	display: flex;
	font-family: Roboto, sans-serif;
	font-size: 3.2rem;
	font-weight: ${({ red }) => red ? 700 : 400};
	margin-right: 2rem;
	color: ${({ theme, red }) => red && theme.colors.fontRed};
`;

export const DetailsBold = styled(DetailsLabel)`
	font-weight: 700;
	margin-left: 1rem;
`;

export const FullyLoaded = styled(DetailsLabel)`
	font-size: 2rem;
	font-weight: 700;
	color: ${({ theme }) => theme.colors.fontGreen};
`;

export const TruckLabel = styled(DetailsLabel)`
	font-size: 4.2rem;
	font-weight: 700;
	line-height: 1;
`;

export const TruckIdWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-left: auto;
	margin-right: 2rem;
`;

export const Divider = styled.span`
	width: calc(100% - 7rem);
	border-bottom: 3px solid black;
	
	:last-child {
		margin-bottom: 4rem;
	}
`;

export const ProductsListContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
`;

export const ListItemWrapper = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	padding: 2rem 6.5rem;
`;

export const ListId = styled(DetailsLabel)`
	grid-column: 1 / 2;
	grid-row: 1 / 4;
	align-self: ${({ center }) => center ? 'center' : 'flex-end'};
	font-weight: 900;
	font-size: 6.2rem;
	margin-right: 3.5rem;
	line-height: 1;
`;

export const ProductTitle = styled(DetailsLabel)`
	grid-column: 2 / 6;
	grid-row: 1 / 2;
	font-weight: 700;
	font-size: 4.2rem;
	margin-bottom: 1.5rem;
`;

export const ProductDetailsGrid = styled.div`
	display: grid;
	align-content: center;
	grid-template-columns: max-content repeat(2, 1fr) repeat(2, max-content);
	width: 100%;
	margin-bottom: 1.5rem;
`;

export const ReplaceButton = styled(ReplaceIcon)`
	grid-column: 1 / 2;
	grid-row: 1 / 2;
	align-self: flex-start;
	padding: 0;
	width: 6rem;
	height: 6rem;
	margin-top: 1.4rem;
	transform: translateX(-20%);
	fill: ${({ theme }) => theme.colors.primaryWhite};
	stroke: ${({ theme }) => theme.colors.primaryRed};
	transition: all .2s;
	
	& > path, g {
		stroke: ${({ theme }) => theme.colors.primaryRed};
		fill: ${({ theme }) => theme.colors.primaryRed};
	}
	
	&:hover,
	&:focus {
		fill: ${({ theme }) => theme.colors.primaryRedHover};
	}
`;

export const EditRideButton = styled(EditButton)`
	grid-column: 4 / 5;
	grid-row: 2 / 4;
`;

export const DeleteArticleButton = styled(DeleteButton)`
	grid-column: 5 / 6;
	grid-row: 2 / 4;
`;

export const EditRideLabel = styled(DetailsLabel)`
	font-weight: 700;
	margin-left: 3.5rem;
`;

export const ActionButtonContainer = styled.div`
	width: 100%;
	padding: 0 9rem;
	margin-bottom: 9rem;
	margin-top: auto;
`;

export const StartRideButton = styled(ButtonSubmit)`
	bottom: 9rem;
	width: 100%;
	height: 14rem;
`;

export const DeleteRideButton = styled(StartRideButton)`
 	background-color: ${({ theme }) => theme.colors.primaryRed};
 	
 	&:hover,
 	&:focus {
 		background-color: ${({ theme }) => theme.colors.primaryRedHover};
 	}
`;

export const AddProductWrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr max-content 1fr;
	width: 100%;
	padding: 0 6.5rem;
`;

export const AddArticleButton = styled(ButtonSubmit)`
	display: flex;
	align-items: center;
	justify-content: center;
	grid-column: 2 / 3;
	width: 33rem;
	height: 11rem;
	border: 2px dashed ${({ theme }) => theme.colors.secondaryGrey};
	background-color: ${({ theme }) => theme.colors.tertiaryGrey};
	margin: 5rem 0;
	font-weight: 400;
	color: ${({ theme }) => theme.colors.fontGreyLight};
	font-size: 3.2rem;
	text-align: left;
	
	svg	{
		width: 6rem;
		height: 6rem;
		margin-right: 3.5rem;
	}
	
	&:hover,
	&:focus {
		background-color: ${({ theme }) => theme.colors.tertiaryGrey};
	}
`;

export const AddArticleTextWrapper = styled.div`
	width: 16rem;
`;
