import * as TYPES from './types';

const authentication = (state = {}, { type, payload }) => {
	switch (type) {
		case TYPES.SET_TOKEN:
		case TYPES.SET_ADMIN_TOKEN:
		case TYPES.SET_PASSWORD_VALIDITY:
		case TYPES.SET_USERNAME:
		case TYPES.SET_GUTEX_USERS:
		case TYPES.SET_TRUCK_TOKENS:
		case TYPES.SET_TRUCK_ID:
			return {
				...state,
				...payload
			};
		case TYPES.ATTEMPT_LOGOUT:
			return {
				...state,
				token: null,
				adminToken: null,
				truckId: null
			};
		default:
			return state;
	}
};

export default authentication;
