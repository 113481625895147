import * as TYPES from './types';

export const setFilter = (payload) => ({
	type: TYPES.SET_FILTER,
	payload
});

export const setCorrection = (payload) => ({
	type: TYPES.SET_CORRECTION,
	payload
});

export const setReport = (payload) => ({
	type: TYPES.SET_REPORT,
	payload
});

export const sendCorrection = () => ({
	type: TYPES.SEND_CORRECTION
});

export const clearCorrection = () => ({
	type: TYPES.CLEAR_CORRECTION
});

export const toggleAllDetails = (payload) => ({
	type: TYPES.TOGGLE_ALL_DETAILS,
	payload
});

export const setCorrectionReport = (payload) => ({
	type: TYPES.SET_CORRECTION_REPORT,
	payload
});

export const downloadCorrectionReport = () => ({
	type: TYPES.DOWNLOAD_CORRECTION_REPORT
});

export const setRidesReport = (payload) => ({
	type: TYPES.SET_RIDES_REPORT,
	payload
});

export const downloadRidesReport = () => ({
	type: TYPES.DOWNLOAD_RIDES_REPORT
});

export const clearReportFilters = () => ({
	type: TYPES.CLEAR_REPORT_FILTERS
});
