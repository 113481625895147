import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DelayedButton from '../../Common/DelayedButton';
import { STRINGS } from '../../../../constants';
import { modalSelectors } from '../../../../state/ducks/modal';
import { ridesActions } from '../../../../state/ducks/rides';
import {
	ButtonsContainer,
	ModalSlowInternetContent,
	ModalSlowInternetText,
	ResendButton,
	ResendRejectButton
} from '../../../styles/Modal/ModalSlowInternet';
import NotSendRides from '../../../styles/Modal/NotSendRides';

const ModalSlowInternet = ({
	showModal,
	handleDismiss
}) => {
	const dispatch = useDispatch();
	const notSentRides = useSelector(modalSelectors.getRidesData);

	const handleResendRides = () => {
		dispatch(ridesActions.resendRides(notSentRides));
		handleDismiss();
	};

	return (
		<ModalSlowInternetContent show={showModal}>
			<ModalSlowInternetText small dangerouslySetInnerHTML={{
				__html: STRINGS.SLOW_INTERNET_MSG
			}}/>
			<NotSendRides ridesData={notSentRides}/>
			<ButtonsContainer top={6}>
				<DelayedButton onClick={handleDismiss} component={ResendRejectButton}>
					{STRINGS.LOSE_RIDE}
				</DelayedButton>
				<DelayedButton onClick={handleResendRides} component={ResendButton}>
					{STRINGS.RESEND}
				</DelayedButton>
			</ButtonsContainer>
		</ModalSlowInternetContent>
	);
};

export default ModalSlowInternet;
