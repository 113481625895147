import styled from 'styled-components';
import { DeleteButton, EditButton } from '../Common/Common';

export const PreviousRidesScreenWrapper = styled.div`
	position: relative;
	min-height: 100vh;
	width: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;
	background-color: ${({ theme }) => theme.colors.primary};
`;

export const PreviousRidesList = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-top: 8.6rem;
	padding: 0 3.5rem;
	margin-bottom: 5rem;
`;

export const PreviousRidesListItem = styled.div`
	display: grid;
	grid-template-columns: 30rem 1fr repeat(2, max-content);
	padding: 3rem 3.5rem;
	background-color: ${({ theme, offline }) => offline && theme.colors.primaryPink};
`;

export const Divider = styled.span`
	width: 100%;
	border-top: 2px solid black;
	
	:last-child {
		display: none;
	}
`;

export const DetailsLabel = styled.div`
	display: flex;
	align-items: center;
	font-family: Roboto, sans-serif;
	font-weight: 400;
	font-size: 3.2rem;
	color: ${({ theme }) => theme.colors.fontGrey};
	margin-right: 1rem;
`;

export const LabelBold = styled(DetailsLabel)`
	font-weight: 700;
	color: ${({ theme, grey }) => grey ? theme.colors.fontGrey : 'black'};
	margin-left: ${({ addMargin }) => addMargin && '1rem'};
`;

export const DestinationsLabel = styled(LabelBold)`
	margin-top: 2rem;
	grid-column: 1 / 2;
`;

export const ArticlesLabel = styled(LabelBold)`
	margin-top: 2rem;
	grid-column: 2 / 5;
`;

export const Destination = styled(DetailsLabel)`
	grid-column: 1 / 2;
	align-self: self-start;
`;

export const ArticleWrapper = styled.div`
	grid-column: 2 / 5;
	grid-row: ${({ row }) => `${row + 4} / ${row + 5}`};
	display: flex;
	align-items: center;
`;

export const Article = styled.p`
	font-family: Roboto, sans-serif;
	font-weight: 400;
	font-size: 3.2rem;
	color: ${({ theme }) => theme.colors.fontGrey};
	margin: 0 1rem 0 0;
	
	strong {
		color: black;
		font-weight: inherit;
	}
`;

export const ArticleBold = styled.strong`
	font-weight: 700;
	color: ${({ theme, grey }) => grey ? theme.colors.fontGrey : 'black'};
	margin-left: 1rem;
`;

export const TimeLabel = styled(DetailsLabel)`
	font-weight: 700;
	color: ${({ theme }) => theme.colors.fontRed};
	font-size: 4.2rem;
`;

export const DateLabel = styled(DetailsLabel)`
	align-self: center;
`;

export const EditRideButton = styled(EditButton)`
	grid-column: 3 / 4;
	grid-row: 1 / 3;
	align-self: start;
`;

export const DeleteRideButton = styled(DeleteButton)`
	grid-column: 4 / 5;
	grid-row: 1 / 3;
	align-self: start;
`;

export const StartContainer = styled.div`
	display: flex;
	grid-column: 1 / 2;
`;

export const DestinationsList = styled.div`
	grid-column: 1 / 2;
	grid-row: 4 / 5;
`;

export const DotWrapper = styled.div`
	height: 100%;
	margin-top: 2.7rem;
`;

export const DetailDot = styled.span`
	display: inline-block;
	width: 0.5rem;
	height: 0.5rem;
	background-color: ${({ theme }) => theme.colors.primaryGrey};
	border-radius: 50%;
	margin-right: 0.5rem;
`;
