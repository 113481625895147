import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ApplyNoteButton, ModalContent, NoteArea, NoteTitle } from '../../styles/Modal/Modal';
import { STRINGS } from '../../../constants';
import DelayedButton from '../Common/DelayedButton';
import { deliveriesActions, deliveriesSelectors } from '../../../state/ducks/deliveries';

const ModalNotes = ({
	handleDismiss,
	showModal
}) => {
	const dispatch = useDispatch();
	const currentNote = useSelector(deliveriesSelectors.getNote);
	const [note, setNote] = useState('');
	const noteRef = useRef();
	const AREA_ID = 'noteArea';

	const handleOnClick = useCallback(() => {
		dispatch(deliveriesActions.setNote({ note }));
		handleDismiss();
	}, [dispatch, handleDismiss, note]);

	const handleNoteChange = (event) => {
		const { value } = event.target;
		setNote(value);
	};

	useEffect(() => {
		setNote(currentNote);
	}, [currentNote]);

	useEffect(() => {
		setTimeout(() => {
			document.getElementById(AREA_ID).focus();
		}, 100);
	}, [showModal]);

	return (
		<ModalContent show={showModal}>
			<NoteTitle>{STRINGS.YOUR_NOTE}</NoteTitle>
			<NoteArea id={AREA_ID} value={note} onChange={handleNoteChange} ref={noteRef}/>
			<DelayedButton onClick={handleOnClick} component={ApplyNoteButton}>
				{STRINGS.SAVE_NOTE}
			</DelayedButton>
		</ModalContent>
	);
};

export default ModalNotes;
