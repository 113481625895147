import React from 'react';
import { DropdownControlWrapper, DropdownWrapper, FilterLabel } from '../../../styles/Modal/ModalReports';
import DropdownCustom from '../../Dropdown/DropdownCustom';
import { useReportControlsContext } from './useReportControlsContext';
import { REPORTS, STRINGS } from '../../../../constants';
import { ReactComponent as FilterDownArrow } from '../../../../../icons/filter_down_arrow.svg';

const ReasonDropdown = () => {
	const { reason, setReason } = useReportControlsContext();

	const reasons = [
		STRINGS.ALL,
		REPORTS.REASON_WAREHOUSE,
		REPORTS.REASON_DAMAGE,
		REPORTS.REASON_DELIVERY
	];

	return (
		<DropdownControlWrapper>
			<FilterLabel>{STRINGS.REASON}:</FilterLabel>
			<DropdownWrapper>
				<DropdownCustom
					items={reasons}
					initialValue={reason}
					updateValue={setReason}
					downArrow={FilterDownArrow}
				/>
			</DropdownWrapper>
		</DropdownControlWrapper>
	);
};

export default ReasonDropdown;
