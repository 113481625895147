import React, { useMemo } from 'react';
import {
	Amount,
	AmountContainer,
	Destination,
	InvoiceSummaryCard,
	SummaryArticleNr as ArticleNr,
	SummaryDimensions as Dimensions,
	SummaryId as Id,
	SummaryName as Name,
	SummaryShortName as ShortName
} from '../../styles/InvoiceSummaryScreen/InvoiceSummaryScreen';
import { Capacity } from '../../styles/InvoiceScreen/InvoiceScreen';

const InvoiceSummaryItem = ({
	index,
	name,
	shortName,
	dimensions,
	articleNr,
	destination,
	capacity,
	amount,
	red
}) => {
	const isZeroDimension = useMemo(() => {
		const values = dimensions.split('x');

		return values.every((value) => !Number(value));
	}, [dimensions]);

	return (
		<InvoiceSummaryCard>
			<Id>{index}</Id>
			<Name>{name}</Name>
			<ShortName>{shortName}</ShortName>
			{!isZeroDimension && <Dimensions>{dimensions}</Dimensions>}
			<ArticleNr>{articleNr}</ArticleNr>
			<Destination red={red}>{destination}</Destination>
			<AmountContainer>
				<Amount>{amount}</Amount>
				<Capacity>/{capacity}</Capacity>
			</AmountContainer>
		</InvoiceSummaryCard>
	);
};

export default InvoiceSummaryItem;
