import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import {
	AmountField,
	AmountInput,
	ArticleNr,
	Capacity,
	Checkbox,
	CheckedDot,
	Dimensions,
	DropdownWrapper,
	Id,
	InvoiceCard,
	Name,
	SelectedDestination,
	ShortName,
	Unit
} from '../../styles/InvoiceScreen/InvoiceScreen';
import DropdownCustom from '../Dropdown/DropdownCustom';
import { destinationSelectors } from '../../../state/ducks/destination';
import { STRINGS } from '../../../constants';
import { deliveriesActions, deliveriesSelectors } from '../../../state/ducks/deliveries';

const InvoiceItem = ({
	id,
	index,
	isSubmitted,
	isFraction,
	name,
	shortName,
	dimensions,
	articleNr,
	destination,
	unit,
	capacity,
	initialCapacity,
	currentAmount,
	primaryType,
	secondaryType,
	splittable
}) => {
	const dispatch = useDispatch();
	const locations = useSelector(destinationSelectors.getDestinationsList);
	const invoiceItems = useSelector(deliveriesSelectors.getMappedInvoice);
	const [selectedLocation, setSelectedLocation] = useState(destination);
	const [isSecondWarehouse, setIsSecondWarehouse] = useState(false);
	const [inputValue, setInputValue] = useState(currentAmount);
	const [extendRow, setExtendRow] = useState(false);
	const [nameHeight, setNameHeight] = useState(0);
	const inputRef = useRef(null);
	const nameRef = useRef(null);
	const dropdownRef = useRef(null);
	const isSelected = invoiceItems.find((item) => item.id === id).selected;

	const isZeroDimension = useMemo(() => {
		const values = dimensions.split('x');

		return values.every((value) => !Number(value));
	}, [dimensions]);

	const handleCheckboxClicked = () => {
		dispatch(deliveriesActions.toggleItemSelected(id));
	};

	const handleDestinationSelected = (value) => {
		setSelectedLocation(value);
		dispatch(deliveriesActions.setWarehouseForSelected({
			id,
			destination: value
		}));
	};

	const handleFocus = (event) => {
		event.target.select();
	};

	const handleBlur = () => {
		setInputValue(initialCapacity);
		dispatch(deliveriesActions.updateItemAmount({ id, amount: initialCapacity }));
	};

	const handleValueChanged = (event) => {
		const { value } = event.target;
		if (value >= 0 && value <= initialCapacity) {
			if (value >= 1) {
				dispatch(deliveriesActions.updateItemAmount({ id, amount: +value }));
			}
			setInputValue(+value);
		}
	};

	const handleEnterClicked = useCallback((event) => {
		if (event.key === 'Enter' &&
			inputRef.current.contains(event.target) &&
			inputValue > 0 &&
			inputValue < initialCapacity
		) {
			dispatch(deliveriesActions.splitItem(id));
			inputRef.current.blur();
		}
	}, [dispatch, id, initialCapacity, inputValue]);

	const checkResize = debounce(useCallback(() => {
		const { right: textRight } = nameRef.current.getBoundingClientRect();
		const { height } = nameRef.current.getBoundingClientRect();
		const { right: dropdownRight } = dropdownRef.current.getBoundingClientRect();

		if (textRight > dropdownRight && (primaryType || secondaryType)) {
			setExtendRow(true);
			setNameHeight(height);
		} else {
			setExtendRow(false);
		}
	}, [primaryType, secondaryType]), 150);

	useEffect(() => {
		setIsSecondWarehouse(selectedLocation === STRINGS.FURTHER_LAGER);
	}, [selectedLocation]);

	useEffect(() => {
		setSelectedLocation(destination);
	}, [destination]);

	useEffect(() => {
		setInputValue(+currentAmount);
	}, [currentAmount]);

	useEffect(() => {
		window.addEventListener('keyup', handleEnterClicked);
		return () => window.removeEventListener('keyup', handleEnterClicked);
	}, [handleEnterClicked]);

	useEffect(() => {
		checkResize();
		window.addEventListener('resize', checkResize);
		return () => window.removeEventListener('resize', checkResize);
	}, [checkResize]);

	return (
		<InvoiceCard>
			<Id alignTop>{index}</Id>
			{isSubmitted
				? <CheckedDot/>
				: <Checkbox onClick={handleCheckboxClicked} selected={isSelected}/>}
			<Name expanded={extendRow} expandedHeight={nameHeight}>
				<span ref={nameRef}>{name}</span>
			</Name>
			<ShortName>{shortName}</ShortName>
			{!isZeroDimension && <Dimensions>{dimensions}</Dimensions>}
			<ArticleNr>{articleNr}</ArticleNr>
			{isSubmitted
				? <SelectedDestination ref={dropdownRef}>
					{destination}
				</SelectedDestination>
				: <DropdownWrapper ref={dropdownRef}>
					<DropdownCustom
						initialValue={destination}
						items={[...locations, STRINGS.FURTHER_LAGER]}
						updateValue={handleDestinationSelected}
						red={isSecondWarehouse}
					/>
				</DropdownWrapper>}
			<AmountField>
				<Unit
					primaryType={primaryType}
					secondaryType={secondaryType}
				>
					{unit}:
				</Unit>
				<AmountInput
					value={inputValue || ''}
					onChange={handleValueChanged}
					type={'number'}
					onFocus={handleFocus}
					onBlur={handleBlur}
					ref={inputRef}
					disabled={isSubmitted || isFraction || !splittable}
				/>
				<Capacity>/{capacity}</Capacity>
			</AmountField>
		</InvoiceCard>
	);
};

export default InvoiceItem;
