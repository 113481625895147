import styled from 'styled-components';

export const ButtonSubmit = styled.button`
	background-color: ${({ theme }) => theme.colors.primaryGreen};
	color: ${({ theme }) => theme.colors.primary};
	font-weight: 700;
	font-family: Roboto, sans-serif;
	font-size: 5.5rem;
	padding: 0;
	text-align: center;
	border: none;
	border-radius: 6px;
	transition: all .2s;
	
	&:focus {
		outline: 0;
		background-color: ${({ theme }) => theme.colors.lightGreenHover};
	}
	
	&:hover {
		background-color: ${({ theme }) => theme.colors.lightGreenHover};
	}
`;

export const ActionButton = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	grid-row: 1 / 4;
	align-self: center;
	font-family: Roboto, sans-serif;
	font-weight: 700;
	font-size: 3.2rem;
	text-align: center;
	height: 9rem;
	border-radius: 6px;
	border: none;
	color: ${({ theme }) => theme.colors.fontWhite};
	padding: 0 3rem;
	margin-left: 2rem;
	transition: all 0.2s;
	
	svg {
		width: 3.5rem;
		height: 3.5rem;
	}
	
	&:focus {
		outline: 0;
	}
	
	&:hover {
		background-color: ${({ theme }) => theme.colors.primaryRedHover};
		outline: 0;
	}
`;

export const EditButton = styled(ActionButton)`
	background-color: ${({ theme }) => theme.colors.primaryRed};
`;

export const DeleteButton = styled(ActionButton)`
	background-color: ${({ theme }) => theme.colors.primary};
	color: ${({ theme }) => theme.colors.primaryRed};
	border: 4px solid ${({ theme }) => theme.colors.primaryRed};
	padding: 0 2.5rem;
	
	&:hover {
		border: 4px solid ${({ theme }) => theme.colors.primaryRedHover};
	}
`;
