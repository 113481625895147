import { call, delay, fork, put, select, takeEvery, takeLeading } from 'redux-saga/effects';
import * as TYPES from './types';
import { getInvoiceInfo, invoice, splitInvoice, submitInvoice, syncInvoice } from '../../../api';
import {
	getDeliveryLocation,
	getInitialInvoice,
	getInvoiceId,
	getInvoicePayload,
	getLocationNr,
	getNote,
	isSecondWarehousePresent
} from './selectors';
import { getToken } from '../authentication/selectors';
import {
	clearInvoice,
	setImmediateBookingPending,
	setInvoice,
	setMappedInvoice,
	setNote,
	setSubmissionStatus
} from './actions';
import { mapInvoice } from '../../../utils/deliveries/deliveries';
import { hideModal, showModal } from '../modal/actions';
import { INVOICE, MODAL } from '../../../constants';
import { modalActions } from '../modal';
import { deliveriesActions } from './index';

function* fetchAndSetInvoice(invoiceNr, apiCallback) {
	const token = yield select(getToken);
	const location = yield select(getDeliveryLocation);
	const locationNr = yield select(getLocationNr);

	const { data, status } = yield call(apiCallback, token, {
			location_name: location,
			location_nr: locationNr,
			invoice_number: invoiceNr
		}
	);

	if (status === 200) {
		yield put(setInvoice(data));
		yield put(setMappedInvoice(mapInvoice(data)));
		yield put(setNote({ note: data.comment }));
	} else if (status === 306) {
		yield put(showModal({ modalType: MODAL.INVOICE_ALREADY_USED }));
		yield put(setImmediateBookingPending({ immediateBookingPending: false }));
	} else if (status === 400) {
		yield put(showModal({ modalType: MODAL.INVOICE_ZERO_ITEMS }));
		yield put(setImmediateBookingPending({ immediateBookingPending: false }));
	} else if (status === 404) {
		yield put(showModal({ modalType: MODAL.INVOICE_NOT_EXISTS }));
		yield put(setImmediateBookingPending({ immediateBookingPending: false }));
	} else if (status === 226) {
		yield put(showModal({ modalType: MODAL.INVOICE_IN_USE }));
		yield put(setImmediateBookingPending({ immediateBookingPending: false }));
	}
}

function* fetchInvoice(action) {
	const { payload: { invoiceNr, immediateBooking } } = action;
	yield put(setImmediateBookingPending({ immediateBookingPending: immediateBooking }));
	yield* fetchAndSetInvoice(invoiceNr, invoice);
}

function* resetInvoice() {
	const initialInvoice = yield select(getInitialInvoice);
	yield put(setMappedInvoice(mapInvoice(initialInvoice)));
	yield put(setNote({ note: initialInvoice.comment }));
}

function* resetInvoiceAbasSync() {
	const invoiceNr = yield select(getInvoiceId);
	yield* fetchAndSetInvoice(invoiceNr, syncInvoice);
}

function* prepareInvoiceSubmission() {
	const token = yield select(getToken);
	const invoiceNr = yield select(getInvoiceId);
	const comment = yield select(getNote);
	const items = yield select(getInvoicePayload);
	const isSecondWarehouse = yield select(isSecondWarehousePresent);
	const apiCallback = isSecondWarehouse ? splitInvoice : submitInvoice;

	const payload = {
		invoice_number: invoiceNr,
		comment,
		items
	};

	return {
		token,
		apiCallback,
		payload
	};
}

function* handleSubmitInvoice() {
	const { token, apiCallback, payload } = yield* prepareInvoiceSubmission();
	const { status } = yield call(apiCallback, token, payload);

	if (status === 500 || status === 400) {
		yield put(modalActions.showModal({ modalType: MODAL.INVOICE_IN_PROGRESS }));
		yield put(setSubmissionStatus({ submissionStatus: INVOICE.FAILURE }));
	} else if (status === 409) {
		yield put(showModal({ modalType: MODAL.INVOICE_QUANTITY_DIFFERS }));
		yield put(setImmediateBookingPending({ immediateBookingPending: false }));
	} else {
		yield put(setSubmissionStatus({ submissionStatus: INVOICE.SUCCESS }));
	}
}

function* toggleOffModal() {
	yield delay(1000);
	yield put(hideModal());
}

function* bookInvoiceImmediately() {
	const { token, apiCallback, payload } = yield* prepareInvoiceSubmission();
	const { status } = yield call(apiCallback, token, payload);

	if (status === 200) {
		yield put(modalActions.showModal({ modalType: MODAL.SUCCESSFULLY_BOOKED }));
		yield fork(toggleOffModal);
		yield put(clearInvoice());
	}

	yield put(setImmediateBookingPending({ immediateBookingPending: false }));
}

function* handleGetInvoiceData(action) {
	const { payload: { invoiceNr } } = action;
	const token = yield select(getToken);

	const { data } = yield call(getInvoiceInfo, token, invoiceNr);
	yield put(deliveriesActions.setInvoiceData(data))
}

export const fetchInvoiceSaga = takeLeading(
	TYPES.FETCH_INVOICE,
	fetchInvoice
);

export const resetInvoiceSaga = takeEvery(
	TYPES.RESET_INVOICE,
	resetInvoice
);

export const resetInvoiceAbasSyncSaga = takeLeading(
	TYPES.RESET_INVOICE_ABAS_SYNC,
	resetInvoiceAbasSync
);

export const submitInvoiceSaga = takeLeading(
	TYPES.SUBMIT_INVOICE,
	handleSubmitInvoice
);

export const bookInvoiceImmediatelySaga = takeLeading(
	TYPES.BOOK_INVOICE_IMMEDIATELY,
	bookInvoiceImmediately
);

export const getInvoiceDataSaga = takeLeading(
	TYPES.FETCH_INVOICE_DATA,
	handleGetInvoiceData
);
