import { useCallback, useEffect, useRef } from 'react';

export const useOutsideClick = (callback) => {
	const itemRef = useRef(null);

	const handleClickOutside = useCallback((event) => {
		if (itemRef.current && !itemRef.current.contains(event.target)) {
			callback();
		}
	}, [callback]);

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		document.addEventListener('touchend', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
			document.removeEventListener('touchend', handleClickOutside);
		};
	}, [callback, handleClickOutside]);

	return itemRef;
};
