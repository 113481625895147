import styled from 'styled-components';

export const LorryWrapper = styled.div`
	position: relative;
`;

export const LorryIcon = styled.img`
	width: 12rem;
	margin-top: 0.6rem;
	margin-right: 2rem;
`;

export const ProductsAmountText = styled.div`
	position: absolute;
	top: 48%;
	left: 52%;
	transform: translate(-50%, -50%);
	font-family: Roboto, sans-serif;
	font-weight: 700;
	font-size: 3.7rem;
	color: ${({ theme, red }) => red ? theme.colors.fontRed : 'black'};
	width: 10rem;
	text-align: center;
`;
