import reducer from './reducers';

import * as generalActions from './actions';
import * as generalTypes from './types';
import * as generalSelectors from './selectors';
import * as generalOperations from './operations';

export {
	generalActions,
	generalTypes,
	generalSelectors,
	generalOperations
};

export default reducer;
