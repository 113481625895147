import { call, put, select, take, takeEvery } from 'redux-saga/effects';
import * as TYPES from './types';
import { trucksSelectors, trucksTypes } from '../trucks';
import { authSelectors } from '../authentication';
import { acceptDatabase, allProducts, database, downloadExcel, lastProducts, products } from '../../../api';
import { fetchLastProducts, setExcelChanges, setLastProducts, setProducts, toggleExcelUpdatePending } from './actions';
import { checkIsLoggedIn } from '../rides/operations';
import { MODAL } from '../../../constants';
import { showModal } from '../modal/actions';

function* fetchProducts() {
	yield take(trucksTypes.SET_TRUCKS);
	const truckId = yield select(trucksSelectors.getAuthenticatedTruckId);
	const token = yield select(authSelectors.getTruckToken);
	const loggedIn = yield* checkIsLoggedIn();

	if (!loggedIn) return;

	const { data, status } = yield call(products, token, truckId);

	if (status && status !== 401) {
		yield put(setProducts({ products: data }));
		yield put(fetchLastProducts());
	}
}

function* fetchAllProducts() {
	const token = yield select(authSelectors.getToken);
	const { status, data } = yield call(allProducts, token);

	if (status === 200) {
		yield put(setProducts({ products: data }));
	}
}

function* handleFetchLastProducts() {
	const truckId = yield select(trucksSelectors.getAuthenticatedTruckId);
	const token = yield select(authSelectors.getTruckToken);
	const loggedIn = yield* checkIsLoggedIn();

	if (!loggedIn) return;

	const { data, status } = yield call(lastProducts, token, truckId);

	if (status === 200) {
		yield put(setLastProducts({ lastProducts: data }));
	}
}

function* uploadExcel(action) {
	const { payload: { file } } = action;
	const adminToken = yield select(authSelectors.getAdminToken);
	yield put(toggleExcelUpdatePending());
	const { data } = yield call(database, adminToken, file);
	yield put(toggleExcelUpdatePending());
	yield put(setExcelChanges(data));
}

function* handleAcceptDatabase() {
	const adminToken = yield select(authSelectors.getAdminToken);
	const { status } = yield call(acceptDatabase, adminToken);

	if (status === 200) {
		yield put(showModal({ modalType: MODAL.DATABASE_ACCEPTED }));
	}
}

function* handleDownloadExcel() {
	const token = yield select(authSelectors.getAdminToken);
	const response = yield call(downloadExcel, token);

	if (response) {
		const url = window.URL.createObjectURL(new Blob([response.data]));
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute('download', 'datenbank.xlsx');
		document.body.appendChild(link);
		link.click();
	}
}

export const fetchProductsSaga = takeEvery(
	TYPES.FETCH_PRODUCTS,
	fetchProducts
);

export const fetchAllProductsSaga = takeEvery(
	TYPES.FETCH_ALL_PRODUCTS,
	fetchAllProducts
);

export const fetchLastProductsSaga = takeEvery(
	TYPES.FETCH_LAST_PRODUCTS,
	handleFetchLastProducts
);

export const uploadExcelSaga = takeEvery(
	TYPES.UPLOAD_EXCEL,
	uploadExcel
);

export const acceptDatabaseSaga = takeEvery(
	TYPES.ACCEPT_DATABASE,
	handleAcceptDatabase
);

export const downloadExcelSaga = takeEvery(
	TYPES.DOWNLOAD_EXCEL,
	handleDownloadExcel
);
