export const authentication = {
	token: null,
	passwordValid: true
};

export const general = {
	darkTheme: false,
	quickMode: false,
	initialSearch: true
};

export const modal = {
	showModal: false
};
