import * as TYPES from './types';

const modal = (state = {}, { type, payload }) => {
	switch (type) {
		case TYPES.SHOW_MODAL:
			return {
				...payload,
				showModal: true
			};
		case TYPES.HIDE_MODAL:
			return {
				showModal: false
			};
		default:
			return state;
	}
};

export default modal;
