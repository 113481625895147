import reducer from './reducers';

import * as deliveriesActions from './actions';
import * as deliveriesTypes from './types';
import * as deliveriesSelectors from './selectors';
import * as deliveriesOperations from './operations';

export {
	deliveriesActions,
	deliveriesTypes,
	deliveriesSelectors,
	deliveriesOperations
};

export default reducer;
