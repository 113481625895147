import { createSelector } from 'reselect';
import { getTruckId } from '../authentication/selectors';

export const getTrucks = createSelector(
	[({ trucks }) => trucks.trucks],
	(trucks) => trucks
);

export const getTrucksIds = createSelector(
	[getTrucks],
	(trucks) => trucks?.map(({ username }) => username) || []
);

export const isSpareTruck = createSelector(
	[getTrucks, getTruckId],
	(trucks, id) => trucks?.find(({ username }) => username === id).is_spare
);

export const getAuthenticatedTruckId = createSelector(
	[getTrucks, getTruckId],
	(trucks, truckId) => trucks?.find(({ username }) => username === truckId).id
);
