import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
	ApplyButton,
	ButtonsContainer,
	EditNoteButton,
	InvoiceSummaryScreenWrapper,
	InvoiceSummaryTable,
	NoteButton,
	TablesContainer
} from '../../styles/InvoiceSummaryScreen/InvoiceSummaryScreen';
import { INVOICE, MODAL, PATHS, STRINGS } from '../../../constants';
import { ReactComponent as NoteIcon } from '../../../../icons/make_note.svg';
import InvoiceSummaryItem from './InvoiceSummaryItem';
import DelayedButton from '../Common/DelayedButton';
import { modalActions, modalSelectors } from '../../../state/ducks/modal';
import { deliveriesActions, deliveriesSelectors } from '../../../state/ducks/deliveries';
import { useEnterClicked } from '../../../utils/hooks/useEnterClicked';

const InvoiceSummaryScreen = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const isSecondWarehouse = useSelector(deliveriesSelectors.isSecondWarehousePresent);
	const note = useSelector(deliveriesSelectors.getNote);
	const groupedItems = useSelector(deliveriesSelectors.getItemsGroupedByDestination);
	const submissionStatus = useSelector(deliveriesSelectors.getSubmissionStatus);
	const modalType = useSelector(modalSelectors.getModalType);

	const makeNote = () => {
		dispatch(modalActions.showModal({ modalType: MODAL.MAKE_NOTE }));
	};

	const handleApplyClicked = () => {
		dispatch(deliveriesActions.submitInvoice());
	};

	const lowestIndex = (group) => Math.min(...group.map(([, { index }]) => Number(index)));

	useEnterClicked(() => {
		if (!modalType) {
			handleApplyClicked();
		}
	});

	useEffect(() => {
		if (submissionStatus === INVOICE.SUCCESS) {
			history.push(PATHS.GOOD_RIDE);
			dispatch(deliveriesActions.setSubmissionStatus({ submissionStatus: null }));
		}
	}, [dispatch, history, submissionStatus]);

	return (
		<InvoiceSummaryScreenWrapper>
			<TablesContainer>
				{Object.entries(groupedItems)
					.sort(([, a], [, b]) => lowestIndex(a) > lowestIndex(b) ? 1 : -1)
					.map(([key, group]) => {
						return (
							<InvoiceSummaryTable key={key}>
								{group
									.sort(([, { index: a }], [, { index: b }]) => Number(a) < Number(b) ? -1 : 1)
									.map(([, item]) => {
										return (
											<InvoiceSummaryItem
												key={item.id}
												index={item.index}
												name={item.name}
												shortName={item.shortName}
												dimensions={item.dimensions}
												articleNr={item.articleNr}
												destination={item.destination}
												capacity={item.maxCapacity}
												amount={item.currentAmount}
												red={item.destination === STRINGS.FURTHER_LAGER}
											/>
										);
									})}
							</InvoiceSummaryTable>
						);
					})}
			</TablesContainer>
			<ButtonsContainer>
				<DelayedButton onClick={makeNote} component={note ? EditNoteButton : NoteButton}>
					<div><NoteIcon/></div>
					<p>{note ? STRINGS.EDIT_NOTE : STRINGS.NOTE}</p>
				</DelayedButton>
				<DelayedButton onClick={handleApplyClicked} component={ApplyButton}>
					{isSecondWarehouse ? STRINGS.BOOK_FURTHER_WAREHOUSE : STRINGS.BOOK_DELIVERY}
				</DelayedButton>
			</ButtonsContainer>
		</InvoiceSummaryScreenWrapper>
	);
};

export default InvoiceSummaryScreen;
