import styled from 'styled-components';

export const DropdownWrapper = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	transform: ${({ large, top, right }) =>
	large && `
			scale(1.35) translateY(-4rem) 
			${top && `translateY(${top})`} 
			${right && `translateX(${right})`}
		`};
	transform-origin: ${({ origin }) => origin};
	z-index: 99;
`;

export const DropdownButton = styled.button`
	width: ${({ thin }) => thin ? 40 : 55}rem;
	height: 7.5rem;
	background-color: ${({ theme }) => theme.colors.primaryWhite};
	border: 4px solid ${({ theme, red }) => red ? theme.colors.primaryRed : theme.colors.fontDark};
	border-radius: 6px;
	display: flex;
	align-items: center;
	padding: 0.5rem 0;
	z-index: ${({ moveTop }) => moveTop ? 20 : 100};
	
	&:active,
	&:hover,
	&:focus {
		outline: 0;
	}
	
	svg	{
		fill: ${({ theme, red }) => red ? theme.colors.primaryRed : theme.colors.fontDark};
	}
`;

export const DropdownTitle = styled.div`
	font-family: 'Roboto', sans-serif;
	font-weight: 400;
	font-size: ${({ open }) => open ? 3.5 : 4.2}rem;
	line-height: 5rem;
	color: black;
	display: flex;
	padding-left: 2.5rem;
	margin-right: auto;
`;

export const DropdownIcon = styled.div`
	svg {
		height: 3rem;
		margin-right: 2rem;
	}
`;

export const DropdownList = styled.div`
	display: ${({ isOpen }) => !isOpen && 'none'};
	position: absolute;
	top: ${({ moveTop }) => moveTop ? '-100%' : '7rem'};
	left: 50%;
	width: 100%;
	max-height: 31rem;
	overflow-y: scroll;
	flex-direction: column;
	border: 4px solid #333333;
	border-top: ${({ moveTop }) => !moveTop && 'none'};
	border-radius: 4px;
	transform: translateX(-50%);
	z-index: 99;
	background-color: ${({ theme }) => theme.colors.primaryWhite};
`;

export const SelectButton = styled.button`
	background-color: ${({ theme }) => theme.colors.primaryWhite};
	border: none;
	font-family: Roboto, sans-serif;
	font-size: 3.5rem;
	height: 6rem;
	padding: 0 0 0 2.5rem;
	width: 100%;
	font-weight: ${({ selected }) => selected ? 400 : 300};
	text-align: left;
	
	&:active,
	&:hover,
	&:focus {
		outline: 0;
	}
	
	:first-child {
		margin-top: ${({ moveTop }) => !moveTop && 0.5}rem;
	}
`;

export const DropdownSmallWrapper = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	width: 32rem;
	background-color: ${({ theme }) => theme.colors.primaryWhite};
`;

export const DropdownButtonSmall = styled.button`
	display: flex;
	align-items: center;
	width: 100%;
	height: 4.8rem;
	border: 2px solid black;
	border-bottom: ${({ isOpen }) => isOpen && 'none'};
	border-radius: 3px;
	background-color: ${({ theme }) => theme.colors.primaryWhite};
	padding: 0;
	
	svg {
		width: 3.2rem;
		margin-right: 1.5rem;
		margin-top: ${({ isOpen }) => isOpen && '-2px'};
	}
	
	&:focus {
		outline: none;
	}
`;

export const DropdownSmallTitle = styled.div`
	font-family: Roboto, sans-serif;
	font-weight: ${({ red }) => red ? 500 : 400};
	font-size: 3rem;
	color: ${({ theme, red }) => red ? theme.colors.primaryRed : 'black'};
	margin-left: 2.5rem;
	margin-right: auto;
	text-align: left;
	margin-top: ${({ isOpen }) => isOpen && '-2px'};
`;

export const DropdownSmallList = styled.div`
	display: ${({ isOpen }) => !isOpen && 'none'};
	transform: ${({ isOpen }) => isOpen && 'translateY(-2px)'};
	position: absolute;
	background-color: ${({ theme }) => theme.colors.primaryWhite};
	width: 100%;
	top: 4.8rem;
	z-index: 99;
	border: 2px solid black;
	border-top: none;
	border-bottom-left-radius: 3px;
	border-bottom-right-radius: 3px;
	padding-top: 0.5rem;
	max-height: 18.5rem;
	overflow: scroll;
`;
