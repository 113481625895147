import React, { createContext, useContext } from 'react';

const ReportControlsContext = createContext(undefined);

export const ReportControlsContextProvider = ({ children, value }) => {
	return (
		<ReportControlsContext.Provider value={value}>
			{children}
		</ReportControlsContext.Provider>
	);
};

export const useReportControlsContext = () => {
	const context = useContext(ReportControlsContext);

	if (context === undefined) {
		throw new Error('useReportControlsContext must be used within a ReportControlsContextProvider');
	}

	return context;
};
