import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import Navbar from './components/Navbar/Navbar';
import { darkTheme, lightTheme, MODAL, PATH_NAMES, PATHS, SERVICE_WORKER, STORAGE } from '../constants';
import { authActions, authSelectors } from '../state/ducks/authentication';
import RidesMenuScreen from './components/MenuScreen/RidesMenuScreen';
import PrivateRoute from './components/Routes/PrivateRoute';
import PublicRoute from './components/Routes/PublicRoute';
import DestinationScreen from './components/DestinationScreen/DestinationScreen';
import { AppContainer } from './styles/AppContainer/AppContainer';
import SearchScreen from './components/SearchScreen/SearchScreen';
import { trucksActions } from '../state/ducks/trucks';
import { generalActions, generalSelectors } from '../state/ducks/general';
import ProductScreen from './components/ProductScreen/ProductScreen';
import SummaryScreen from './components/SummaryScreen/SummaryScreen';
import AdminLogin from './components/AdminPanel/AdminLogin';
import AdminMenu from './components/AdminPanel/AdminMenu';
import AdminRoute from './components/Routes/AdminRoute';
import WarehouseScreen from './components/AdminPanel/WarehouseScreen';
import ExcelScreen from './components/AdminPanel/ExcelScreen';
import PreviousRidesScreen from './components/PreviousRidesScreen/PreviousRidesScreen';
import Modal from './components/Modal/Modal';
import GoodRideScreen from './components/GoodRideScreen/GoodRideScreen';
import ScannerScreen from './components/ScannerScreen/ScannerScreen';
import { productsActions } from '../state/ducks/products';
import InvoiceScreen from './components/InvoiceScreen/InvoiceScreen';
import InvoiceSummaryScreen from './components/InvoiceSummaryScreen/InvoiceSummaryScreen';
import MainMenuScreen from './components/MenuScreen/MainMenuScreen';
import LoginScreen from './components/LoginScreen/LoginScreen';
import ReportsScreen from './components/ReportsScreen/ReportsScreen';
import CorrectionScreen from './components/CorrectionScreen/CorrectionScreen';
import CorrectionSearchScreen from './components/CorrectionScreen/CorrectionSearchScreen';
import ProductCorrectionScreen from './components/ProductScreen/ProductCorrectionScreen';
import { getItemFromStorage, putItemIntoStorage } from '../utils/storage/storage';
import { modalActions } from '../state/ducks/modal';

const GlobalStyle = createGlobalStyle`
	html {
		font-size: 62.5%;
	}
	
	body {
		user-select: none;
	}
`;

const App = () => {
	const history = useHistory();
	const token = useSelector(authSelectors.getToken);
	const adminToken = useSelector(authSelectors.getAdminToken);
	const darkThemeEnabled = useSelector(generalSelectors.isDarkTheme);
	const dispatch = useDispatch();

	const checkLastAction = useCallback(() => {
		const lastAction = getItemFromStorage(STORAGE.LAST_ACTION);

		if (moment().diff(lastAction, 'seconds') >= 60 * 180) {
			dispatch(authActions.attemptLogout());
			window.location.reload();
		}
	}, [dispatch]);

	useEffect(() => {
		dispatch(authActions.checkStorageForToken());
		dispatch(authActions.fetchGutexUsers());
		dispatch(trucksActions.fetchTrucks());
		dispatch(generalActions.checkDarkTheme());
	}, [dispatch]);

	useEffect(() => {
		const savedPath = getItemFromStorage(STORAGE.PATH);

		if (token) {
			history.push(PATHS.MAIN_MENU);
		} else if (adminToken) {
			history.push(PATHS.ADMIN_MENU);
		}

		if (savedPath === PATH_NAMES.REPORTS) {
			history.push(PATHS.REPORTS);
		}
	}, [adminToken, history, token]);

	useEffect(() => {
		const channel = new BroadcastChannel(SERVICE_WORKER.CHANNEL);

		channel.onmessage = (e) => {
			if (e.data === SERVICE_WORKER.UPDATE_LAST_5) {
				setTimeout(() => {
					dispatch(productsActions.fetchLastProducts());
				}, 0);
			} else if (e.data === SERVICE_WORKER.UPDATE_LIVENESS) {
				putItemIntoStorage(STORAGE.LAST_ACTION, moment.utc().local().format());
			} else if (e.data.msg === SERVICE_WORKER.SLOW_INTERNET) {
				dispatch(modalActions.showModal({
					modalType: MODAL.SLOW_INTERNET,
					ridesData: e.data.ridesData
				}));
			}
		};

		return () => channel.close();
	}, [dispatch]);

	useEffect(() => {
		checkLastAction();

		const interval = setInterval(() => {
			checkLastAction();
		}, 1000 * 60);

		return () => clearInterval(interval);
	}, [checkLastAction]);

	return (
		<ThemeProvider theme={darkThemeEnabled ? darkTheme : lightTheme}>
			<AppContainer>
				<GlobalStyle/>
				<Modal/>
				<PublicRoute path={PATHS.PAGE} component={Navbar}/>
				<PublicRoute exact path={PATHS.DEFAULT} component={Navbar}/>
				<PublicRoute exact path={PATHS.LOGIN} component={LoginScreen} restricted redirectPath={PATHS.RIDES_MENU}/>
				<PublicRoute exact path={PATHS.ADMIN} component={AdminLogin} restricted redirectPath={PATHS.ADMIN_MENU}/>
				<AdminRoute exact path={PATHS.ADMIN_MENU} component={AdminMenu}/>
				<AdminRoute exact path={PATHS.WAREHOUSE} component={WarehouseScreen}/>
				<AdminRoute exact path={PATHS.EXCEL} component={ExcelScreen}/>
				<PrivateRoute exact path={PATHS.MAIN_MENU} component={MainMenuScreen}/>
				<PrivateRoute exact path={PATHS.RIDES_MENU} component={RidesMenuScreen}/>
				<PrivateRoute exact path={PATHS.DESTINATION} component={DestinationScreen}/>
				<PrivateRoute exact path={PATHS.SEARCH} component={SearchScreen}/>
				<PrivateRoute exact path={PATHS.SEARCH_CORRECTION} component={CorrectionSearchScreen}/>
				<PrivateRoute exact path={`${PATHS.PRODUCTS}${PATHS.PRODUCT_ID}`} component={ProductScreen}/>
				<PrivateRoute exact path={`${PATHS.PRODUCTS_CORRECTION}${PATHS.PRODUCT_ID}`}
											component={ProductCorrectionScreen}/>
				<PrivateRoute exact path={PATHS.SUMMARY} component={SummaryScreen}/>
				<PrivateRoute exact path={PATHS.PREVIOUS_RIDES} component={PreviousRidesScreen}/>
				<PrivateRoute exact path={PATHS.SCAN_INVOICE} component={ScannerScreen}/>
				<PrivateRoute exact path={PATHS.INVOICE} component={InvoiceScreen}/>
				<PrivateRoute exact path={PATHS.INVOICE_SUMMARY} component={InvoiceSummaryScreen}/>
				<PrivateRoute exact path={PATHS.GOOD_RIDE} component={GoodRideScreen}/>
				<PrivateRoute exact path={PATHS.REPORTS} component={ReportsScreen}/>
				<PrivateRoute exact path={PATHS.CORRECTION} component={CorrectionScreen}/>
			</AppContainer>
		</ThemeProvider>
	);
};

export default App;
