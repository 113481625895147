export const FETCH_RIDES = 'FETCH_RIDES';
export const SET_RIDES = 'SET_RIDES';
export const DELETE_RIDE = 'DELETE_RIDE';
export const DELETE_RIDE_FROM_LIST = 'DELETE_RIDE_FROM_LIST';
export const SEND_RIDE_TO_INDEXED_DB = 'SEND_RIDE_TO_INDEXED_DB';
export const SET_CURRENT_RIDE_ID = 'SET_CURRENT_RIDE_ID';
export const SET_CURRENT_RIDE_TIMESTAMP = 'SET_CURRENT_RIDE_TIMESTAMP';
export const UPDATE_RIDE = 'UPDATE_RIDE';
export const REMOVE_FROM_DB = 'REMOVE_FROM_DB';
export const RESEND_RIDES = 'RESEND_RIDES';
