import React, { useEffect, useState } from 'react';
import moment from 'moment';
import DatePicker from './DatePicker';
import ReasonDropdown from './ReasonDropdown';
import { ReportControlsContainer } from '../../../styles/Modal/ModalReports';
import { ReportControlsContextProvider } from './useReportControlsContext';
import { STRINGS } from '../../../../constants';
import DataDropdown from './DataDropdown';
import ControlledDatePicker from './ControlledDatePicker';

const ReportControls = ({
	children,
	updateFilters = () => {
	}
}) => {
	const currentDate = moment(new Date()).format('yyyy-MM-DD');
	const [startDate, setStartDate] = useState(currentDate);
	const [endDate, setEndDate] = useState(currentDate);
	const [reason, setReason] = useState(STRINGS.ALL);

	const providerProps = {
		startDate,
		setStartDate,
		endDate,
		setEndDate,
		reason,
		setReason
	};

	useEffect(() => {
		updateFilters({ startDate, endDate, reason });
	}, [startDate, endDate, reason, updateFilters]);

	return (
		<ReportControlsContextProvider value={providerProps}>
			<ReportControlsContainer>
				{children}
			</ReportControlsContainer>
		</ReportControlsContextProvider>
	);
};

ReportControls.DatePicker = DatePicker;
ReportControls.ControlledDatePicker = ControlledDatePicker;
ReportControls.ReasonDropdown = ReasonDropdown;
ReportControls.DataDropdown = DataDropdown;

export default ReportControls;
