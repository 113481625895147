import * as TYPES from './types';

export const setSearchValue = (payload) => ({
	type: TYPES.SET_SEARCH_VALUE,
	payload
});

export const setSearchContext = (payload) => ({
	type: TYPES.SET_SEARCH_CONTEXT,
	payload
});
