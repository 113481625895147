import styled from 'styled-components';
import { ButtonSubmit } from '../Common/Common';
import { DropdownButtonSmall, DropdownSmallList, DropdownSmallTitle, DropdownSmallWrapper } from '../Dropdown/Dropdown';

export const CorrectionScreenWrapper = styled.div`
	position: relative;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: ${({ theme }) => theme.colors.primary};
	padding: 0 5rem;
	font-family: Roboto, sans-serif;
`;

export const CorrectionItemsContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-top: 12rem;
`;

export const CorrectionItemRow = styled.div`
	display: flex;
	align-items: ${({ top }) => top ? 'flex-start' : 'center'};
	width: 100%;
	margin: 1.8rem 0;
	margin-top: ${({ removeMarginTop }) => removeMarginTop && '-1.5rem'};
`;

export const Divider = styled.span`
	display: inline-block;
	width: 100%;
	border: 1px solid black;
	
	&:last-child {
		margin-bottom: 4rem;
	}
`;

export const ItemLight = styled.div`
	font-size: 3.2rem;
	font-weight: 300;
	min-width: ${({ wide }) => wide ? '100%' : '30rem'};
	margin-right: ${({ small }) => small && 'auto'};
	text-transform: capitalize;
	grid-column: ${({ col }) => col};
`;

export const ItemNarrow = styled(ItemLight)`
	min-width: 15rem;
`;

export const ItemBold = styled(ItemLight)`
	font-weight: 700;
`;

export const ReasonButtonsContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 34rem);
	grid-column-gap: 3rem;
	grid-row-gap: 1.5rem;
`;

export const ReasonButton = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 6rem;
	border: 4px solid ${({ theme, invalid }) => invalid ? theme.colors.primaryRed : 'currentColor'};
	border-radius: 6px;
	color: ${({ theme, selected }) => selected ? theme.colors.primaryGreen : theme.colors.greyMedium2};
	background-color: ${({ theme, invalid }) => invalid ? theme.colors.mediumRed2 : theme.colors.primaryWhite};
	font-weight: 400;
	font-size: 3.2rem;
	transition: all .2s;
	grid-column: ${({ wide }) => wide && '1 / 3'};
	
	&:hover {
		background-color: ${({ theme }) => theme.colors.lightGreenHover2};
	}
	
	&:focus {
		outline: none;
	}
`;

export const ReportsButton = styled(ReasonButton)`
	width: 30rem;
	margin-left: auto;
	
	&:hover,
	&:focus {
		background-color: ${({ theme }) => theme.colors.greyMedium3};
	}
	
	svg {
		width: 3rem;
		height: 3.8rem;
		margin-right: 1.5rem;
		fill: ${({ theme }) => theme.colors.greyMedium2};
	}
`;

export const AmountButton = styled.span`
	display: inline-block;
	position: relative;
	width: 3.7rem;
	height: 3.7rem;
	background-color: ${({ theme, invalid, selected }) => {
	if (invalid) {
		return theme.colors.mediumRed2;
	}
	if (selected) {
		return theme.colors.primaryGreen;
	}

	return theme.colors.greyMedium2;
}};
	margin-right: 1rem;
	border-radius: 6px;
	transition: all .2s;
	border: 4px solid ${({ theme, invalid, selected }) => {
	if (invalid) {
		return theme.colors.primaryRed;
	}
	if (selected) {
		return theme.colors.primaryGreen;
	}

	return theme.colors.greyMedium2;
}};
	
	svg {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 2.7rem;
		height: 2.7rem;
		fill: ${({ theme, invalid }) => invalid ? theme.colors.primaryRed : theme.colors.primary};
	}
`;

export const SelectArticleButton = styled.button`
	display: flex;
	align-items: center;
	text-align: left;
	width:  31rem;
	height: 9.5rem;
	background-color: ${({ theme, invalid }) => invalid ? theme.colors.mediumRed2 : theme.colors.greyMedium2};
	border: 4px solid ${({ theme, invalid }) => invalid ? theme.colors.primaryRed : theme.colors.greyMedium2};
	border-radius: 6px;
	color: ${({ theme }) => theme.colors.primary};
	font-weight: 700;
	font-size: 3.2rem;
	transition: all .2s;
	
	&:hover,
	&:focus {
		background-color: ${({ theme }) => theme.colors.greyMediumHover};
	}
	
	svg {
		width: 5.5rem;
		height: 5.5rem;
		margin: 0 2.8rem;
	}
`;

export const ApplyButton = styled(ButtonSubmit)`
	width: 100%;
	height: 9rem;
	font-size: 3.2rem;
	margin: auto 0 8rem;
`;

export const SelectedProduct = styled.div`
	display: grid;
	grid-template-columns: 25rem 1fr;
	width: 100%;
`;

export const ProductSelectionWrapper = styled.div`
	display: grid;
	grid-template-columns: max-content 1fr max-content;
	width: 100%;
`;

export const ReplaceButton = styled.button`
	width: 5.5rem;
	height: 5.5rem;
	border: none;
	border-radius: 6px;
	background-color: ${({ theme }) => theme.colors.greyMedium2};
	
	svg {
		width: 4rem;
		height: 4rem;
		fill: ${({ theme }) => theme.colors.primaryWhite};
	}
`;

export const DropdownWrapper = styled.div`
	width: 34rem;
	color: ${({ theme, invalid }) => invalid ? theme.colors.greyMedium2 : theme.colors.primaryGreen};
	
	${DropdownSmallWrapper} {
		width: 100%;
		margin-bottom: 0;
	}
	
	${DropdownButtonSmall} {
		height: 6rem;
		border-radius: ${({ isOpen }) => isOpen ? 0 : '6px'};
		border: 4px solid ${({ theme, invalid }) => invalid ? theme.colors.primaryRed : theme.colors.primaryGreen};
		color: ${({ theme, invalid }) => invalid ? theme.colors.greyMedium2 : theme.colors.primaryGreen};
		background-color: ${({ theme, invalid }) => invalid && theme.colors.mediumRed2};
		
		${DropdownSmallTitle} {
			font-size: 3.2rem;
			color: inherit;
			margin-top: 0;
		}
		
		svg {
			width: 2.5rem;
			margin-top: 0;
			fill: ${({ theme, invalid }) => invalid ? theme.colors.greyMedium2 : theme.colors.primaryGreen};
		}
	}
	
	${DropdownSmallList} {
		overflow-x: hidden;
		border-radius: 0 0 6px 6px;
		border: 4px solid ${({ theme, invalid }) => invalid ? theme.colors.primaryRed : theme.colors.primaryGreen};
		border-top: none;
		transform: translateY(4px);
		max-height: 19rem;
		font-size: 3.2rem;
		background-color: ${({ theme, invalid }) => invalid && theme.colors.mediumRed2};
		
		& > * {
			color: inherit;
		}
	}
`;

export const NoteArea = styled.textarea`
	width: 70rem;
	height: 13rem;
	resize: none;
	border: 3px solid ${({ theme }) => theme.colors.greyMedium2};
	border-radius: 6px;
	font-family: Roboto, sans-serif;
	font-weight: 300;
	font-size: 3.2rem;
	outline: none;
	padding: 2rem 2.5rem;
	background-color: ${({ theme }) => theme.colors.primaryWhite};
	
	::placeholder {
		color: ${({ theme }) => theme.colors.greyLight7};
		opacity: 1;
	}
`;
