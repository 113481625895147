export const CHECK_DARK_THEME = 'CHECK_DARK_THEME';
export const TOGGLE_DARK_THEME = 'TOGGLE_DARK_THEME';
export const TOGGLE_QUICK_MODE = 'TOGGLE_QUICK_MODE';
export const SET_QUICK_MODE = 'SET_QUICK_MODE';
export const SET_DARK_THEME = 'SET_DARK_THEME';
export const SET_RIDE_CONTEXT = 'SET_RIDE_CONTEXT';
export const SET_DB_TYPE = 'SET_DB_TYPE';
export const SET_DB_ID = 'SET_DB_ID';
export const SET_RIDE_ID = 'SET_RIDE_ID';
export const SET_INITIAL_SEARCH = 'SET_INITIAL_SEARCH';
