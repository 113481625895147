import * as TYPES from './types';
import { productsTypes } from '../products';
import { ridesTypes } from '../rides';

const general = (state = {}, { type, payload }) => {
	switch (type) {
		case TYPES.SET_DARK_THEME:
			return {
				...state,
				darkTheme: !state.darkTheme
			};
		case TYPES.TOGGLE_QUICK_MODE:
			return {
				...state,
				quickMode: !state.quickMode
			};
		case TYPES.SET_RIDE_CONTEXT:
		case TYPES.SET_DB_TYPE:
		case TYPES.SET_DB_ID:
		case TYPES.SET_RIDE_ID:
		case TYPES.SET_QUICK_MODE:
			return {
				...state,
				...payload
			};
		case TYPES.SET_INITIAL_SEARCH:
			return {
				...state,
				initialSearch: false
			};
		case productsTypes.CANCEL_RIDE:
		case ridesTypes.SEND_RIDE_TO_INDEXED_DB:
			return {
				...state,
				rideContext: null,
				dbType: null,
				dbId: null,
				rideId: null
			};
		default:
			return state;
	}
};

export default general;
