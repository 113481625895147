import React from 'react';
import { ProductDetailsContainer, ProductText, ProductTextLight } from '../../styles/ReportsScreen/ReportsScreen';

const ProductDetails = ({
	name,
	shortName,
	dimensions,
	articleNr,
	unit,
	amount
}) => {
	return (
		<ProductDetailsContainer>
			<ProductText>{name}</ProductText>
			<ProductTextLight>{shortName}</ProductTextLight>
			<ProductText col={'1 / 2'}>{dimensions}</ProductText>
			<ProductTextLight col={'2 / 3'}>{articleNr}</ProductTextLight>
			<ProductTextLight col={'3 / 4'} align={'right'} dangerouslySetInnerHTML={{
				__html: `${unit}: <strong>${amount}</strong>`
			}}/>
		</ProductDetailsContainer>
	);
};

export default ProductDetails;
