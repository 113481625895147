import { createSelector } from 'reselect';

export const getShowModal = createSelector(
	[({ modal }) => modal.showModal],
	(showModal) => showModal
);

export const getModalType = createSelector(
	[({ modal }) => modal.modalType],
	(modalType) => modalType
);

export const getDeleteSelectionId = createSelector(
	[({ modal }) => modal.selectionId],
	(selectionId) => selectionId
);

export const getDeleteRideData = createSelector(
	[({ modal }) => modal.rideData],
	(rideData) => rideData
);

export const getRidesData = createSelector(
	[({ modal }) => modal.ridesData],
	(ridesData) => ridesData
);
