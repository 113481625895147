import styled from 'styled-components';
import { ModalContent } from './Modal';
import { DateInput, FilterDropdownWrapper } from '../ReportsScreen/ReportsScreen';
import { DropdownButtonSmall, DropdownSmallWrapper } from '../Dropdown/Dropdown';

export const ModalReportsContent = styled(ModalContent)`
	display: flex;
	justify-content: center;
	align-items: center;
	width: calc(100% - 3rem);
	height: calc(100% - 12rem);
	align-self: flex-end;
	margin-bottom: 2rem;
`;

export const CloseModalButton = styled.button`
	position: absolute;
	width: 3rem;
	height: 3rem;
	top: 2rem;
	right: 2rem;
	border: none;
	background-color: transparent;
	
	&:before,
	&:after {
		content: "";
		position: absolute;
		display: inline-block;
		width: 3.5rem;
		height: 4px;
		border-radius: 10px;
		background-color: black;
		top: 50%;
		left: 50%;
	}
	
	&:before {
		transform: translate(-50%, -50%) rotate(45deg);
		box-shadow: 3px 2px 5px rgba(0, 0, 0, 0.35);
	}
	
	&:after {
		transform: translate(-50%, -50%) rotate(-45deg);
		box-shadow: -3px 2px 6px rgba(0, 0, 0, 0.3);
	}
`;

export const ReportFiltersContainer = styled.div`
	display: grid;
	grid-row-gap: 2.5rem;
	width: calc(100% - 5rem);
`;

export const Divider = styled.span`
	display: inline-block;
	width: 100%;
	height: 2px;
	background-color: black;
`;

export const ReportTitle = styled.div`
	display: flex;
	align-items: center;
	font-family: Roboto, sans-serif;
	font-weight: 400;
	font-size: 3.2rem;
	
	svg {
		width: 5rem;
		height: 6.5rem;
		fill: ${({ theme }) => theme.colors.greyMedium2};
		margin-right: 1.8rem;
	}
`;

export const DownloadReportButton = styled.button`
	width: 33rem;
	height: 6rem;
	justify-self: flex-end;
	border: none;
	border-radius: 6px;
	background-color: ${({ theme }) => theme.colors.greyMedium2};
	font-family: Roboto, sans-serif;
	font-weight: 400;
	font-size: 3.2rem;
	color: ${({ theme }) => theme.colors.primaryWhite};
	transition: all .2s;
	
	&:hover,
	&:focus {
		background-color: ${({ theme }) => theme.colors.greyMedium3};
	}
`;

export const ReportControlsContainer = styled.div`
	display: flex;
	justify-content: space-around;
`;

export const DatePickerContainer = styled.div`
	display: flex;
`;

export const FilterLabel = styled.span`
	display: inline-block;
	font-family: Roboto, sans-serif;
	font-weight: 400;
	font-size: 3.2rem;
	margin-right: 1.5rem;
`;

export const DatePickerInput = styled(DateInput)`
	width: 26rem;
	height: 5.5rem;
	padding-left: 1.5rem;
`;

export const DropdownControlWrapper = styled.div`
	display: flex;
	align-items: center;
`;
export const DropdownWrapper = styled(FilterDropdownWrapper)`

	width: 38rem;
	
	${DropdownSmallWrapper} {
		background-color: ${({ theme }) => theme.colors.primary };
		height: 100%;
	}
	
	${DropdownButtonSmall} {
		height: 5.5rem;
	}
`;
