import styled from 'styled-components';
import { DismissButton, ModalContent, ModalResetButtonsContainer, ResetButton } from './Modal';
import {
	Article,
	DeleteRideButton,
	EditRideButton,
	PreviousRidesListItem
} from '../PreviousRidesScreen/PreviousRidesScreen';

export const ModalSlowInternetContent = styled(ModalContent)`
	width: calc(100% - 2rem);
	height: calc(100% - 2rem);
	overflow: scroll;
`;

export const ModalSlowInternetText = styled.div`
	font-family: Roboto, sans-serif;
	font-size: 3.2rem;
	font-weight: 400;
	margin-top: 3.5rem;
	
	strong {
		font-weight: 700;
	}
`;

export const ButtonsContainer = styled(ModalResetButtonsContainer)`
	grid-column-gap: 6rem;
	margin-top: 8rem;
	margin-bottom: 3rem;
`;

export const ResendRejectButton = styled(ResetButton)`
	width: 50rem;
	height: 7.5rem;
`;

export const ResendButton = styled(DismissButton)`
	width: 50rem;
	height: 7.5rem;
`;

export const RideItemWrapper = styled.div`
	width: calc(100% - 12rem);
	border-top: 1px solid black;
	border-right: 1px solid black;
	border-left: 1px solid black;
	margin-top: 4rem;
	background-color: ${({ theme }) => theme.colors.greyLight6};
	
	${PreviousRidesListItem} {
		border-bottom: 1px solid black;
	}
	
	${EditRideButton} {
		display: none;
	}
	
	${DeleteRideButton} {
		display: none;
	}
	
	${Article} {
		text-align: left;
	}
`;
