import React from 'react';
import { ButtonGoBack, ButtonsContainerCustom, ModalContent, ModalText } from '../../styles/Modal/Modal';
import { STRINGS } from '../../../constants';
import DelayedButton from '../Common/DelayedButton';

const ModalOfflineRidesContent = ({
	handleDismiss,
	offlineAmount,
	showModal
}) => {
	const getModalText = () =>
		`<strong>${offlineAmount} ${STRINGS.RIDES}</strong> ${STRINGS.OFFLINE_RIDES_MSG}`;

	return (
		<ModalContent show={showModal}>
			<ModalText dangerouslySetInnerHTML={{
				__html: getModalText()
			}}/>
			<ButtonsContainerCustom marginTop={4}>
				<DelayedButton onClick={handleDismiss} component={ButtonGoBack}>
					{STRINGS.GO_BACK}
				</DelayedButton>
			</ButtonsContainerCustom>
		</ModalContent>
	);
};

export default ModalOfflineRidesContent;
