import * as TYPES from './types';

export const setNote = (payload) => ({
	type: TYPES.SET_NOTE,
	payload
});

export const setDeliveryLocation = (payload) => ({
	type: TYPES.SET_DELIVERY_LOCATION,
	payload
});

export const fetchInvoice = (payload) => ({
	type: TYPES.FETCH_INVOICE,
	payload
});

export const setInvoice = (payload) => ({
	type: TYPES.SET_INITIAL_INVOICE,
	payload
});

export const setMappedInvoice = (payload) => ({
	type: TYPES.SET_MAPPED_INVOICE,
	payload
});

export const toggleItemSelected = (payload) => ({
	type: TYPES.TOGGLE_ITEM_SELECTED,
	payload
});

export const setWarehouseForSelected = (payload) => ({
	type: TYPES.SET_WAREHOUSE_FOR_SELECTED,
	payload
});

export const setFurtherWarehouseForAllSelected = () => ({
	type: TYPES.SET_FURTHER_WAREHOUSE_FOR_ALL_SELECTED
});

export const resetInvoice = () => ({
	type: TYPES.RESET_INVOICE
});

export const resetInvoiceAbasSync = () => ({
	type: TYPES.RESET_INVOICE_ABAS_SYNC
});

export const updateItemAmount = (payload) => ({
	type: TYPES.UPDATE_ITEM_AMOUNT,
	payload
});

export const splitItem = (payload) => ({
	type: TYPES.SPLIT_ITEM,
	payload
});

export const clearInvoice = () => ({
	type: TYPES.CLEAR_INVOICE
});

export const submitInvoice = () => ({
	type: TYPES.SUBMIT_INVOICE
});

export const setSubmissionStatus = (payload) => ({
	type: TYPES.SET_SUBMISSION_STATUS,
	payload
});

export const bookInvoiceImmediately = () => ({
	type: TYPES.BOOK_INVOICE_IMMEDIATELY
});

export const setImmediateBookingPending = (payload) => ({
	type: TYPES.SET_IMMEDIATE_BOOKING_PENDING,
	payload
});

export const fetchInvoiceData = (payload) => ({
	type: TYPES.FETCH_INVOICE_DATA,
	payload
});

export const setInvoiceData = (payload) => ({
	type: TYPES.SET_INVOICE_DATA,
	payload
});

export const clearInvoiceData = () => ({
		type: TYPES.CLEAR_INVOICE_DATA
	}
);
