import { call, put, select, takeEvery } from 'redux-saga/effects';
import * as TYPES from './types';
import { addLocationToList, removeLocationFromList, setDestinations } from './actions';
import { addLocation, deleteLocation, locations, locationsAdmin } from '../../../api';
import { authSelectors } from '../authentication';
import { checkIsLoggedIn } from '../rides/operations';

function* fetchDestinations() {
	const token = yield select(authSelectors.getToken);
	const adminToken = yield select(authSelectors.getAdminToken);
	const loggedIn = yield* checkIsLoggedIn();

	if (!loggedIn) return;

	const { data, status } = yield call(locations, token || adminToken);

	if (status && status !== 401) {
		yield put(setDestinations({ destinations: data }));
	}
}

function* fetchAdminDestinations() {
	const adminToken = yield select(authSelectors.getAdminToken);
	const { data, status } = yield call(locationsAdmin, adminToken);

	if (status && status !== 401) {
		yield put(setDestinations({ destinations: data }));
	}
}

function* handleAddLocation(action) {
	const { payload } = action;
	const adminToken = yield select(authSelectors.getAdminToken);
	const { data, status } = yield call(addLocation, adminToken, payload);
	if (status === 201) {
		yield put(addLocationToList(data));
	}
}

function* handleDeleteLocation(action) {
	const { payload } = action;
	const adminToken = yield select(authSelectors.getAdminToken);
	const { status } = yield call(deleteLocation, adminToken, payload);

	if (status === 204) {
		yield put(removeLocationFromList(payload));
	}
}

export const fetchDestinationsSaga = takeEvery(
	TYPES.FETCH_DESTINATIONS,
	fetchDestinations
);

export const fetchAdminDestinationsSaga = takeEvery(
	TYPES.FETCH_ADMIN_DESTINATIONS,
	fetchAdminDestinations
);

export const addLocationSaga = takeEvery(
	TYPES.ADD_LOCATION,
	handleAddLocation
);

export const deleteLocationSaga = takeEvery(
	TYPES.DELETE_LOCATION,
	handleDeleteLocation
);
