import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
	EditRideContainer,
	EnterRideContainer,
	MenuScreenWrapper,
	NoteIcon,
	OptionIcon,
	OptionTextEdit,
	OptionTextEnter,
	SelectOptionContainer
} from '../../styles/MenuScreen/MenuScreen';
import TruckIcon from '../../../../icons/truck_logo.png';
import TruckIconDark from '../../../../icons/truck_logo_dark.png';
import NoteIconLight from '../../../../icons/note.png';
import NoteIconDark from '../../../../icons/note-dark.png';
import { PATHS, SERVICE_WORKER, STRINGS } from '../../../constants';
import { authSelectors } from '../../../state/ducks/authentication';
import { destinationActions } from '../../../state/ducks/destination';
import { generalActions, generalSelectors } from '../../../state/ducks/general';
import { productsActions } from '../../../state/ducks/products';
import { trucksActions } from '../../../state/ducks/trucks';
import DelayedButton from '../Common/DelayedButton';
import { ridesActions } from '../../../state/ducks/rides';
import BottomBar from '../Common/BottomBar';

const RidesMenuScreen = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const truckId = useSelector(authSelectors.getTruckId);
	const darkThemeEnabled = useSelector(generalSelectors.isDarkTheme);
	const initialSearch = useSelector(generalSelectors.isInitialSearch);

	useEffect(() => {
		dispatch(generalActions.setRideContext({ rideContext: null }));
		dispatch(trucksActions.fetchTrucks());
		dispatch(destinationActions.fetchDestinations());
		dispatch(productsActions.fetchProducts());
	}, [dispatch, initialSearch]);

	const handleEnterRideClick = () => history.push(PATHS.DESTINATION);

	const handleCorrectRideClicked = () => history.push(PATHS.PREVIOUS_RIDES);

	useEffect(() => {
		const channel = new BroadcastChannel(SERVICE_WORKER.CHANNEL);

		channel.onmessage = (e) => {
			if (e.data === SERVICE_WORKER.NEW_RIDES_AVAILABLE) {
				dispatch(ridesActions.fetchRides());
			}
		};

		return () => channel.close();
	}, [dispatch]);

	return (
		<MenuScreenWrapper>
			<SelectOptionContainer>
				<DelayedButton onClick={handleEnterRideClick} component={EnterRideContainer}>
					<OptionIcon src={darkThemeEnabled ? TruckIconDark : TruckIcon}/>
					<OptionTextEnter>{STRINGS.ENTER_A_RIDE}</OptionTextEnter>
				</DelayedButton>
				<DelayedButton onClick={handleCorrectRideClicked} component={EditRideContainer}>
					<NoteIcon src={darkThemeEnabled ? NoteIconDark : NoteIconLight}/>
					<OptionTextEdit>{STRINGS.CORRECT_A_RIDE}</OptionTextEdit>
				</DelayedButton>
			</SelectOptionContainer>
			<BottomBar infoText={truckId}/>
		</MenuScreenWrapper>
	);
};

export default RidesMenuScreen;
