import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import {
	BackArrow,
	BackButton,
	IconWrapper,
	LogoWrapper,
	NavbarIcon,
	NavbarWrapper,
	PathIndicator,
	VerticalDivider
} from '../../styles/Navbar/Navbar';
import GutexLogo from '../../../../icons/gutex_logo.png';
import GutexLogoDark from '../../../../icons/gutex_logo_dark.png';
import { ADMIN_ROUTES, MODAL, PATH_NAMES, PATHS, STORAGE, STRINGS } from '../../../constants';
import { ReactComponent as LogoutIcon } from '../../../../icons/logout.svg';
import { ReactComponent as GoBackArrow } from '../../../../icons/down-arrow.svg';
import { ReactComponent as RefreshIcon } from '../../../../icons/refresh.svg';
import SearchBar from '../SearchScreen/SearchBar';
import Lorry from '../Lorry/Lorry';
import { modalActions } from '../../../state/ducks/modal';
import { generalSelectors } from '../../../state/ducks/general';
import { deliveriesActions, deliveriesSelectors } from '../../../state/ducks/deliveries';
import { putItemIntoStorage } from '../../../utils/storage/storage';

const Navbar = () => {
	const dispatch = useDispatch();
	const { page = '/' } = useParams();
	const { pathname } = useLocation();
	const history = useHistory();
	const [pathData, setPathData] = useState({});
	const darkThemeEnabled = useSelector(generalSelectors.isDarkTheme);
	const invoiceId = useSelector(deliveriesSelectors.getInvoiceId);

	const isAdminRoute = useMemo(() =>
			ADMIN_ROUTES.some((route) => route === pathname),
		[pathname]
	);

	const {
		backButtonName,
		pathName,
		onButtonClick,
		Icon,
		isSearchBar,
		isTruckIcon,
		suppressBackButton,
		suppressDivider,
		addMargin,
		refreshEnabled
	} = pathData;

	const getPathData = useCallback(() => {
		switch (page) {
			case PATH_NAMES.LOGIN:
				setPathData({
					backButtonName: STRINGS.GUTEX_INTRALOGISTIK,
					pathName: STRINGS.LOGIN
				});
				break;
			case PATH_NAMES.ADMIN:
				setPathData({
					backButtonName: STRINGS.GUTEX_INTRALOGISTIK,
					pathName: STRINGS.ADMIN_PANEL
				});
				break;
			case PATH_NAMES.ADMIN_MENU:
				setPathData({
					backButtonName: STRINGS.LOGOUT,
					pathName: STRINGS.MENU,
					onButtonClick: () =>
						dispatch(modalActions.showModal({ modalType: MODAL.LOGOUT })),
					Icon: <IconWrapper><LogoutIcon/></IconWrapper>
				});
				break;
			case PATH_NAMES.WAREHOUSE:
				setPathData({
					backButtonName: STRINGS.GO_BACK,
					pathName: STRINGS.WAREHOUSE_MANAGEMENT,
					onButtonClick: () => history.goBack(),
					Icon: <BackArrow><GoBackArrow/></BackArrow>
				});
				break;
			case PATH_NAMES.EXCEL:
				setPathData({
					backButtonName: STRINGS.GO_BACK,
					pathName: STRINGS.EXCEL_PROVISION,
					onButtonClick: () => history.goBack(),
					Icon: <BackArrow><GoBackArrow/></BackArrow>
				});
				break;
			case PATH_NAMES.MAIN_MENU:
				setPathData({
					backButtonName: STRINGS.LOGOUT,
					pathName: STRINGS.MAIN_MENU,
					onButtonClick: () =>
						dispatch(modalActions.showModal({ modalType: MODAL.LOGOUT })),
					Icon: <IconWrapper><LogoutIcon/></IconWrapper>
				});
				break;
			case PATH_NAMES.RIDES_MENU:
				setPathData({
					backButtonName: STRINGS.MAIN_MENU,
					pathName: STRINGS.WAREHOUSE_RIDE,
					onButtonClick: () => history.push(PATHS.MAIN_MENU),
					Icon: <BackArrow><GoBackArrow/></BackArrow>
				});
				break;
			case PATH_NAMES.DESTINATION:
				setPathData({
					backButtonName: STRINGS.GO_BACK,
					pathName: STRINGS.SELECT_DESTINATION,
					onButtonClick: () => history.goBack(),
					Icon: <BackArrow><GoBackArrow/></BackArrow>
				});
				break;
			case PATH_NAMES.SEARCH:
				setPathData({
					backButtonName: STRINGS.GO_BACK,
					isSearchBar: true,
					isTruckIcon: true,
					onButtonClick: () => history.goBack(),
					Icon: <BackArrow><GoBackArrow/></BackArrow>
				});
				break;
			case PATH_NAMES.SEARCH_CORRECTION:
				setPathData({
					backButtonName: STRINGS.GO_BACK,
					isSearchBar: true,
					isTruckIcon: true,
					onButtonClick: () => history.goBack(),
					Icon: <BackArrow><GoBackArrow/></BackArrow>
				});
				break;
			case PATH_NAMES.PRODUCTS:
				setPathData({
					backButtonName: STRINGS.GO_BACK,
					pathName: STRINGS.ADD_ARTICLE,
					isTruckIcon: true,
					onButtonClick: () => history.goBack(),
					Icon: <BackArrow><GoBackArrow/></BackArrow>
				});
				break;
			case PATH_NAMES.PRODUCTS_CORRECTION:
				setPathData({
					backButtonName: STRINGS.GO_BACK,
					pathName: STRINGS.ADD_ARTICLE,
					isTruckIcon: true,
					onButtonClick: () => history.goBack(),
					Icon: <BackArrow><GoBackArrow/></BackArrow>
				});
				break;
			case PATH_NAMES.SUMMARY:
				setPathData({
					backButtonName: STRINGS.CANCEL,
					pathName: STRINGS.RIDE_SUMMARY,
					onButtonClick: () =>
						dispatch(modalActions.showModal({ modalType: MODAL.CANCEL_RIDE })),
					Icon: <BackArrow><GoBackArrow/></BackArrow>
				});
				break;
			case PATH_NAMES.PREVIOUS_RIDES:
				setPathData({
					backButtonName: STRINGS.MENU,
					pathName: STRINGS.YOUR_PREVIOUS_RIDES,
					onButtonClick: () => history.push(PATHS.RIDES_MENU),
					Icon: <BackArrow><GoBackArrow/></BackArrow>
				});
				break;
			case PATH_NAMES.GOOD_RIDE:
				setPathData({
					suppressBackButton: true,
					suppressDivider: true,
					addMargin: true,
					pathName: `${STRINGS.DELIVERY} <strong>Nr. ${invoiceId}</strong>`
				});
				break;
			case PATH_NAMES.SCAN_INVOICE:
				setPathData({
					backButtonName: STRINGS.GO_BACK,
					pathName: STRINGS.SCAN_INVOICE,
					onButtonClick: () => history.push(PATHS.MAIN_MENU),
					Icon: <BackArrow><GoBackArrow/></BackArrow>
				});
				break;
			case PATH_NAMES.INVOICE:
				setPathData({
					backButtonName: STRINGS.GO_BACK,
					pathName: `${STRINGS.DELIVERY} <strong>Nr. ${invoiceId}</strong>`,
					onButtonClick: () => {
						history.goBack();
						dispatch(deliveriesActions.setInvoice(null));
					},
					Icon: <BackArrow><GoBackArrow/></BackArrow>
				});
				break;
			case PATH_NAMES.INVOICE_SUMMARY:
				setPathData({
					backButtonName: STRINGS.GO_BACK,
					pathName: `${STRINGS.INVOICE_SUMMARY} <strong>Nr. ${invoiceId}</strong>`,
					onButtonClick: () => history.goBack(),
					Icon: <BackArrow><GoBackArrow/></BackArrow>
				});
				break;
			case PATH_NAMES.REPORTS:
				setPathData({
					backButtonName: STRINGS.GO_BACK,
					pathName: STRINGS.REPORTS_LOGISTIC,
					onButtonClick: () => history.goBack(),
					Icon: <BackArrow><GoBackArrow/></BackArrow>
				});
				break;
			case PATH_NAMES.CORRECTION:
				setPathData({
					backButtonName: STRINGS.GO_BACK,
					pathName: `<strong>${STRINGS.WAREHOUSE_CORRECTIONS}</strong>`,
					onButtonClick: () => history.push(PATHS.MAIN_MENU),
					Icon: <BackArrow><GoBackArrow/></BackArrow>
				});
				break;
			default:
				setPathData({
					backButtonName: STRINGS.GUTEX_INTRALOGISTIK,
					pathName: STRINGS.NO_MATCHING_ROUTE
				});
				break;
		}
	}, [dispatch, history, invoiceId, page]);

	useEffect(() => {
		getPathData();
		putItemIntoStorage(STORAGE.PATH, page);
	}, [getPathData, page]);

	return (
		<NavbarWrapper admin={isAdminRoute}>
			{!suppressBackButton && <BackButton onClick={onButtonClick}>
				{Icon}
				{backButtonName}
			</BackButton>}
			{!suppressDivider && <VerticalDivider/>}
			{isSearchBar
				? <SearchBar/>
				: <PathIndicator
					marginLeft={addMargin}
					admin={isAdminRoute}
					dangerouslySetInnerHTML={{
						__html: pathName
					}}/>}
			{isTruckIcon
				? <Lorry/>
				: <LogoWrapper refreshEnabled={refreshEnabled}>
					{refreshEnabled && <RefreshIcon/>}
					<NavbarIcon
						refreshEnabled={refreshEnabled}
						src={darkThemeEnabled ? GutexLogoDark : GutexLogo}
					/>
				</LogoWrapper>}
		</NavbarWrapper>
	);
};

export default Navbar;
