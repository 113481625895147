import { call, put, takeEvery } from 'redux-saga/effects';
import * as TYPES from './types';
import { trucks } from '../../../api';
import { setTrucks } from './actions';

function* fetchTrucks() {
	const { data, status } = yield call(trucks);
	if (status === 200) {
		yield put(setTrucks({ trucks: data }));
	}
}

export const fetchTrucksSaga = takeEvery(
	TYPES.FETCH_TRUCKS,
	fetchTrucks
);
