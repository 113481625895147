import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	AdminInput,
	AdminPanelWrapper, GutexImage, LoginButton,
	LoginContainer,
	LogoContainer,
	TruckImage
} from '../../styles/AdminPanel/AdminPanel';
import TruckIcon from '../../../../icons/truck.png';
import GutexLogo from '../../../../icons/gutex_logo.png';
import { STRINGS } from '../../../constants';
import { authActions, authSelectors } from '../../../state/ducks/authentication';

const AdminLogin = () => {
	const dispatch = useDispatch();
	const isPasswordValid = useSelector(authSelectors.getPasswordValid);
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');

	const handleLoginClicked = useCallback(() => {
		dispatch(authActions.attemptLogin({
			username,
			password,
			isAdmin: true
		}));
	}, [dispatch, password, username]);

	const handleSubmitOnEnter = useCallback((event) => {
		if (event.key === 'Enter') {
			handleLoginClicked();
		}
	}, [handleLoginClicked]);

	useEffect(() => {
		window.addEventListener('keyup', handleSubmitOnEnter);
		return () => window.removeEventListener('keyup', handleSubmitOnEnter);
	}, [handleSubmitOnEnter]);

	return (
		<AdminPanelWrapper>
			<LoginContainer>
				<LogoContainer>
					<TruckImage src={TruckIcon}/>
					<GutexImage src={GutexLogo}/>
				</LogoContainer>
				<AdminInput
					autoFocus
					placeholder={STRINGS.USERNAME}
					onChange={(e) => setUsername(e.target.value)}
				/>
				<AdminInput
					placeholder={STRINGS.PASSWORD}
					type="password"
					onChange={(e) => setPassword(e.target.value)}
					invalid={!isPasswordValid}
				/>
				<LoginButton onClick={handleLoginClicked}>
					{STRINGS.LOG_IN}
				</LoginButton>
			</LoginContainer>
		</AdminPanelWrapper>
	);
};

export default AdminLogin;
