import React from 'react';
import ReactDOM from 'react-dom';
import Root from './scripts/views/Root';
import configureStore from './scripts/state/store';
import * as initialState from './scripts/state/store/initialState';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import './index.css';
import 'sanitize.css';
import 'sanitize.css/typography.css';

const rootElement = document.getElementById('root');
const reduxStore = configureStore(initialState);

ReactDOM.render(<Root store={reduxStore}/>, rootElement);

serviceWorkerRegistration.register();
