import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
	DeleteArticleButton,
	DetailsBold,
	DetailsLabel,
	Divider,
	EditRideButton,
	EditRideLabel,
	ListId,
	ListItemWrapper,
	ProductDetailsGrid,
	ProductTitle,
	ReplaceButton
} from '../../styles/SummaryScreen/SummaryScreen';
import { STRINGS } from '../../../constants';
import { ReactComponent as EditIcon } from '../../../../icons/edit.svg';
import { ReactComponent as DeleteIcon } from '../../../../icons/delete.svg';
import DelayedButton from '../Common/DelayedButton';
import { searchActions } from '../../../state/ducks/search';

const SummaryListItem = ({
	index,
	name,
	nr,
	length,
	width,
	height,
	item,
	ballen,
	handleEditRide,
	handleDeleteArticle,
	handleReplaceArticle
}) => {
	const dispatch = useDispatch();
	const secondUnit = ballen ? STRINGS.BALLEN : STRINGS.PIECES;

	useEffect(() => {
		dispatch(searchActions.setSearchContext({
			context: null,
			selectionId: null
		}));
	}, [dispatch, item.selectionId]);

	return (
		<>
			<ListItemWrapper>
				<ProductDetailsGrid>
					<DelayedButton
						onClick={() => handleReplaceArticle(item.selectionId)}
						component={ReplaceButton}
					/>
					<ListId>{index + 1}</ListId>
					<ProductTitle>{name}</ProductTitle>
					<DetailsLabel>{nr}</DetailsLabel>
					<DetailsLabel>
						{STRINGS.DEST}:
						<DetailsBold>{item.selectedDestination}</DetailsBold>
					</DetailsLabel>
					<DetailsLabel>{`${height}x${length}x${width}`}</DetailsLabel>
					<DetailsLabel red={!item.isPAL}>
						{item.isPAL ? STRINGS.PAL : secondUnit}:
						<DetailsBold>{item.amount}</DetailsBold>
					</DetailsLabel>
					<DelayedButton onClick={() => handleEditRide(item.selectionId)} component={EditRideButton}>
						<EditIcon/>
						<EditRideLabel>{STRINGS.EDIT_ARTICLE}</EditRideLabel>
					</DelayedButton>
					<DelayedButton onClick={() => handleDeleteArticle(item.selectionId)} component={DeleteArticleButton}>
						<DeleteIcon/>
					</DelayedButton>
				</ProductDetailsGrid>
			</ListItemWrapper>
			<Divider/>
		</>
	);
};

export default SummaryListItem;
