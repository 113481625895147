import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { SearchScreenWrapper } from '../../styles/SearchScreen/SearchScreen';
import SearchResults from '../SearchScreen/SearchResults';
import { productsActions, productsSelectors } from '../../../state/ducks/products';
import { searchSelectors } from '../../../state/ducks/search';
import { PATHS } from '../../../constants';

const CorrectionSearchScreen = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const searchValue = useSelector(searchSelectors.getSearchValue) || '';
	const products = useSelector(productsSelectors.getProducts);
	const [items, setItems] = useState([]);

	const filterMatchingProducts = useCallback(() => {
		const matching = products?.filter(({ name, article_nr: nr }) =>
			name.toLowerCase().includes(searchValue.toLowerCase().trim())
			|| `${nr}`.startsWith(searchValue.trim()));
		setItems(matching);
	}, [products, searchValue]);

	const handleProductClicked = (id) => {
		dispatch(productsActions.setSelectedProductId({ selectedProductId: id }));
		history.push(`${PATHS.PRODUCTS_CORRECTION}/${id}`);
	};

	useEffect(() => {
		filterMatchingProducts();
	}, [filterMatchingProducts, products]);

	return (
		<SearchScreenWrapper>
			<SearchResults
				items={items}
				handleProductClicked={handleProductClicked}
			/>
		</SearchScreenWrapper>
	);
};

export default CorrectionSearchScreen;
