import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { includes, isNil } from 'ramda';
import { debounce } from 'lodash';
import {
	BarcodeField,
	BarcodeInput,
	CheckDeliveryButton,
	QuickModeMessage,
	ScanContainer,
	ScannerImage,
	ScannerScreenWrapper
} from '../../styles/ScannerScreen/ScannerScreen';
import BottomBar from '../Common/BottomBar';
import ScannerIcon from '../../../../icons/scanner.png';
import ScannerDarkIcon from '../../../../icons/scanner-dark.png';
import { ReactComponent as BarcodeIcon } from '../../../../icons/barcode_icon.svg';
import { MODAL, PATHS, STRINGS } from '../../../constants';
import { generalSelectors } from '../../../state/ducks/general';
import { deliveriesActions, deliveriesSelectors } from '../../../state/ducks/deliveries';
import { useEnterClicked } from '../../../utils/hooks/useEnterClicked';
import { modalActions, modalSelectors } from '../../../state/ducks/modal';
import { parseInvoiceNumber } from '../../../utils/deliveries/scanner';

const ScannerScreen = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const darkTheme = useSelector(generalSelectors.isDarkTheme);
	const location = useSelector(deliveriesSelectors.getDeliveryLocation);
	const invoice = useSelector(deliveriesSelectors.getInitialInvoice);
	const modalType = useSelector(modalSelectors.getModalType);
	const quickModeEnabled = useSelector(generalSelectors.isQuickMode);
	const bookingPending = useSelector(deliveriesSelectors.isImmediateBookingPending);

	const [scannedValue, setScannedValue] = useState('');
	const [inputFocused, setInputFocused] = useState(false);
	const [inputClicked, setInputClicked] = useState(false);
	const inputRef = useRef(null);
	const isMobileDevice = navigator.userAgent.includes('Android');
	const hideBottomBar = inputFocused && isMobileDevice;

	const handleScan = debounce(() => {
		const { value } = inputRef.current;
		const parsedValue = parseInvoiceNumber(value);

		if (Math.abs(parsedValue.length - scannedValue.length) > 1 && parsedValue.length) {
			dispatch(deliveriesActions.fetchInvoice({
				invoiceNr: parsedValue,
				immediateBooking: quickModeEnabled
			}));
			inputRef.current.blur();
		}

		setScannedValue(parsedValue);
	}, 10);

	const handleInputFocused = () => {
		setInputFocused(true);
		inputRef?.current?.select();
	};

	const focusInput = () => {
		inputRef.current.focus();
		setInputFocused(true);
		setInputClicked(true);
	};

	const handleCheckDelivery = () => {
		inputRef?.current?.blur();
		dispatch(modalActions.showModal({ modalType: MODAL.CHECK_DELIVERY }));
	};

	useEnterClicked(() => {
		if (!modalType) {
			dispatch(deliveriesActions.fetchInvoice({
				invoiceNr: scannedValue,
				immediateBooking: quickModeEnabled
			}));
			inputRef.current.blur();
		}
	});

	useEffect(() => {
		if (invoice && !bookingPending) {
			history.push(PATHS.INVOICE);
		} else if (invoice && bookingPending) {
			setScannedValue('');
			inputRef.current.value = '';
			dispatch(deliveriesActions.bookInvoiceImmediately());
		}
	}, [bookingPending, dispatch, history, invoice, quickModeEnabled]);

	useEffect(() => {
		if (isNil(modalType)) {
			inputRef.current.focus();
		}
		if (includes(modalType, [
			MODAL.INVOICE_NOT_EXISTS,
			MODAL.INVOICE_ALREADY_USED
		])) {
			setScannedValue('');
			inputRef.current.value = '';
		}
	}, [modalType]);

	return (
		<ScannerScreenWrapper red={quickModeEnabled}>
			<CheckDeliveryButton onClick={handleCheckDelivery}>
				{STRINGS.CHECK_DELIVERY}
			</CheckDeliveryButton>
			<ScanContainer isMobile={isMobileDevice}>
				{quickModeEnabled && !(isMobileDevice && inputFocused) && <QuickModeMessage
					dangerouslySetInnerHTML={{
						__html: STRINGS.QUICK_MODE_MSG
					}}
				/>}
				<ScannerImage src={darkTheme ? ScannerDarkIcon : ScannerIcon}/>
				<BarcodeField isScanned={scannedValue} onClick={focusInput}>
					{!scannedValue && !inputClicked && <BarcodeIcon/>}
					<BarcodeInput
						autoFocus
						ref={inputRef}
						placeholder={inputClicked ? '' : STRINGS.SCAN}
						isScanned={scannedValue}
						onChange={handleScan}
						onFocus={handleInputFocused}
						onBlur={() => {
							setInputFocused(false);
							setInputClicked(false);
						}}
					/>
				</BarcodeField>
			</ScanContainer>
			{!hideBottomBar && <BottomBar infoText={location}/>}
		</ScannerScreenWrapper>
	);
};

export default ScannerScreen;
