import React from 'react';
import { DatePickerContainer, DatePickerInput, FilterLabel } from '../../../styles/Modal/ModalReports';

const ControlledDatePicker = ({
	label,
	value,
	updater
}) => {
	return (
		<DatePickerContainer>
			<FilterLabel>{label}:</FilterLabel>
			<DatePickerInput
				type={'date'}
				value={value}
				onChange={(e) => updater(e.target.value)}
			/>
		</DatePickerContainer>
	);
};

export default ControlledDatePicker;
