import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	AdminInput,
	AdminPanelWrapper,
	CurrentWarehouses,
	Divider,
	ListRadio,
	ProvideButton,
	RadioWrapper,
	RemoveButton,
	WarehouseControlContainer,
	WarehouseList,
	WarehouseListItem,
	WarehouseOption,
	WarehouseProvision
} from '../../styles/AdminPanel/AdminPanel';
import { STRINGS } from '../../../constants';
import { destinationActions, destinationSelectors } from '../../../state/ducks/destination';

const WarehouseScreen = () => {
	const dispatch = useDispatch();
	const destinations = useSelector(destinationSelectors.getDestinations);
	const [selected, setSelected] = useState(null);
	const [warehouse, setWarehouse] = useState('');
	const [warehouseNr, setWarehouseNr] = useState('');
	const [nameInvalid, setNameInvalid] = useState(false);
	const [nrInvalid, setNrInvalid] = useState(false);
	const warehouseRef = useRef(null);
	const warehouseNrRef = useRef(null);
	const [displayedList, setDisplayedList] = useState([]);

	const handleAddLocationClicked = () => {
		if (warehouse && Number(warehouseNr)) {
			dispatch(destinationActions.addLocation({
				name: warehouse,
				nr: warehouseNr
			}));

			setNameInvalid(false);
			setNrInvalid(false);
			setWarehouse('');
			setWarehouseNr(null);
			warehouseRef.current.value = '';
			warehouseNrRef.current.value = '';
		} else {
			setNameInvalid(!warehouse);
			setNrInvalid(!Number(warehouseNr));
		}
	};

	const handleDeleteLocationClicked = () => {
		if (selected) {
			dispatch(destinationActions.deleteLocation({ id: selected }));
			setSelected(null);
		}
	};

	useEffect(() => {
		const defaultStart = destinations?.find(({ is_default_start: start }) => start);
		const other = destinations?.filter(({ is_default_start: start }) => !start);

		setDisplayedList([defaultStart, ...other]);
	}, [destinations]);

	return (
		<AdminPanelWrapper>
			<WarehouseControlContainer>
				<CurrentWarehouses>
					<WarehouseOption>{STRINGS.CURRENT_WAREHOUSES}</WarehouseOption>
					<Divider/>
					<WarehouseList>
						{displayedList?.map((item) => {
							return (
								<WarehouseListItem
									key={item.id}
									isDefault={item.is_default_start}
									onClick={!item.is_default_start ? () => setSelected(item.id) : null}
								>
									<RadioWrapper>
										<ListRadio
											selected={item.id === selected}
											isDefault={item.is_default_start}
										/>
									</RadioWrapper>
									{`${item.name} - ${item.location_nr}`}
								</WarehouseListItem>
							);
						})}
					</WarehouseList>
					{selected && <RemoveButton onClick={handleDeleteLocationClicked}>
						{STRINGS.REMOVE}
					</RemoveButton>}
				</CurrentWarehouses>
				<WarehouseProvision>
					<WarehouseOption>{STRINGS.WAREHOUSE_MANAGEMENT}</WarehouseOption>
					<Divider/>
					<AdminInput
						placeholder={STRINGS.WAREHOUSE_NAME}
						onChange={(e) => setWarehouse(e.target.value)}
						invalid={nameInvalid}
						ref={warehouseRef}
					/>
					<AdminInput
						placeholder={STRINGS.WAREHOUSE_NUMBER}
						onChange={(e) => setWarehouseNr(e.target.value)}
						invalid={nrInvalid}
						ref={warehouseNrRef}
					/>
					<ProvideButton onClick={handleAddLocationClicked}>
						{STRINGS.PROVIDE}
					</ProvideButton>
				</WarehouseProvision>
			</WarehouseControlContainer>
		</AdminPanelWrapper>
	);
};

export default WarehouseScreen;
