import { setupPutDatabase, setupReadyDatabase } from './setupDatabase';
import { API } from '../../constants';
import RideReadyHandler from './RideReadyHandler';

class RidePutHandler {
	constructor() {
		this.database = setupPutDatabase();
		this.readyDb = setupReadyDatabase();
	}

	getRidesToPut() {
		return this.database.rides.toArray();
	}

	static getRidesIds(rides) {
		return rides.map(({ dbId }) => dbId);
	}

	async deleteRides(rides) {
		const ids = RidePutHandler.getRidesIds(rides);
		await this.database.rides.bulkDelete(ids);
	}

	async deleteById(id) {
		await this.database.rides.bulkDelete([id]);
	}

	static mapRide(ride) {
		return {
			ride_items: ride.ride_items,
			start_location: ride.start_location,
			start_time: ride.start_time,
			truck: ride.truck
		};
	}

	static prepareRequests(rides) {
		return rides.map((ride) => {
			const payload = this.mapRide(ride);

			return fetch(`${API.DOMAIN}${API.RIDES}${ride.id}/`, {
				method: 'PUT',
				headers: {
					'Authorization': `Token ${ride.token}`,
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(payload)
			});
		});
	}

	async putRidesAndClearDb() {
		const rides = await this.getRidesToPut();
		const requests = RidePutHandler.prepareRequests(rides);
		await Promise.all(requests).then((values) => {
			values.forEach((val) => {
				val.json().then(async (data) => {
					const readyHandler = new RideReadyHandler();
					await readyHandler.addRide(data);
				});
			});
		});
		await this.deleteRides(rides);
	}
}

export default RidePutHandler;
