import * as TYPES from './types';

export const checkDarkTheme = () => ({
	type: TYPES.CHECK_DARK_THEME
});

export const toggleDarkTheme = () => ({
	type: TYPES.TOGGLE_DARK_THEME
});

export const toggleQuickMode = () => ({
	type: TYPES.TOGGLE_QUICK_MODE
});

export const setQuickMode = (payload) => ({
	type: TYPES.SET_QUICK_MODE,
	payload
});

export const setDarkTheme = () => ({
	type: TYPES.SET_DARK_THEME
});

export const setRideContext = (payload) => ({
	type: TYPES.SET_RIDE_CONTEXT,
	payload
});

export const setDbType = (payload) => ({
	type: TYPES.SET_DB_TYPE,
	payload
});

export const setDbId = (payload) => ({
	type: TYPES.SET_DB_ID,
	payload
});

export const setRideId = (payload) => ({
	type: TYPES.SET_RIDE_ID,
	payload
});

export const setInitialSearch = () => ({
	type: TYPES.SET_INITIAL_SEARCH
});
