import { createSelector } from 'reselect';
import { getCart } from '../products/selectors';

export const getDestinations = createSelector(
	[({ destination }) => destination.destinations],
	(destinations) => destinations
);

export const getDestinationsList = createSelector(
	[getDestinations],
	(destinations) => destinations?.map(({ name }) => name) || []
);

export const getDefaultDestination = createSelector(
	[({ destination }) => destination.defaultDestination],
	(defaultDestination) => defaultDestination
);

export const getStartLocation = createSelector(
	[({ destination }) => destination.startLocation],
	(startLocation) => startLocation
);

export const getDefaultStartLocation = createSelector(
	[getDestinations],
	(destinations) => destinations
		?.find(({ is_default_start: start }) => start).name
);

export const getAvailableStartLocations = createSelector(
	[getDestinations, getCart],
	(destinations, cart) => {
		const engagedLocations = cart?.map(({ selectedDestination }) => selectedDestination) || [];

		return destinations
			?.filter(({ name }) => !engagedLocations.includes(name))
			.map(({ name }) => name);
	}
);

export const getAvailableEndLocations = createSelector(
	[getDestinations, getStartLocation],
	(destinations, start) => destinations
		?.filter(({ name }) => name !== start)
		.map(({ name }) => name)
);
