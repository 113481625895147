import React from 'react';
import {
	LastRidesContainer,
	LastRidesItem,
	ProductDimensions,
	ProductId,
	ProductTitle,
	SearchArticleText
} from '../../styles/SearchScreen/SearchScreen';
import { STRINGS } from '../../../constants';

const LastRides = ({
	lastRides,
	handleProductClicked
}) => {
	return (
		<>
			<SearchArticleText>{STRINGS.LAST_RIDES}</SearchArticleText>
			<LastRidesContainer>
				{lastRides?.map(({
					product: {
						id, name, height, length, width, article_nr: nr
					}
				}) => {
					const sizeText = `<strong>${height}</strong>x${length}x${width}`;

					return (
						<LastRidesItem
							key={id}
							onClick={() => handleProductClicked(id)}
						>
							<ProductDimensions dangerouslySetInnerHTML={{
								__html: sizeText
							}}/>
							<ProductId>{`/ ${nr}`}</ProductId>
							<ProductTitle>{name}</ProductTitle>
						</LastRidesItem>
					);
				})}
			</LastRidesContainer>
		</>
	);
};

export default LastRides;
