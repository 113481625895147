export const FETCH_PRODUCTS = 'FETCH_PRODUCTS';
export const FETCH_ALL_PRODUCTS = 'FETCH_ALL_PRODUCTS';
export const FETCH_LAST_PRODUCTS = 'FETCH_LAST_PRODUCTS';
export const SET_PRODUCTS = 'SET_PRODUCTS';
export const SET_LAST_PRODUCTS = 'SET_LAST_PRODUCTS';
export const SET_SELECTED_PRODUCT_ID = 'SET_SELECTED_PRODUCT_ID';
export const SET_ACTIVE_PRODUCT = 'SET_ACTIVE_PRODUCT';
export const ADD_PRODUCT_TO_CART = 'ADD_PRODUCT_TO_CART';
export const SET_CART = 'SET_CART';
export const EDIT_PRODUCT_IN_CART = 'EDIT_PRODUCT_IN_CART';
export const CLEAR_ACTIVE_SELECTION = 'CLEAR_ACTIVE_SELECTION';
export const DELETE_PRODUCT_FROM_CART = 'DELETE_PRODUCT_FROM_CART';
export const CANCEL_RIDE = 'CANCEL_RIDE';
export const UPLOAD_EXCEL = 'UPLOAD_EXCEL';
export const DOWNLOAD_EXCEL = 'DOWNLOAD_EXCEL';
export const SET_EXCEL_CHANGES = 'SET_EXCEL_CHANGES';
export const TOGGLE_EXCEL_UPDATE_PENDING = 'TOGGLE_EXCEL_UPDATE_PENDING';
export const REPLACE_PRODUCT = 'REPLACE_PRODUCT';
export const ACCEPT_DATABASE = 'ACCEPT_DATABASE';
