import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import moment from 'moment';
import * as TYPES from './types';
import { rides } from '../../../api';
import { authSelectors } from '../authentication';
import { deleteRideFromList, setRides } from './actions';
import { setupDeleteDatabase, setupPostDatabase, setupPutDatabase } from '../../../utils/database/setupDatabase';
import { DATABASE, SERVICE_WORKER } from '../../../constants';
import RideReadyHandler from '../../../utils/database/RideReadyHandler';
import RidePostHandler from '../../../utils/database/RidePostHandler';
import RidePutHandler from '../../../utils/database/RidePutHandler';
import { trucksSelectors } from '../trucks';

export function* checkIsLoggedIn() {
	yield;
	return true;
}

function* fetchRides() {
	const token = yield select(authSelectors.getTruckToken);
	const truckId = yield select(trucksSelectors.getAuthenticatedTruckId);
	const { data, status } = yield call(rides, token);
	const loggedIn = yield* checkIsLoggedIn();

	if (!loggedIn) return;

	if (status === 200) {
		yield put(setRides({ previousRides: data }));
	} else {
		const readyDatabase = yield new RideReadyHandler();
		const postDatabase = yield new RidePostHandler();
		const putDatabase = yield new RidePutHandler();
		const ridesReady = yield readyDatabase.getAllRides();
		const ridesPost = yield postDatabase.getRidesToPost();
		const ridesPut = yield putDatabase.getRidesToPut();
		const offlineRides = [
			...ridesReady.map((item) => ({ ...item, dbType: DATABASE.TYPE_READY })),
			...ridesPost.map((item) => ({ ...item, dbType: DATABASE.TYPE_POST })),
			...ridesPut.map((item) => ({ ...item, dbType: DATABASE.TYPE_PUT }))
		].filter(({ truck }) => truck === truckId)
			.sort((a, b) => moment(a.start_time).unix() - moment(b.start_time).unix());

		yield put(setRides({ previousRides: offlineRides }));
	}
}

function* handleDeleteRide(action) {
	const { payload: { id, dbType, dbId } } = action;
	const token = yield select(authSelectors.getTruckToken);
	const readyDatabase = yield new RideReadyHandler();
	const postDatabase = yield new RidePostHandler();
	const putDatabase = yield new RidePutHandler();
	const deleteDatabase = yield setupDeleteDatabase();
	const registration = yield navigator.serviceWorker.ready;
	const loggedIn = yield* checkIsLoggedIn();

	if (!loggedIn) return;

	if (id) {
		yield deleteDatabase.rides.add({ id, token });
	}

	switch (dbType) {
		case DATABASE.TYPE_READY:
			yield readyDatabase.deleteById(dbId);
			break;
		case DATABASE.TYPE_POST:
			yield postDatabase.deleteById(dbId);
			break;
		case DATABASE.TYPE_PUT:
			yield putDatabase.deleteById(dbId);
			break;
		default:
			yield readyDatabase.deleteByRideId(id);
			break;
	}

	yield put(deleteRideFromList({ id }));
	yield registration.sync.register(SERVICE_WORKER.DELETE_RIDE);
}

function* sendRideToIndexedDb(action) {
	const { payload: { ride, db } } = action;
	const loggedIn = yield* checkIsLoggedIn();

	if (!loggedIn) return;

	const registration = yield navigator.serviceWorker.ready;
	const readyDb = new RideReadyHandler();

	yield readyDb.deleteByRideId(ride.id);

	if (db === DATABASE.DB_PUT) {
		const database = yield setupPutDatabase();
		yield database.rides.add(ride);
		yield registration.sync.register(SERVICE_WORKER.PUT_RIDE);
	} else if (db === DATABASE.DB_POST) {
		const database = yield setupPostDatabase();
		yield database.rides.add(ride);
		yield registration.sync.register(SERVICE_WORKER.POST_RIDE);
	}

	yield* fetchRides();
}

function* removeFromDb(action) {
	const { payload: { dbType, dbId } } = action;
	const loggedIn = yield* checkIsLoggedIn();

	if (!loggedIn) return;

	switch (dbType) {
		case DATABASE.TYPE_POST: {
			const database = yield new RidePostHandler();
			yield database.deleteById(dbId);
			break;
		}
		case DATABASE.TYPE_PUT: {
			const database = yield new RidePutHandler();
			yield database.deleteById(dbId);
			break;
		}
		case DATABASE.TYPE_READY: {
			const database = yield new RideReadyHandler();
			yield database.deleteById(dbId);
			break;
		}
		default:
			break;
	}
}

function* resendRides(action) {
	const { payload: ridesData } = action;

	yield all(ridesData.map((ride) => call(sendRideToIndexedDb, {
		payload: {
			ride,
			db: DATABASE.DB_POST
		}
	})));
}

export const fetchRidesSaga = takeEvery(
	TYPES.FETCH_RIDES,
	fetchRides
);

export const deleteRideSaga = takeEvery(
	TYPES.DELETE_RIDE,
	handleDeleteRide
);

export const sendRideToIndexedDbSaga = takeEvery(
	TYPES.SEND_RIDE_TO_INDEXED_DB,
	sendRideToIndexedDb
);

export const removeFromDbSaga = takeEvery(
	TYPES.REMOVE_FROM_DB,
	removeFromDb
);

export const resendRidesSaga = takeEvery(
	TYPES.RESEND_RIDES,
	resendRides
);
