import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	AddedSpace,
	AdminPanelWrapper,
	ApplyButton,
	ApplyChangesButton,
	ChangesError,
	ChangesList,
	DecorationWrapper,
	DiffBox,
	DiffContainer,
	DiffDivider,
	DiffTitle,
	DiffTitleContainer,
	DownloadExcel,
	DragDropZone,
	ErrorMessage,
	ExcelProvisionContainer,
	ExcelWrapper,
	FileNameWrapper,
	ListItem,
	ListItemDecoration,
	RemoveFileButton,
	XSpanLeft,
	XSpanRight
} from '../../styles/AdminPanel/AdminPanel';
import { STRINGS } from '../../../constants';
import { productsActions, productsSelectors } from '../../../state/ducks/products';
import { ReactComponent as DownloadIcon } from '../../../../icons/download.svg';

const ExcelScreen = () => {
	const dispatch = useDispatch();
	const error = useSelector(productsSelectors.getExcelError);
	const summary = useSelector(productsSelectors.getExcelSummary);
	const updatePending = useSelector(productsSelectors.isExcelUpdatePending);

	const [isDragging, setIsDragging] = useState(false);
	const [filename, setFilename] = useState('');
	const [fileData, setFileData] = useState(null);
	const dragRef = useRef();

	const prevent = (e) => {
		e.preventDefault();
		e.stopPropagation();
	};

	const handleDragIn = useCallback((e) => {
		prevent(e);
		if (e.dataTransfer.items?.length) {
			setIsDragging(true);
		}
	}, []);

	const handleDragOut = useCallback((e) => {
		prevent(e);
		setIsDragging(false);
	}, []);

	const handleDrag = useCallback((e) => {
		prevent(e);
	}, []);

	const handleDrop = useCallback((e) => {
		prevent(e);
		setIsDragging(false);
		if (e.dataTransfer.files?.length) {
			setFilename(e.dataTransfer.files[0].name);
			setFileData(e.dataTransfer.files[0]);
		}
	}, []);

	const handleApplyClicked = () => {
		const formData = new FormData();
		formData.append('file', fileData);
		dispatch(productsActions.uploadExcel({ file: formData }));
	};

	const removeFile = () => {
		setFileData(null);
		setFilename('');
	};

	const handleAcceptDatabase = () => {
		dispatch(productsActions.acceptDatabase());
	};

	const handleDownloadExcel = () => {
		dispatch(productsActions.downloadExcel());
	};

	useEffect(() => {
		removeFile();
	}, [error, summary]);

	useEffect(() => {
		const div = dragRef.current;

		div.addEventListener('dragenter', handleDragIn);
		div.addEventListener('dragleave', handleDragOut);
		div.addEventListener('dragover', handleDrag);
		div.addEventListener('drop', handleDrop);

		return () => {
			div.removeEventListener('dragenter', handleDragIn);
			div.removeEventListener('dragleave', handleDragOut);
			div.removeEventListener('dragover', handleDrag);
			div.removeEventListener('drop', handleDrop);
		};
	}, [handleDrag, handleDragIn, handleDragOut, handleDrop]);

	return (
		<AdminPanelWrapper>
			<ExcelWrapper>
				<DownloadExcel onClick={handleDownloadExcel}>
					<DownloadIcon/>
					{STRINGS.DOWNLOAD_CURRENT_EXCEL}
				</DownloadExcel>
				<ExcelProvisionContainer>
					<DragDropZone
						isDrag={isDragging}
						ref={dragRef}
					>
						<FileNameWrapper>
							{filename && <RemoveFileButton onClick={removeFile}>
								<XSpanLeft/>
								<XSpanRight/>
							</RemoveFileButton>}
							{filename || STRINGS.DRAG_N_DROP_HERE}
						</FileNameWrapper>
						{filename &&
						<ApplyButton onClick={handleApplyClicked}>
							{STRINGS.APPLY_EXCEL}
						</ApplyButton>}
					</DragDropZone>
					<DiffBox>
						<DiffTitleContainer>
							<DiffContainer>
								<DiffTitle>{STRINGS.YOUR_CHANGES}</DiffTitle>
								{summary && <ApplyChangesButton onClick={handleAcceptDatabase}>
									{STRINGS.CHANGE_DATABASE}
								</ApplyChangesButton>}
							</DiffContainer>
							<DiffDivider/>
							{updatePending && <DiffTitle>{STRINGS.LOADING}</DiffTitle>}
							{error && !updatePending && <ChangesError>{STRINGS.ERROR}:</ChangesError>}
							{error && !updatePending && <ErrorMessage>{error}</ErrorMessage>}
							{summary && !updatePending && <ChangesList>
								{summary.map((item, index) => {
									return (
										<ListItem key={index}>
											<DecorationWrapper><ListItemDecoration/></DecorationWrapper>
											{item}
										</ListItem>
									);
								})}
							</ChangesList>}
						</DiffTitleContainer>
					</DiffBox>
					<AddedSpace/>
				</ExcelProvisionContainer>
			</ExcelWrapper>
		</AdminPanelWrapper>
	);
};

export default ExcelScreen;
