import React from 'react';
import {
	AmountButtonsContainer,
	AmountInput,
	ApproveButton,
	ApproveButtonContainer,
	ChangeAmountButton,
	ContainerType,
	ContainerTypeWrapper,
	DropdownContainer,
	PalletButton,
	PiecesButton,
	ProductDetails,
	ProductDetailsContainer,
	ProductScreenWrapper,
	ProductTitle,
	SelectionsContainer
} from '../../styles/ProductScreen/ProductScreen';
import DelayedButton from '../Common/DelayedButton';
import { ReactComponent as MinusIcon } from '../../../../icons/minus.svg';
import { ReactComponent as PlusIcon } from '../../../../icons/add.svg';
import { STRINGS } from '../../../constants';
import Dropdown from '../Dropdown/Dropdown';

const ProductLayout = ({
	name,
	nr,
	height,
	length,
	width,
	amount,
	secondUnit,
	destinations,
	isPAL,
	isLimit,
	isDesktop = false,
	isMobileDevice,
	isCorrectionContext,
	suppressTouch = false,
	hideDropdown,
	valid,
	togglePal,
	decreaseAmount,
	increaseAmount,
	selectedDestination,
	setSelectedDestination,
	handleFocus,
	handleInputChange,
	handleProductApproved
}) => {
	return (
		<ProductScreenWrapper>
			<ProductDetailsContainer isDesktop={isDesktop}>
				<ProductTitle>{name}</ProductTitle>
				<ProductDetails>
					<ProductDetails>{nr}</ProductDetails>
					<ProductDetails>{`${height}x${length}x${width}`}</ProductDetails>
				</ProductDetails>
				<AmountButtonsContainer>
					<DelayedButton
						onClick={decreaseAmount}
						limit={amount === 1}
						component={ChangeAmountButton}
					>
						<MinusIcon/>
					</DelayedButton>
					<AmountInput
						value={amount || ''}
						type="number"
						onChange={handleInputChange}
						onFocus={handleFocus}
						pal={isPAL}
					/>
					<DelayedButton
						onClick={increaseAmount}
						limit={isLimit}
						component={ChangeAmountButton}
					>
						<PlusIcon/>
					</DelayedButton>
					<ContainerType pal={isPAL}>{isPAL ? STRINGS.PALLETS : secondUnit}</ContainerType>
				</AmountButtonsContainer>
				<SelectionsContainer>
					<ContainerTypeWrapper onClick={togglePal}>
						<PalletButton pal={isPAL}>{STRINGS.PAL}</PalletButton>
						<PiecesButton pal={isPAL}>{secondUnit}</PiecesButton>
					</ContainerTypeWrapper>
					{!hideDropdown && <DropdownContainer>
						<ProductDetails>{STRINGS.DEST}:</ProductDetails>
						<Dropdown
							initialValue={selectedDestination}
							items={destinations}
							updateValue={setSelectedDestination}
							red
							top={'-150%'}
							origin={'right'}
						/>
					</DropdownContainer>}
				</SelectionsContainer>
			</ProductDetailsContainer>
			<ApproveButtonContainer isDesktop={isDesktop}>
				<ApproveButton
					onClick={(!isMobileDevice && valid) || suppressTouch ? handleProductApproved : null}
					onTouchEnd={isMobileDevice && valid && !suppressTouch ? handleProductApproved : null}
					valid={valid}
				>
					{isCorrectionContext ? STRINGS.APPROVE_SELECTION : STRINGS.APPROVE}
				</ApproveButton>
			</ApproveButtonContainer>
		</ProductScreenWrapper>
	);
};

export default ProductLayout;
