import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
	Divider,
	GoodRideContainer,
	GoodRideImage,
	GoodRideScreenWrapper,
	GoodRideTitle,
	RideInfo,
	TextWrapper
} from '../../styles/GoodRideScreen/GoodRideScreen';
import TruckIcon from '../../../../icons/truck-loaded.png';
import TruckDarkIcon from '../../../../icons/truck-loaded-dark.png';
import WarehouseIcon from '../../../../icons/warehouse.png';
import WarehouseDarkIcon from '../../../../icons/warehouse-dark.png';
import { PATHS, STRINGS } from '../../../constants';
import { generalSelectors } from '../../../state/ducks/general';
import { deliveriesActions, deliveriesSelectors } from '../../../state/ducks/deliveries';
import { useEnterClicked } from '../../../utils/hooks/useEnterClicked';

const GoodRideScreen = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const darkTheme = useSelector(generalSelectors.isDarkTheme);
	const destinations = useSelector(deliveriesSelectors.getSelectedDestinations);
	const isSecondWarehouse = useSelector(deliveriesSelectors.isSecondWarehousePresent);

	const formatDestinations = () => {
		const withComma = `(${destinations.join(', ')})`;
		const lastIndex = withComma.lastIndexOf(',');
		const start = withComma.slice(0, lastIndex);
		const end = withComma.slice(lastIndex).replace(',', ' &');

		return `${start}${end}`;
	};

	const rideTextSuccess =
		`${STRINGS.RIDE_BOOKED_SUCCESSFULLY}</br><small>${formatDestinations()}</small>`;

	const handleSkipScreen = () => {
		dispatch(deliveriesActions.clearInvoice());
		history.push(PATHS.SCAN_INVOICE);
	};

	useEnterClicked(() => {
		dispatch(deliveriesActions.clearInvoice());
		history.push(PATHS.SCAN_INVOICE);
	});

	useEffect(() => {
		const timeout = setTimeout(() => {
			dispatch(deliveriesActions.clearInvoice());
			history.push(PATHS.SCAN_INVOICE);
		}, 1000);

		return () => {
			clearTimeout(timeout);
		};
	}, [dispatch, history]);

	return (
		<GoodRideScreenWrapper onClick={handleSkipScreen}>
			<GoodRideContainer>
				{!darkTheme && <GoodRideImage
					src={isSecondWarehouse ? WarehouseIcon : TruckIcon}
				/>}
				{darkTheme && <GoodRideImage
					src={isSecondWarehouse ? WarehouseDarkIcon : TruckDarkIcon}
				/>}
				<TextWrapper>
					<GoodRideTitle>{STRINGS.GOOD_RIDE}</GoodRideTitle>
					<Divider/>
					<RideInfo dangerouslySetInnerHTML={{
						__html: isSecondWarehouse ? STRINGS.SECOND_WAREHOUSE_WAITING : rideTextSuccess
					}}/>
				</TextWrapper>
			</GoodRideContainer>
		</GoodRideScreenWrapper>
	);
};

export default GoodRideScreen;
