import * as TYPES from './types';

const trucks = (state = {}, { type, payload }) => {
	switch (type) {
		case TYPES.SET_TRUCKS:
			return {
				...state,
				...payload
			};
		default:
			return state;
	}
};

export default trucks;
