import reducer from './reducers';

import * as destinationActions from './actions';
import * as destinationTypes from './types';
import * as destinationSelectors from './selectors';
import * as destinationOperations from './operations';

export {
	destinationActions,
	destinationTypes,
	destinationSelectors,
	destinationOperations,
};

export default reducer;
