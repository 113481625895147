import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { RidesContainer, RidesDataContainer } from '../../styles/ReportsScreen/ReportsScreen';
import RideItem from './RideItem';

const RidesData = ({
	truckName,
	rides,
	timeSlot
}) => {
	return (
		<RidesDataContainer>
			{truckName}
			<RidesContainer>
				{rides.map((item) => {
					return <RideItem
						key={uuidv4()}
						rideItems={item.ride_items}
						rideTime={item.ride_time}
						timeSlot={timeSlot}
					/>;
				})}
			</RidesContainer>
		</RidesDataContainer>
	);
};

export default RidesData;
