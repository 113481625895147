import * as TYPES from './types';

export const fetchDestinations = () => ({
	type: TYPES.FETCH_DESTINATIONS
});

export const fetchAdminDestinations = () => ({
	type: TYPES.FETCH_ADMIN_DESTINATIONS
});

export const setDestinations = (payload) => ({
	type: TYPES.SET_DESTINATIONS,
	payload
});

export const setStartLocation = (payload) => ({
	type: TYPES.SET_START_LOCATION,
	payload
});

export const setDefaultDestination = (payload) => ({
	type: TYPES.SET_DEFAULT_DESTINATION,
	payload
});

export const addLocation = (payload) => ({
	type: TYPES.ADD_LOCATION,
	payload
});

export const deleteLocation = (payload) => ({
	type: TYPES.DELETE_LOCATION,
	payload
});

export const addLocationToList = (payload) => ({
	type: TYPES.ADD_LOCATION_TO_LIST,
	payload
});

export const removeLocationFromList = (payload) => ({
	type: TYPES.REMOVE_LOCATION_FROM_LIST,
	payload
});
