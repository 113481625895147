import styled from 'styled-components';

export const SearchScreenWrapper = styled.div`
	display: flex;
	width: 100%;
	min-height: 100vh;
	flex-direction: column;
	align-items: center;
	background-color: ${({ theme }) => theme.colors.primary};
`;

export const SearchBarWrapper = styled.div`
	display: flex;
	align-items: center;
	height: 100%;
	width: 50%;
	margin-right: auto;
`;

export const SearchInput = styled.input`
	font-family: Roboto, sans-serif;
	font-weight: 400;
	font-size: 3.2rem;
	width: 100%;
	background-color: ${({ theme }) => theme.colors.primary};
	border: none;
	
	::placeholder {
		color: ${({ theme }) => theme.colors.fontGrey};
		opacity: 1;
	}
	
	&:focus {
		outline: 0;
	}
`;

export const IconWrapper = styled.div`
	display: flex;
	align-items: center;
	
	svg {
		width: 2.5rem;
		height: 2.5rem;
		fill: black;
		margin-right: 2rem;
	}
`;

export const LogoIcon = styled.img`
	margin-top: 16rem;
	display: block;
	width: 25rem;
	opacity: .3;
`;

export const SearchArticleText = styled.div`
	margin-top: 10rem;
	color: ${({ theme }) => theme.colors.greyDark};
	font-family: Roboto, sans-serif;
	font-weight: 700;
	font-size: 3.7rem;
`;

export const ProductsList = styled.div`
	display: grid;
	align-items: center;
	width: 100%;
	margin-top: 8.6rem;
	font-family: Roboto, sans-serif;
	font-weight: 400;
	font-size: 3rem;
`;

export const ProductItemListCell = styled.div`
	padding: 0.6rem 0;
	color: ${({ theme, isGrey }) => isGrey ? theme.colors.fontGrey : 'black'};
	grid-column: ${({ col }) => `${col} / ${col + 1}`};
	grid-row: ${({ row }) => `${row} / ${row + 1}`};
	
	strong {
		color: ${({ theme }) => theme.colors.fontRed};
		font-weight: inherit;
	}
`;

export const ProductMatching = styled.strong`
	font-weight: 700;
	color: black;
	white-space: nowrap;
`;

export const TitleContainer = styled.p`
	width: 100%;
	transition: all 0.2s;
	font-weight: 700;
	grid-column: 3 / 4;
	grid-row: 2 / 3;
	margin: 0;
	padding: 0.6rem 0;
	color: black;
`;

export const TitleContainerWrapper = styled.div`
	height: 100%;
`;

export const ListDivider = styled.span`
	width: 95%;
	transform: translateX(2.5%);
	border-top: 1px solid black;
	
	:last-child {
		display: none;	
	}
`;

export const RowContentWrapper = styled.div`
	display: grid;
	grid-template-columns: 5rem 25rem 1fr 5rem;
	grid-template-rows: repeat(2, max-content);
	width: 100%;
	padding: 1rem 0;
`;

export const LastRidesContainer = styled.div`
	display: grid;
	grid-column-gap: 3rem;
	grid-row-gap: 3rem;
	justify-items: center;
	grid-template-columns: repeat(12, 1fr);
	grid-template-rows: repeat(2, 1fr);
	width: 100%;
	padding: 0 3rem;
	margin-top: 1rem;
`;

export const LastRidesItem = styled.div`
	grid-column: span 4;
	height: 17rem;
	width: 100%;
	background-color: ${({ theme }) => theme.colors.primaryWhite};
	border: 2px solid black;
	border-radius: 3px;
	filter: drop-shadow(1px 2px 2px rgba(0, 0, 0, 0.25));
	
	:nth-last-child(2) {
		grid-row-start: 2;
		grid-column: 3 / span 4;
	}
	
	:nth-last-child(1) {
		grid-row-start: 2;
		grid-column: 7 / span 4;
	}
`;

export const ProductTitle = styled.div`
	display: -webkit-box;
  overflow: hidden;
	font-family: Roboto, sans-serif;
	font-size: 3.2rem;
	font-weight: 500;
	line-height: 1.3;
	margin: 0.7rem 2rem 0;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const ProductDetails = styled.span`
	display: inline-block;
	font-size: 3.2rem;
	font-weight: 400;
	margin: 1rem 1rem 0 2rem;
	color: black;
`;

export const ProductDimensions = styled(ProductDetails)`
	color: ${({ theme }) => theme.colors.greyDark};
	
	strong {
		font-weight: inherit;
		color: ${({ theme }) => theme.colors.fontRed};
	}
`;

export const ProductId = styled(ProductDetails)`
	margin-left: 0;
`;
