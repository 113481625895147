import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { authSelectors } from '../../../state/ducks/authentication';
import { PATHS } from '../../../constants';

const PublicRoute = ({ component: Component, restricted = false, redirectPath, ...rest }) => {
	const token = useSelector(authSelectors.getToken);

	return (
		<Route
			{...rest}
			render={(routeProps) =>
				token && restricted
					? <Redirect to={redirectPath || PATHS.LOGIN}/>
					: <Component {...routeProps}/>
			}
		/>
	);
};

export default PublicRoute;
