import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { PreviousRidesList, PreviousRidesScreenWrapper } from '../../styles/PreviousRidesScreen/PreviousRidesScreen';
import { ridesActions, ridesSelectors } from '../../../state/ducks/rides';
import PreviousRidesItem from './PreviousRidesItem';
import { DATABASE, GENERAL, MODAL, PATHS, SERVICE_WORKER, STRINGS } from '../../../constants';
import { destinationActions, destinationSelectors } from '../../../state/ducks/destination';
import { productsActions, productsSelectors } from '../../../state/ducks/products';
import { generalActions, generalSelectors } from '../../../state/ducks/general';
import { trucksSelectors } from '../../../state/ducks/trucks';
import { modalActions } from '../../../state/ducks/modal';

const PreviousRidesScreen = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const previousRides = useSelector(ridesSelectors.getPreviousRides);
	const places = useSelector(destinationSelectors.getDestinations);
	const products = useSelector(productsSelectors.getProducts);
	const trucks = useSelector(trucksSelectors.getTrucks);
	const rideContext = useSelector(generalSelectors.getRideContext);

	const [rides, setRides] = useState([]);

	const getTimeValues = (timestamp) => {
		const date = moment(timestamp).format('DD.MM.yyyy').toLocaleString();
		const hour = moment(timestamp).format('HH:mm').toLocaleString();

		return [hour, date];
	};

	const getRideData = (ride) => {
		const locations = ride.ride_items
			.map(({ end_location: location }) => location)
			.map(({ name }) => name);

		const articles = ride.ride_items
			.map(({
				item_type: type,
				amount,
				product: { name, height, length, width }
			}) => ({ type, amount, name, height, length, width }));

		return [locations, articles];
	};

	const createCartData = (item) => {
		const startLocation = item.start_location.name;
		const cart = item.ride_items.map(({
			amount,
			end_location: { name },
			item_type: type,
			product: { id, is_ballen: isBallen }
		}) => ({
			id,
			isBallen,
			selectionId: uuidv4(),
			isPAL: type === STRINGS.PAL_KEY,
			amount,
			selectedDestination: name
		}));

		return [startLocation, cart];
	};

	const createDataForRidesFromDb = useCallback((ridesFromDb) => {
		return ridesFromDb?.map(({
			id: ID,
			dbType,
			dbId,
			start_location: start,
			truck,
			start_time: startTime,
			ride_items: rideItems
		}) => ({
			id: ID,
			dbType,
			dbId,
			start_time: startTime,
			start_location: places.find(({ id }) => id === start),
			truck: trucks.find(({ id }) => id === truck),
			ride_items: rideItems.map((item) => ({
				...item,
				end_location: places.find(({ id }) => id === item.end_location),
				product: products.find(({ product: { id } }) => id === item.product).product
			}))
		}));
	}, [places, products, trucks]);

	const handleEditRideClicked = (item) => {
		const [startLocation, cart] = createCartData(item);

		dispatch(destinationActions.setStartLocation({ startLocation }));
		dispatch(productsActions.setCart({ cart }));
		dispatch(generalActions.setRideContext({ rideContext: GENERAL.CONTEXT_EDIT }));
		dispatch(generalActions.setDbType({ dbType: item.dbType }));
		dispatch(generalActions.setDbId({ dbId: item.dbId }));
		dispatch(generalActions.setRideId({ rideId: item.id }));
		dispatch(ridesActions.setCurrentRideId({ currentRideId: item.id }));
		dispatch(ridesActions.setCurrentRideTimestamp({ currentRideTimestamp: item.start_time }));

		history.push(PATHS.SUMMARY);
	};

	const handleDeleteRideClicked = (item) => {
		const rideData = {
			id: item.id,
			dbType: item.dbType,
			dbId: item.dbId
		};

		dispatch(modalActions.showModal({
			modalType: MODAL.DELETE_RIDE,
			rideData
		}));
	};

	const checkRideIsOffline = (item) =>
		[DATABASE.TYPE_POST, DATABASE.TYPE_PUT].includes(item.dbType);

	useEffect(() => {
		const isDataFromDb = previousRides?.filter(({ dbType }) => dbType).length;

		if (isDataFromDb) {
			setRides(createDataForRidesFromDb(previousRides));
		} else {
			setRides(previousRides);
		}
	}, [createDataForRidesFromDb, previousRides]);

	useEffect(() => {
		dispatch(ridesActions.fetchRides());
	}, [dispatch, rideContext]);

	useEffect(() => {
		const channel = new BroadcastChannel(SERVICE_WORKER.CHANNEL);

		channel.onmessage = (e) => {
			if (e.data === SERVICE_WORKER.CONTENT_UPDATE) {
				dispatch(ridesActions.fetchRides());
			}
		};

		return () => channel.close();
	}, [dispatch]);

	return (
		<PreviousRidesScreenWrapper>
			<PreviousRidesList>
				{rides?.map((item) => {
					const [hour, date] = getTimeValues(item.start_time);
					const [destinations, articles] = getRideData(item);
					const offline = checkRideIsOffline(item);

					return (
						<PreviousRidesItem
							key={item.id || item.start_time}
							item={item}
							date={date}
							hour={hour}
							destinations={destinations}
							articles={articles}
							offline={offline}
							handleEditRideClicked={() => handleEditRideClicked(item)}
							handleDeleteRideClicked={() => handleDeleteRideClicked(item)}
						/>
					);
				})}
			</PreviousRidesList>
		</PreviousRidesScreenWrapper>
	);
};

export default PreviousRidesScreen;
