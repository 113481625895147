import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { equals, includes } from 'ramda';
import { ReactComponent as SleepIcon } from '../../../../icons/sleep-mode.svg';
import { ReactComponent as RunnerIcon } from '../../../../icons/runner.svg';
import { ReactComponent as SnailIcon } from '../../../../icons/snail.svg';
import { generalActions, generalSelectors } from '../../../state/ducks/general';
import {
	AppThemeSwitch,
	BottomBarWrapper,
	InfoText,
	QuickIconWrapper,
	QuickModeSwitch,
	SleepIconWrapper,
	SwitchesWrapper
} from '../../styles/Common/BottomBar';
import { authSelectors } from '../../../state/ducks/authentication';
import { PATHS } from '../../../constants';

const BottomBar = ({ infoText }) => {
	const { location: { pathname } } = useHistory();
	const dispatch = useDispatch();
	const darkThemeEnabled = useSelector(generalSelectors.isDarkTheme);
	const quickModeEnabled = useSelector(generalSelectors.isQuickMode);
	const isAdmin = useSelector(authSelectors.isAdminLoggedIn);

	const handleThemeToggled = () => dispatch(generalActions.toggleDarkTheme());

	const handleQuickModeToggled = () => dispatch(generalActions.toggleQuickMode());

	const shouldShowQuickModeSwitch = useMemo(() => {
		return includes(pathname, [
			PATHS.SCAN_INVOICE
		]) && equals('enabled', process.env.REACT_APP_QUICK_MODE);
	}, [pathname]);

	return (
		<BottomBarWrapper>
			<SwitchesWrapper>
				<AppThemeSwitch onClick={handleThemeToggled}>
					<SleepIconWrapper toggled={darkThemeEnabled}>
						<SleepIcon/>
					</SleepIconWrapper>
				</AppThemeSwitch>
				{shouldShowQuickModeSwitch && isAdmin && <QuickModeSwitch
					quickMode={quickModeEnabled}
					onClick={handleQuickModeToggled}
				>
					<QuickIconWrapper toggled={quickModeEnabled}>
						{quickModeEnabled ? <RunnerIcon/> : <SnailIcon/>}
					</QuickIconWrapper>
				</QuickModeSwitch>}
			</SwitchesWrapper>
			<InfoText black={isAdmin}>{infoText}</InfoText>
		</BottomBarWrapper>
	);
};

export default BottomBar;
