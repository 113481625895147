import React from 'react';
import { ButtonOkay, ButtonsContainer, ModalContent, ModalText } from '../../styles/Modal/Modal';
import { STRINGS } from '../../../constants';
import DelayedButton from '../Common/DelayedButton';

const ModalDatabaseAccepted = ({
	showModal,
	handleDismiss
}) => {
	return (
		<ModalContent show={showModal}>
			<ModalText dangerouslySetInnerHTML={{
				__html: STRINGS.DATABASE_ACCEPTED_MSG
			}}/>
			<ButtonsContainer>
				<DelayedButton onClick={handleDismiss} component={ButtonOkay}>
					{STRINGS.OKAY}
				</DelayedButton>
			</ButtonsContainer>
		</ModalContent>
	);
};

export default ModalDatabaseAccepted;
