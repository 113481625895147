import reducer from './reducers';

import * as searchActions from './actions';
import * as searchTypes from './types';
import * as searchSelectors from './selectors';

export {
	searchActions,
	searchTypes,
	searchSelectors
};

export default reducer;
