import React, { useEffect, useState } from 'react';
import { always, equals, when } from 'ramda';
import {
	DropdownButtonSmall,
	DropdownSmallList,
	DropdownSmallTitle,
	DropdownSmallWrapper
} from '../../styles/Dropdown/Dropdown';
import { ReactComponent as DownArrow } from '../../../../icons/open_small.svg';
import { useOutsideClick } from './useOutsideClick';

const DropdownCustom = ({
	placeholder,
	items,
	updateValue,
	initialValue,
	emptyLabel,
	red = false,
	downArrow: Arrow
}) => {
	const [value, setValue] = useState(initialValue || '');
	const [isOpen, setIsOpen] = useState(false);

	const toggleList = () => setIsOpen((prev) => !prev);

	const selectItem = (item) => {
		setValue(item);
		updateValue(item);
		toggleList();
	};

	const dropdownRef = useOutsideClick(() => {
		if (isOpen) {
			toggleList();
		}
	});

	useEffect(() => {
		setValue(initialValue);
	}, [initialValue]);

	return (
		<DropdownSmallWrapper ref={dropdownRef}>
			<DropdownButtonSmall
				onClick={toggleList}
				isOpen={isOpen}
			>
				<DropdownSmallTitle
					isOpen={isOpen}
					red={red}
				>
					{emptyLabel
						? when(equals(emptyLabel), always(placeholder))(value) || placeholder
						: value || placeholder}
				</DropdownSmallTitle>
				{Arrow ? <Arrow/> : <DownArrow/>}
			</DropdownButtonSmall>
			<DropdownSmallList isOpen={isOpen}>
				{items.map((item, index) => {
					return (
						<DropdownSmallTitle
							key={index}
							onClick={() => selectItem(item)}
						>
							{item}
						</DropdownSmallTitle>
					);
				})}
			</DropdownSmallList>
		</DropdownSmallWrapper>
	);
};

export default DropdownCustom;
