import { createSelector } from 'reselect';
import { __, all, complement, equals, filter, includes, isNil, join, map, pipe, reject, reverse, when } from 'ramda';
import moment from 'moment';
import { REPORTS, STRINGS } from '../../../constants';
import { getDestinations } from '../destination/selectors';
import { getProducts } from '../products/selectors';
import { getDeliveryLocation } from '../deliveries/selectors';

export const getFilters = createSelector(
	[({ reports }) => reports.filters],
	(filters) => filters
);

export const getDate = createSelector(
	[getFilters],
	(filters) => filters?.[REPORTS.DATE]
);

export const getTruck = createSelector(
	[getFilters],
	(filters) => filters?.[REPORTS.TRUCK]
);

export const getStart = createSelector(
	[getFilters],
	(filters) => filters?.[REPORTS.START]
);

export const getEnd = createSelector(
	[getFilters],
	(filters) => filters?.[REPORTS.END]
);

export const getReportsQueryString = createSelector(
	[getFilters, getDestinations],
	(filters, destinations) => {
		const formatDate = ([key, value]) => [key, reverse(value.split('-')).join('.')];
		const mapLocation = ([key, value]) =>
			[key, destinations?.find(({ name }) => name === value).location_nr];
		const updateDate = when(([key]) => key.startsWith(REPORTS.DATE), formatDate);
		const updateLocation = when(([key]) =>
			equals(key, REPORTS.START) || equals(key, REPORTS.END), mapLocation);
		const isNoSelection = ([, value]) => includes(value, [
			REPORTS.NO_TRUCK_SELECTION,
			REPORTS.NO_START_SELECTION,
			REPORTS.NO_END_SELECTION
		]);
		const joinKeyValue = join('=');
		const joinEntries = join('&');

		return pipe(
			Object.entries,
			reject(isNoSelection),
			map(updateDate),
			map(updateLocation),
			map(joinKeyValue),
			joinEntries
		)(filters);
	});

export const getCorrections = createSelector(
	[({ reports }) => reports.correction],
	(correction) => correction
);

export const getType = createSelector(
	[getCorrections],
	(correction) => correction?.[REPORTS.CORRECTION_TYPE]
);

export const getReason = createSelector(
	[getCorrections],
	(correction) => correction?.[REPORTS.CORRECTION_REASON]
);

export const getEndLocation = createSelector(
	[getCorrections],
	(correction) => correction?.[REPORTS.CORRECTION_END_WAREHOUSE]
);

export const getAmount = createSelector(
	[getCorrections],
	(correction) => correction?.[REPORTS.CORRECTION_AMOUNT]
);

export const getProductNr = createSelector(
	[getCorrections],
	(correction) => correction?.[REPORTS.CORRECTION_PRODUCT_NR]
);

export const getUnit = createSelector(
	[getCorrections],
	(correction) => correction?.[REPORTS.CORRECTION_UNIT]
);

export const getProduct = createSelector(
	[getCorrections, getProducts],
	(correction, products) =>
		products?.find(({ article_nr: nr }) => nr === correction?.[REPORTS.CORRECTION_PRODUCT_NR])
);

export const getCustomNote = createSelector(
	[getCorrections],
	(correction) => correction?.[REPORTS.CORRECTION_CUSTOM_NOTE] || ''
);

export const allCorrectionDetailsProvided = createSelector(
	[getReason, getProduct, getEndLocation],
	(reason, product, endLocation) => all(complement(isNil))([
		reason,
		product,
		...(reason === REPORTS.REASON_DELIVERY ? [endLocation] : [])
	])
);

export const getReport = createSelector(
	[({ reports }) => reports.report],
	(report) => report || {}
);

export const getCorrectionPayload = createSelector(
	[getAmount, getUnit, getReason, getProductNr, getDestinations, getDeliveryLocation, getCustomNote],
	(amount, unit, reason, productNr, destinations, location, note) => {
		const locationNr = destinations.find(({ name }) => name === location)?.location_nr;

		return {
			location_nr: locationNr,
			product_nr: productNr,
			amount,
			unit,
			is_plus: false,
			comment: reason,
			custom_comment: note
		};
	}
);

export const getDoubleCorrectionPayload = createSelector(
	[getAmount, getUnit, getReason, getProductNr, getDestinations, getDeliveryLocation, getEndLocation],
	(amount, unit, reason, productNr, destinations, location, endLocation) => {
		const startLocationNr = destinations.find(({ name }) => name === location)?.location_nr;
		const endLocationNr = destinations.find(({ name }) => name === endLocation)?.location_nr;

		return {
			start_location_nr: startLocationNr,
			end_location_nr: endLocationNr,
			product_nr: productNr,
			amount,
			unit,
			comment: reason
		};
	}
);

export const getAllDetails = createSelector(
	[({ reports }) => reports.allDetails],
	(allDetails) => allDetails
);

export const getCorrectionReport = createSelector(
	[({ reports }) => reports.correctionReport],
	(report) => report
);

export const isCorrectionReportReasonAll = createSelector(
	[getCorrectionReport],
	({ reason }) => reason === STRINGS.ALL
);

export const getCorrectionReportQuery = createSelector(
	[getCorrectionReport],
	(report) =>
		`?correction_type=${report.reason}&start_date=${report.startDate}&end_date=${report.endDate}`
);

export const getAllCorrectionsReportsQueries = createSelector(
	[getCorrectionReport],
	(report) => ([
		REPORTS.REASON_DELIVERY,
		REPORTS.REASON_DAMAGE,
		REPORTS.REASON_WAREHOUSE
	].map((reason) =>
		`?correction_type=${reason}&start_date=${report.startDate}&end_date=${report.endDate}`
	)));

export const getRidesReportFilters = createSelector(
	[({ reports }) => reports.ridesReport],
	(report) => report
);

export const getRidesReportQuery = createSelector(
	[getRidesReportFilters, getDestinations],
	(report, destinations) => {
		const currentDate = moment(new Date()).format('yyyy-MM-DD');

		const {
			truckName,
			startDate = currentDate,
			endDate = currentDate,
			startLocation,
			endLocation
		} = report || {};

		const startNr = destinations.find(({ name }) => name === startLocation)?.location_nr;
		const endNr = destinations.find(({ name }) => name === endLocation)?.location_nr;
		const isPresent = complement(isNil);
		const isNotAll = complement(includes(__, [
			REPORTS.NO_TRUCK_SELECTION,
			REPORTS.NO_START_SELECTION,
			REPORTS.NO_END_SELECTION
		]));

		const query = {
			start_date: moment(startDate).format('DD.MM.yyyy'),
			end_date: moment(endDate).format('DD.MM.yyyy'),
			truck_name: truckName,
			start_location_nr: startNr,
			end_location_nr: endNr
		};

		return pipe(
			filter(isPresent),
			filter(isNotAll),
			Object.entries,
			map(join('=')),
			join('&')
		)(query);
	}
);
