import React from 'react';

const DelayedButton = ({
	onClick = () => {
	},
	suppressTouch = false,
	component: Component,
	...rest
}) => {
	const handleClick = () => setTimeout(onClick, 200);
	const isMobileDevice = navigator.userAgent.includes('Android');

	return (
		<Component
			onClick={!isMobileDevice || suppressTouch ? handleClick : null}
			onTouchEnd={isMobileDevice && !suppressTouch ? handleClick : null}
			{...rest}
		/>
	);
};

export default DelayedButton;
