import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { assoc } from 'ramda';
import moment from 'moment';
import {
	CloseModalButton, Divider, DownloadReportButton,
	ModalReportsContent,
	ReportFiltersContainer,
	ReportTitle
} from '../../../styles/Modal/ModalReports';
import { ReactComponent as ReportIcon } from '../../../../../icons/report.svg';
import { REPORTS, STRINGS } from '../../../../constants';
import ReportControls from './ReportControls';
import { DATE_PICKER_TYPES } from './DatePicker';
import { destinationSelectors } from '../../../../state/ducks/destination';
import { trucksSelectors } from '../../../../state/ducks/trucks';
import { DatePickersContainer, DropdownsContainer, FiltersContainer } from '../../../styles/Modal/ModalRidesReport';
import { reportsActions, reportsSelectors } from '../../../../state/ducks/reports';

const FILTER_TYPES = {
	START_DATE: 'startDate',
	END_DATE: 'endDate',
	TRUCK_NAME: 'truckName',
	START_LOCATION: 'startLocation',
	END_LOCATION: 'endLocation'
};

const ModalRidesReports = ({
	showModal,
	handleDismiss
}) => {
	const dispatch = useDispatch();
	const ridesReport = useSelector(reportsSelectors.getRidesReportFilters);
	const destinations = useSelector(destinationSelectors.getAvailableStartLocations);
	const trucks = useSelector(trucksSelectors.getTrucksIds);
	const currentDate = moment(new Date()).format('yyyy-MM-DD');

	const { startDate, endDate } = ridesReport || {};

	const handleDownloadReport = () => {
		dispatch(reportsActions.downloadRidesReport());
	};

	const setRidesReportFilters = (type) => (value) => {
		const updated = assoc(type, value, ridesReport || {});

		dispatch(reportsActions.setRidesReport({ ridesReport: updated }));
	};

	return (
		<ModalReportsContent show={showModal}>
			<CloseModalButton onClick={handleDismiss}/>
			<ReportFiltersContainer>
				<ReportTitle>
					<ReportIcon/>
					{STRINGS.INDIVIDUAL_REPORT}
				</ReportTitle>
				<Divider/>
				<ReportControls>
					<FiltersContainer>
						<DatePickersContainer>
							<ReportControls.ControlledDatePicker
								label={STRINGS.FROM}
								updater={setRidesReportFilters(FILTER_TYPES.START_DATE)}
								value={startDate || currentDate}
							/>
							<ReportControls.ControlledDatePicker
								label={STRINGS.TO}
								updater={setRidesReportFilters(FILTER_TYPES.END_DATE)}
								value={endDate || currentDate}
							/>
						</DatePickersContainer>
						<DropdownsContainer>
							<ReportControls.DataDropdown
								items={[...(trucks || []), REPORTS.NO_TRUCK_SELECTION]}
								updater={setRidesReportFilters(FILTER_TYPES.TRUCK_NAME)}
								noSelectionLabel={REPORTS.NO_TRUCK_SELECTION}
							/>
							<ReportControls.DataDropdown
								items={[...(destinations || []), REPORTS.NO_START_SELECTION]}
								updater={setRidesReportFilters(FILTER_TYPES.START_LOCATION)}
								noSelectionLabel={REPORTS.NO_START_SELECTION}
							/>
							<ReportControls.DataDropdown
								items={[...(destinations || []), REPORTS.NO_END_SELECTION]}
								updater={setRidesReportFilters(FILTER_TYPES.END_LOCATION)}
								noSelectionLabel={REPORTS.NO_END_SELECTION}
							/>
						</DropdownsContainer>
					</FiltersContainer>
				</ReportControls>
				<Divider/>
				<DownloadReportButton onClick={handleDownloadReport}>
					{STRINGS.GENERATE_REPORT}
				</DownloadReportButton>
			</ReportFiltersContainer>
		</ModalReportsContent>
	);
};

export default ModalRidesReports;
