import { setupPostDatabase, setupReadyDatabase } from './setupDatabase';
import { API, SERVICE_WORKER } from '../../constants';
import RideReadyHandler from './RideReadyHandler';
import { fetchWithTimeout } from './utils';

class RidePostHandler {
	constructor() {
		this.database = setupPostDatabase();
		this.readyDb = setupReadyDatabase();
	}

	getRidesToPost() {
		return this.database.rides.toArray();
	}

	static getRidesIds(rides) {
		return rides.map(({ dbId }) => dbId);
	}

	async deleteRides(rides) {
		const ids = RidePostHandler.getRidesIds(rides);
		await this.database.rides.bulkDelete(ids);
	}

	async deleteById(id) {
		await this.database.rides.bulkDelete([id]);
	}

	static mapRideItems(rideItems) {
		return rideItems.map(({ product_details: details, ...rest }) => ({
			...rest
		}));
	}

	static mapRide(ride) {
		return {
			ride_items: this.mapRideItems(ride.ride_items),
			start_location: ride.start_location,
			start_time: ride.start_time,
			truck: ride.truck
		};
	}

	static prepareRequests(rides) {
		const controller = new AbortController();

		return rides.map((ride) => {
			const payload = this.mapRide(ride);

			return fetchWithTimeout(`${API.DOMAIN}${API.RIDES}`, 45000, {
				signal: controller.signal,
				method: 'POST',
				headers: {
					'Authorization': `Token ${ride.token}`,
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(payload)
			});
		});
	}

	async postRidesAndClearDb() {
		const rides = await this.getRidesToPost();
		const requests = RidePostHandler.prepareRequests(rides);
		await Promise.all(requests).then(async (values) => {
			values.forEach((val) => {
				val.json().then(async (data) => {
					const readyHandler = new RideReadyHandler();
					await readyHandler.addRide(data);
				});
			});
			await this.deleteRides(rides);
		}).catch(async () => {
			const channel = new BroadcastChannel(SERVICE_WORKER.CHANNEL);
			const ridesData = rides.map(({ dbId, ...rest }) => ({ ...rest }));

			channel.postMessage({
				msg: SERVICE_WORKER.SLOW_INTERNET,
				ridesData
			});

			await this.deleteRides(rides);
		});
	}
}

export default RidePostHandler;
