import reducer from './reducers';

import * as authActions from './actions';
import * as authTypes from './types';
import * as authSelectors from './selectors';
import * as authOperations from './operations';

export {
	authActions,
	authTypes,
	authSelectors,
	authOperations,
};

export default reducer;
