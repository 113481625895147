import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
	AdminButton,
	ClientRideContainer,
	ClientRideText,
	DropdownClients,
	DropdownWarehouse,
	MenuScreenWrapper,
	OptionIcon,
	SelectOptionContainer,
	WarehouseIcon,
	WarehouseRideContainer,
	WarehouseRideText
} from '../../styles/MenuScreen/MenuScreen';
import BottomBar from '../Common/BottomBar';
import WarehousesIcon from '../../../../icons/warehouses.png';
import WarehousesDarkIcon from '../../../../icons/warehouses-dark.png';
import DeliveryManIcon from '../../../../icons/delivery-man.png';
import DeliveryManDarkIcon from '../../../../icons/delivery-man-dark.png';
import { PATHS, STORAGE, STRINGS } from '../../../constants';
import DelayedButton from '../Common/DelayedButton';
import DropdownCustom from '../Dropdown/DropdownCustom';
import { ReactComponent as DownArrow } from '../../../../icons/down_arrow2.svg';
import { generalActions, generalSelectors } from '../../../state/ducks/general';
import { trucksSelectors } from '../../../state/ducks/trucks';
import { destinationActions, destinationSelectors } from '../../../state/ducks/destination';
import { authActions, authSelectors } from '../../../state/ducks/authentication';
import { deliveriesActions, deliveriesSelectors } from '../../../state/ducks/deliveries';
import { productsActions } from '../../../state/ducks/products';
import { getItemFromStorage } from '../../../utils/storage/storage';

const MainMenuScreen = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const darkTheme = useSelector(generalSelectors.isDarkTheme);
	const trucks = useSelector(trucksSelectors.getTrucksIds);
	const locations = useSelector(destinationSelectors.getDestinationsList);
	const username = useSelector(authSelectors.getUsername);
	const location = useSelector(deliveriesSelectors.getDeliveryLocation);
	const initialTruckId = useSelector(authSelectors.getTruckId);
	const isAdmin = getItemFromStorage(STORAGE.IS_GUTEX_ADMIN_LAST_LOGGED_IN) === 'true';
	const isDriver = getItemFromStorage(STORAGE.IS_GUTEX_DRIVER_LAST_LOGGED_IN) === 'true';

	const [selectedTruck, setSelectedTruck] = useState(initialTruckId);
	const [selectedLocation, setSelectedLocation] = useState(location);
	const [truckInvalid, setTruckInvalid] = useState(false);
	const [locationInvalid, setLocationInvalid] = useState(false);

	const handleWarehouseClicked = () => {
		if (selectedTruck) {
			history.push(PATHS.RIDES_MENU);
		} else {
			setTruckInvalid(true);
		}
	};

	const handleClientsClicked = () => {
		if (selectedLocation) {
			history.push(PATHS.SCAN_INVOICE);
		} else {
			setLocationInvalid(true);
		}
	};

	const updateTruck = (value) => {
		setSelectedTruck(value);
		setTruckInvalid(false);
		dispatch(authActions.setTruckId({ truckId: value }));

		if (isDriver && value) {
			history.push(PATHS.RIDES_MENU);
		}
	};

	const updateLocation = (value) => {
		setSelectedLocation(value);
		setLocationInvalid(false);
	};

	const handleReportsClicked = () => history.push(PATHS.REPORTS);

	const handleCorrectionClicked = () => {
		if (selectedLocation) {
			history.push(PATHS.CORRECTION);
		} else {
			setLocationInvalid(true);
		}
	};

	useEffect(() => {
		dispatch(deliveriesActions.setDeliveryLocation({ location: selectedLocation }));
	}, [dispatch, selectedLocation]);

	useEffect(() => {
		dispatch(destinationActions.fetchDestinations());
		dispatch(productsActions.setProducts({ products: [] }));
		dispatch(productsActions.setLastProducts({ lastProducts: [] }));
		dispatch(generalActions.setQuickMode({ quickMode: false }));
	}, [dispatch]);

	return (
		<MenuScreenWrapper>
			<SelectOptionContainer smallGap={isAdmin} columns={isDriver ? 1 : 2}>
				<DropdownWarehouse invalid={truckInvalid}>
					<DropdownCustom
						placeholder={STRINGS.LKW}
						initialValue={initialTruckId || ''}
						items={trucks}
						downArrow={DownArrow}
						updateValue={updateTruck}
					/>
				</DropdownWarehouse>
				{!isDriver && <DropdownClients invalid={locationInvalid}>
					<DropdownCustom
						placeholder={STRINGS.WAREHOUSE}
						items={locations}
						downArrow={DownArrow}
						updateValue={updateLocation}
						initialValue={selectedLocation}
					/>
				</DropdownClients>}
				<DelayedButton onClick={handleWarehouseClicked} component={WarehouseRideContainer}>
					<WarehouseIcon src={darkTheme ? WarehousesDarkIcon : WarehousesIcon}/>
					<WarehouseRideText>{STRINGS.WAREHOUSE_RIDE}</WarehouseRideText>
				</DelayedButton>
				{!isDriver && <DelayedButton onClick={handleClientsClicked} component={ClientRideContainer}>
					<OptionIcon src={darkTheme ? DeliveryManDarkIcon : DeliveryManIcon}/>
					<ClientRideText>{STRINGS.CLIENT_RIDE}</ClientRideText>
				</DelayedButton>}
				{isAdmin && <>
					<DelayedButton onClick={handleReportsClicked} component={AdminButton}>
						{STRINGS.REPORTS_LOGISTIC}
					</DelayedButton>
					<DelayedButton onClick={handleCorrectionClicked} component={AdminButton}>
						{STRINGS.WAREHOUSE_CORRECTIONS}
					</DelayedButton>
				</>}
			</SelectOptionContainer>
			<BottomBar infoText={username}/>
		</MenuScreenWrapper>
	);
};

export default MainMenuScreen;
