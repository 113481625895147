import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { always } from 'ramda';
import moment from 'moment';
import * as TYPES from './types';
import { setAdminToken, setGutexUsers, setPasswordValidity, setToken, setTruckTokens, setUsername } from './actions';
import { gutexUsers, login } from '../../../api';
import { clearStorage, getItemFromStorage, putItemIntoStorage } from '../../../utils/storage/storage';
import { AUTHENTICATION, STORAGE } from '../../../constants';
import RideReadyHandler from '../../../utils/database/RideReadyHandler';
import { isDarkTheme } from '../general/selectors';
import { toggleDarkTheme } from '../general/actions';
import { trucksSelectors } from '../trucks';
import { getGutexUsers } from './selectors';

function* fetchTokensForTrucks(password) {
	const truckIds = yield select(trucksSelectors.getTrucksIds);

	const responses = yield all(truckIds.map((id) => call(login, {
		username: id,
		password
	})));

	const truckTokens = responses.map(({ config: { data }, data: { key } }) => ([
		JSON.parse(data).username,
		key
	])).reduce((acc, [id, token]) => ({
		...acc,
		[id]: token
	}), {});

	yield put(setTruckTokens({ truckTokens }));
	yield call(putItemIntoStorage, AUTHENTICATION.TRUCK_TOKEN_KEYS, JSON.stringify(truckTokens));
}

function* checkStorageForToken() {
	const token = yield getItemFromStorage(AUTHENTICATION.TOKEN_KEY);
	const adminToken = yield getItemFromStorage(AUTHENTICATION.ADMIN_TOKEN_KEY);
	const username = yield getItemFromStorage(AUTHENTICATION.USERNAME);
	const truckTokens = yield getItemFromStorage(AUTHENTICATION.TRUCK_TOKEN_KEYS);

	if (token) {
		yield put(setToken({ token }));
		yield put(setUsername({ username }));
	} else if (adminToken) {
		yield put(setAdminToken({ adminToken }));
	}

	if (truckTokens) {
		yield put(setTruckTokens({ truckTokens: JSON.parse(truckTokens) }));
	}
}

function* attemptLogin(action) {
	const { payload } = action;
	const { password, username, isAdmin } = payload;
	const database = yield new RideReadyHandler();
	const users = yield select(getGutexUsers);

	const { data: { key } = {}, status } = yield call(login, {
		username,
		password
	});

	yield database.deleteAllRides();

	if (status === 200) {
		const isGutexDriverLastLoggedIn = users.find((user) => user.username === username)?.is_lkw_fahrer_only;
		const isGutexAdminLastLoggedIn = users.find((user) => user.username === username)?.is_gutex_admin;
		yield put(setPasswordValidity({ passwordValid: true }));
		yield call(putItemIntoStorage, STORAGE.LAST_ACTION, moment.utc().local().format());
		yield call(putItemIntoStorage, STORAGE.IS_GUTEX_DRIVER_LAST_LOGGED_IN, isGutexDriverLastLoggedIn);
		yield call(putItemIntoStorage, STORAGE.IS_GUTEX_ADMIN_LAST_LOGGED_IN, isGutexAdminLastLoggedIn);

		if (isAdmin) {
			yield call(putItemIntoStorage, AUTHENTICATION.ADMIN_TOKEN_KEY, key);
			yield put(setAdminToken({ adminToken: key }));
		} else {
			yield put(setUsername({ username }));
			yield call(putItemIntoStorage, AUTHENTICATION.TOKEN_KEY, key);
			yield call(putItemIntoStorage, AUTHENTICATION.USERNAME, username);
			yield put(setToken({ token: key }));

			yield* fetchTokensForTrucks(always('v8Q7SaNeFdJnCa9Hkbnh')());
		}
	} else {
		yield put(setPasswordValidity({ passwordValid: false }));
	}
}

function* attemptLogout() {
	const darkThemeEnabled = yield select(isDarkTheme);
	const database = yield new RideReadyHandler();

	if (darkThemeEnabled) {
		yield put(toggleDarkTheme());
	}

	yield call(clearStorage);
	yield database.deleteAllRides();
}

function* fetchGutexUsers() {
	const { data, status } = yield call(gutexUsers);

	if (status === 200) {
		yield put(setGutexUsers({ gutexUsers: data }));
	}
}

export const checkStorageForTokenSaga = takeEvery(
	TYPES.CHECK_STORAGE_FOR_TOKEN,
	checkStorageForToken
);

export const attemptLoginSaga = takeEvery(
	TYPES.ATTEMPT_LOGIN,
	attemptLogin
);

export const attemptLogoutSaga = takeEvery(
	TYPES.ATTEMPT_LOGOUT,
	attemptLogout
);

export const fetchUsersSaga = takeEvery(
	TYPES.FETCH_GUTEX_USERS,
	fetchGutexUsers
);
