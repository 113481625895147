import styled, { css } from 'styled-components';
import { ButtonSubmit } from '../Common/Common';

export const ProductScreenWrapper = styled.div`
	position: relative;
	height: 100vh;
	width: 100%;
	display: flex;
	flex-direction: column;
	background-color: ${({ theme }) => theme.colors.primary};
`;

export const ProductDetailsContainer = styled.div`
	display: grid;
	margin-top: auto;
	grid-template-columns: 1fr max-content;
	grid-template-rows: minmax(max-content, 18rem) repeat(2, max-content);
	align-items: center;
	justify-content: center;
	width: 100%;
	padding: 0 8rem;
	font-family: Roboto, sans-serif;
	font-size: 4.2rem;
	font-weight: 400;
	
	@media (min-width: 1281px) {
			margin-top: ${({ isDesktop }) => isDesktop ? '4rem' : 'auto'};
	}
`;

export const ApproveButtonContainer = styled.div`
	bottom: 5rem;
	width: 100%;
	padding: 0 8rem;
	margin-bottom: 8rem;
	margin-top: 6rem;
	
	@media (min-width: 1281px) {
			margin-top: ${({ isDesktop }) => isDesktop ? 'auto' : '6rem'};
	}
`;

export const ApproveButton = styled(ButtonSubmit)`
	height: 14rem;
	width: 100%;
	background-color: ${({ theme, valid }) => !valid && theme.colors.primaryGrey};
	
	${({ valid }) => !valid && css`
		opacity: 0;
	`};
`;

export const ProductTitle = styled.div`
	font-weight: 700;
	grid-column: 1 / 3;
	align-self: flex-end;
	margin-top: 12rem;
	margin-bottom: 1rem;
	font-size: 4rem;
`;

export const ContainerType = styled.div`
	position: absolute;
	color: ${({ theme, pal }) =>
	pal ? 'black' : theme.colors.fontRed};
	font-weight: ${({ pal }) => pal ? 500 : 700};
	font-size: 2rem;
	left: 50%;
	top: 95%;
	transform: translateX(-50%);
	text-transform: uppercase;
`;

export const ProductDetails = styled.div`
	margin-right: 1rem;
`;

export const AmountButtonsContainer = styled.div`
	position: relative;
	display: flex;
`;

export const ChangeAmountButton = styled.button`
	position: relative;
	height: 10rem;
	width: 10rem;
	background-color: ${({ theme, limit }) =>
	limit ? theme.colors.lightRed : theme.colors.primaryRed};
	border: none;
	border-radius: 6px;
	transition: all 0.2s;
	
	svg {
		position: absolute;
		fill: ${({ theme }) => theme.colors.primaryWhite};
		width: 7.5rem;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
	
	&:focus,
	&:hover {
		outline: 0;
		background-color: ${({ theme }) => theme.colors.lightRed};
	}
	
	&:not(:active) {
		background-color: ${({ theme, limit }) =>
	limit ? theme.colors.lightRed : theme.colors.primaryRed};
	}
`;

export const AmountInput = styled.input`
	color: ${({ theme, pal }) => pal ? 'black' : theme.colors.fontRed};
	background-color: ${({ theme }) => theme.colors.primary};
	border: none;
	font-family: Roboto, sans-serif;
	font-weight: 700;
	font-size: 8rem;
	width: 16rem;
	text-align: center;
	
	::placeholder {
		color: black;
		opacity: 1;
	}
	
	&:focus {
		outline: 0;	
	}
`;

export const SelectionsContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	grid-column: 1 / span 2;
	margin-top: 4.5rem;
`;

export const DropdownContainer = styled.div`
	display: flex;
	align-items: center;
`;

export const ContainerTypeWrapper = styled.div`
	display: flex;
	align-items: center;
`;

export const TypeButton = styled.button`
	font-family: Roboto, sans-serif;
	font-weight: 400;
	font-size: 4.2rem;
	padding: 1rem 1.5rem;
	border: 4px solid ${({ theme, pal }) =>
	pal ? theme.colors.lightRed : theme.colors.primaryRed};
	
	&:focus {
		outline: 0;
	}
`;

export const PalletButton = styled(TypeButton)`
	border-top-left-radius: 6px;
	border-bottom-left-radius: 6px;
	background-color: ${({ theme, pal }) =>
	pal ? theme.colors.lightRed : theme.colors.primaryWhite};
	color: ${({ theme, pal }) => pal ? theme.colors.fontWhite : 'black'};
	border-color: ${({ theme }) => theme.colors.mediumRed};
	border-right: ${({ pal }) => !pal && 'none'};
`;

export const PiecesButton = styled(TypeButton)`
	border-left: none;
	border-top-right-radius: 6px;
	border-bottom-right-radius: 6px;
	background-color: ${({ theme, pal }) =>
	pal ? theme.colors.primary : theme.colors.fontRed};
	color: ${({ theme, pal }) =>
	pal ? theme.colors.fontGrey : theme.colors.fontWhite};
`;
