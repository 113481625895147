import produce from 'immer';
import * as TYPES from './types';

const reports = (state = {}, { type, payload }) => {
	switch (type) {
		case TYPES.SET_REPORT:
		case TYPES.SET_RIDES_REPORT:
		case TYPES.SET_CORRECTION_REPORT:
			return {
				...state,
				...payload
			};
		case TYPES.SET_FILTER:
			return produce(state, (draft) => {
				if (draft.filters) {
					draft.filters = { ...draft.filters, ...payload };
				} else {
					draft.filters = payload;
				}
			});
		case TYPES.SET_CORRECTION:
			return produce(state, (draft) => {
				if (draft.correction) {
					draft.correction = { ...draft.correction, ...payload };
				} else {
					draft.correction = payload;
				}
			});
		case TYPES.CLEAR_CORRECTION:
			return {
				...state,
				correction: {}
			};
		case TYPES.TOGGLE_ALL_DETAILS:
			return produce(state, (draft) => {
				const { timeSlot } = payload;

				if (draft.allDetails) {
					draft.allDetails[timeSlot] = !draft?.allDetails?.[timeSlot];
					draft.allDetails.toggled = timeSlot;
				} else {
					draft.allDetails = { [timeSlot]: true, toggled: timeSlot };
				}
			});
		case TYPES.CLEAR_REPORT_FILTERS:
			return produce(state, (draft) => {
				draft.ridesReport = {};
			});
		default:
			return state;
	}
};

export default reports;
