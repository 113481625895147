import styled from 'styled-components';
import { ButtonSubmit } from '../Common/Common';

export const ModalContainer = styled.div`
	position: fixed;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	z-index: 99999;
	background-color: rgba(0, 0, 0, 0.7);
	transition: all 0.3s;
	opacity: ${({ show }) => (show ? 1 : 0)};
	visibility: ${({ show }) => show ? '' : 'hidden'};
`;

export const ModalContent = styled.div`
	position: fixed;
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: ${({ theme }) => theme.colors.primary};
	width: ${({ admin, increaseWidth }) => admin ? 60 : 90 + (increaseWidth ?? 0)}rem;
	min-height: ${({ admin, low }) => admin || low === 'true' ? 30 : 40}rem;
	border-radius: 6px;
	transition: all 0.3s;
	box-shadow: 0 4px 9px rgba(0, 0, 0, 0.15);
	transform: scale(${({ show }) => (show ? 1 : 0.2)});
	visibility: ${({ show }) => (show ? '' : 'hidden')};
  opacity: ${({ show }) => (show ? 1 : 0)};
  text-align: center;
`;

export const ModalText = styled.div`
	font-family: Roboto, sans-serif;
	font-size: ${({ small }) => small ? 3.5 : 4.2}rem;
	font-weight: 700;
	margin-top: ${({ small }) => small ? 4 : 8}rem;
	padding: 0 2rem;
	
	strong {
		color: ${({ theme }) => theme.colors.fontRed};
	}
`;

export const ButtonsContainer = styled.div`
	margin-top: ${({ small }) => small ? 2 : 8}rem;
	display: flex;
`;

export const ButtonsContainerCustom = styled(ButtonsContainer)`
	margin-top: ${({ marginTop }) => marginTop}rem;
`;

export const ButtonDecision = styled(ButtonSubmit)`
	width: ${({ admin }) => admin ? 22 : 35}rem;
	height: ${({ admin }) => admin ? 7.5 : 12}rem;
	margin: ${({ admin }) => admin ? '0 2rem' : '0 5rem 5rem'};
	font-weight: 400;
	font-size: 4.2rem;
`;

export const ButtonYes = styled(ButtonDecision)`
`;

export const ButtonOkay = styled(ButtonDecision)`
	width: 50rem;
	height: 8rem;
`;

export const ButtonGoBack = styled(ButtonDecision)`
	width: 50rem;
	height: 8rem;
	text-transform: capitalize;
	background-color: ${({ theme }) => theme.colors.primaryRed};
		
	&:focus,
	&:hover {
		background-color: ${({ theme }) => theme.colors.primaryRedHover};
	}
`;

export const ButtonNo = styled(ButtonDecision)`
	background-color: ${({ theme }) => theme.colors.primaryRed};
	
	&:focus,
	&:hover {
		background-color: ${({ theme }) => theme.colors.primaryRedHover};
	}
`;

export const NoteTitle = styled.p`
	width: calc(100% - 12rem);
	font-family: Roboto, sans-serif;
	font-weight: 700;
	font-size: 3.5rem;
	color: black;
	text-align: left;
	margin: 4rem 0 3rem;
`;

export const ApplyNoteButton = styled(ButtonSubmit)`
	height: 9rem;
	width: calc(100% - 12rem);
	font-size: 3.2rem;
	margin: 2.5rem 0 3.5rem;
	background-color: ${({ theme }) => theme.colors.greyMedium};
	
	&:focus,
	&:hover {
		background-color: ${({ theme }) => theme.colors.greyMediumHover};
	}
`;

export const NoteArea = styled.textarea`
	width: calc(100% - 12rem);
	height: 33rem;
	padding: 2.5rem 3rem;
	font-family: Roboto, sans-serif;
	font-weight: 400;
	font-size: 3.2rem;
	color: black;
	resize: none;
	background-color: ${({ theme }) => theme.colors.greyLight2};
	border-radius: 4px;
	border: none;
	overflow: auto;
	outline: none;
`;

export const ModalResetContent = styled(ModalContent)`
	height: 34rem;
	width: 83rem;
	min-height: 0;
`;

export const ModalResetButtonsContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-column-gap: 4rem;
	margin-bottom: 6rem;
	margin-top: ${({ top }) => top ? `${top}rem` : 'auto'};
`;

export const ModalResetText = styled(ModalText)`
	margin-top: 6rem;
	font-size: 3.2rem;
`;

export const ResetButton = styled(ButtonNo)`
	width: 33rem;
	height: 9rem;
	font-size: 3.2rem;
	font-weight: 700;
	margin: 0;
`;

export const DismissButton = styled(ButtonYes)`
	width: 33rem;
	height: 9rem;
	font-size: 3.2rem;
	font-weight: 700;
	margin: 0;
`;

export const ButtonApplyBig = styled(ButtonSubmit)`
	width: 76rem;
	height: 9rem;
	margin-bottom: 6rem;
	font-size: 3.7rem;
`;

export const ModalTextInvoiceZeroItems = styled(ModalText)`
	font-weight: 400;
	font-size: 3.7rem;
	line-height: 1.25;

	strong {
		color: black;
		font-weight: 700;
	}
`;

export const ModalTextSuccessfullySubmitted = styled(ModalText)`
	font-weight: 700;
	font-size: 3.5rem;
	color: black;
	
	strong {
		color: ${({ theme }) => theme.colors.darkGreen};
	}
`;

export const CheckedImage = styled.img`
	width: 21rem;
	height: 21rem;
	margin-top: 3rem;
	margin-bottom: 5rem;
`;
