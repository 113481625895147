import React from 'react';
import { useDispatch } from 'react-redux';
import {
	DismissButton,
	ModalResetButtonsContainer,
	ModalResetContent,
	ModalResetText,
	ResetButton
} from '../../styles/Modal/Modal';
import { STRINGS } from '../../../constants';
import DelayedButton from '../Common/DelayedButton';
import { deliveriesActions } from '../../../state/ducks/deliveries';

const ModalResetInvoice = ({
	handleDismiss,
	showModal
}) => {
	const dispatch = useDispatch();

	const handleResetClicked = () => {
		dispatch(deliveriesActions.resetInvoice());
		handleDismiss();
	};

	return (
		<ModalResetContent show={showModal}>
			<ModalResetText dangerouslySetInnerHTML={{
				__html: STRINGS.RESET_MSG
			}}/>
			<ModalResetButtonsContainer>
				<DelayedButton onClick={handleResetClicked} component={ResetButton}>
					{STRINGS.RESET}
				</DelayedButton>
				<DelayedButton onClick={handleDismiss} component={DismissButton}>
					{STRINGS.REJECT}
				</DelayedButton>
			</ModalResetButtonsContainer>
		</ModalResetContent>
	);
};

export default ModalResetInvoice;
