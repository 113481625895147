import produce from 'immer';
import * as TYPES from './types';
import { productsTypes } from '../products';

const rides = (state = {}, { type, payload }) => {
	switch (type) {
		case TYPES.SET_RIDES:
		case TYPES.SET_CURRENT_RIDE_ID:
		case TYPES.SET_CURRENT_RIDE_TIMESTAMP:
			return {
				...state,
				...payload
			};
		case TYPES.DELETE_RIDE_FROM_LIST:
			return produce(state, (draft) => {
				const index = draft.previousRides.findIndex(({ id }) => id === payload.id);
				draft.previousRides.splice(index, 1);
			});
		case productsTypes.CANCEL_RIDE:
		case TYPES.SEND_RIDE_TO_INDEXED_DB:
			return {
				...state,
				currentRideId: null,
				currentRideTimestamp: null
			};
		default:
			return state;
	}
};

export default rides;
