import moment from 'moment';
import { STRINGS } from '../../constants';

const getLocationId = (location, destinations) =>
	destinations.find(({ name }) => name === location).id;

const getSecondUnit = (isBallen) =>
	isBallen ? STRINGS.BALLEN : STRINGS.PIECES_KEY;

export const prepareRidePayload = ({
	truckId,
	startLocation,
	destinations,
	cart,
	timestamp,
	rideId,
	token,
	products
}) => {
	const startLocationId = getLocationId(startLocation, destinations);
	const rideItems = cart.map(({ id, isPAL, isBallen, amount, selectedDestination }) => ({
		product: id,
		product_details: products.find((item) => item.id === id),
		item_type: isPAL ? STRINGS.PAL_KEY : getSecondUnit(isBallen),
		amount,
		end_location: getLocationId(selectedDestination, destinations)
	}));

	return {
		token,
		id: rideId,
		truck: truckId,
		start_time: timestamp || moment.utc().local().format(),
		start_location: startLocationId,
		ride_items: rideItems
	};
};
