import { createSelector } from 'reselect';
import { isSpareTruck } from '../trucks/selectors';

export const getProducts = createSelector(
	[({ products }) => products.products],
	(products) => products
);

export const getProductsList = createSelector(
	[getProducts],
	(products) => products?.map(({ product, capacity }) => ({ ...product, capacity }))
);

export const getSelectedProductId = createSelector(
	[({ products }) => products.selectedProductId],
	(selectedProduct) => selectedProduct
);

export const getActiveProduct = createSelector(
	[({ products }) => products.activeProduct],
	(activeProduct) => activeProduct
);

export const getSelectedProduct = createSelector(
	[getProductsList, getSelectedProductId],
	(productsList, productId) => productsList?.find(({ id }) => id === productId)
);

export const getProductForCorrection = createSelector(
	[getProducts, getSelectedProductId],
	(productsList, productId) => productsList?.find(({ id }) => id === productId)
);

export const getCart = createSelector(
	[({ products }) => products.cart],
	(cart) => cart
);

export const getPalletsAmount = createSelector(
	[getCart],
	(cart) => cart
		?.map(({ isPAL, amount }) => isPAL ? amount : 1)
		.reduce((acc, item) => acc + item, 0) || 0
);

export const isFullyLoaded = createSelector(
	[getCart, getProductsList, isSpareTruck],
	(cart, products, isSpare) => {
		if (isSpare) {
			return false;
		}

		const data = cart?.map((item) => ({
			capacity: products?.find(({ id }) => id === item.id).capacity,
			amount: item.amount,
			isPAL: item.isPAL
		}));

		return data?.length === 1 && data?.every(({ capacity, amount, isPAL }) =>
			isPAL && capacity === amount);
	}
);

export const getLastSelectedDestinationFromCart = createSelector(
	[getCart],
	(cart) => cart?.[cart.length - 1]?.selectedDestination
);

export const isPiecesPresent = createSelector(
	[getCart],
	(cart) => cart?.some(({ isPAL }) => !isPAL)
);

export const getExcelError = createSelector(
	[({ products }) => products?.excel?.error],
	(error) => error
);

export const getExcelSummary = createSelector(
	[({ products }) => products?.excel?.summary],
	(summary) => summary
);

export const isExcelUpdatePending = createSelector(
	[({ products }) => products?.excel?.updatePending],
	(updatePending) => updatePending
);

export const isCartEmpty = createSelector(
	[getCart],
	(cart) => !cart?.length
);

export const getLastRides = createSelector(
	[({ products }) => products.lastProducts],
	(products) => products
);
