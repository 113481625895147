import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { assoc } from 'ramda';
import {
	DateInput,
	FilterDropdownWrapper,
	FilterItemsContainer,
	FilterSelectionWrapper,
	FilterTitle
} from '../../styles/ReportsScreen/ReportsScreen';
import { ReactComponent as FilterIcon } from '../../../../icons/filter_icon.svg';
import { ReactComponent as FilterDownArrow } from '../../../../icons/filter_down_arrow.svg';
import { REPORTS, STORAGE, STRINGS } from '../../../constants';
import DropdownCustom from '../Dropdown/DropdownCustom';
import { destinationSelectors } from '../../../state/ducks/destination';
import { trucksSelectors } from '../../../state/ducks/trucks';
import { reportsActions, reportsSelectors } from '../../../state/ducks/reports';
import { getItemFromStorage, putItemIntoStorage } from '../../../utils/storage/storage';

const FilterSelection = () => {
	const dispatch = useDispatch();
	const destinations = useSelector(destinationSelectors.getAvailableStartLocations);
	const trucks = useSelector(trucksSelectors.getTrucksIds);
	const date = useSelector(reportsSelectors.getDate);
	const truck = useSelector(reportsSelectors.getTruck);
	const start = useSelector(reportsSelectors.getStart);
	const end = useSelector(reportsSelectors.getEnd);

	const updateFiltersInStorage = (key, value) => {
		const savedFilters = JSON.parse(getItemFromStorage(STORAGE.FILTERS)) || {};
		putItemIntoStorage(STORAGE.FILTERS, JSON.stringify(assoc(key, value, savedFilters)));
	};

	const handleFilterUpdated = (key, value) => {
		dispatch(reportsActions.setFilter({ [key]: value }));
		updateFiltersInStorage(key, value);
	};

	useEffect(() => {
		const savedFilters = JSON.parse(getItemFromStorage(STORAGE.FILTERS)) || {};

		Object.entries(savedFilters).forEach(([key, value]) => {
			dispatch(reportsActions.setFilter({
				[key]: value
			}));
		});
	}, [dispatch]);

	useEffect(() => {
		const { date: savedDate } = JSON.parse(getItemFromStorage(STORAGE.FILTERS)) || {};

		if (!date && !savedDate) {
			const currentDate = moment(new Date()).format('yyyy-MM-DD');
			dispatch(reportsActions.setFilter({
				[REPORTS.DATE]: currentDate
			}));
			updateFiltersInStorage(REPORTS.DATE, currentDate);
		}
	}, [date, dispatch]);

	return (
		<FilterSelectionWrapper>
			<FilterTitle>
				<FilterIcon/>
				{STRINGS.FILTER}
			</FilterTitle>
			<FilterItemsContainer bigGap marginTop>
				<FilterItemsContainer>
					<DateInput
						type={'date'}
						onChange={(event) => handleFilterUpdated(REPORTS.DATE, event.target.value)}
						value={date || ''}
					/>
					<FilterDropdownWrapper>
						<DropdownCustom
							items={[...trucks, REPORTS.NO_TRUCK_SELECTION]}
							placeholder={REPORTS.NO_TRUCK_SELECTION}
							initialValue={truck}
							updateValue={(val) => handleFilterUpdated(REPORTS.TRUCK, val)}
							emptyLabel={REPORTS.NO_TRUCK_SELECTION}
							downArrow={FilterDownArrow}
						/>
					</FilterDropdownWrapper>
				</FilterItemsContainer>
				<FilterItemsContainer>
					<FilterDropdownWrapper>
						<DropdownCustom
							items={[...(destinations || []), REPORTS.NO_START_SELECTION]}
							placeholder={REPORTS.NO_START_SELECTION}
							initialValue={start}
							updateValue={(val) => handleFilterUpdated(REPORTS.START, val)}
							emptyLabel={REPORTS.NO_START_SELECTION}
							downArrow={FilterDownArrow}
						/>
					</FilterDropdownWrapper>
					<FilterDropdownWrapper>
						<DropdownCustom
							items={[...(destinations || []), REPORTS.NO_END_SELECTION]}
							placeholder={REPORTS.NO_END_SELECTION}
							initialValue={end}
							updateValue={(val) => handleFilterUpdated(REPORTS.END, val)}
							emptyLabel={REPORTS.NO_END_SELECTION}
							downArrow={FilterDownArrow}
						/>
					</FilterDropdownWrapper>
				</FilterItemsContainer>
			</FilterItemsContainer>
		</FilterSelectionWrapper>
	);
};

export default FilterSelection;
