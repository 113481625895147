import reducer from './reducers';

import * as modalActions from './actions';
import * as modalTypes from './types';
import * as modalSelectors from './selectors';

export {
	modalActions,
	modalTypes,
	modalSelectors
};

export default reducer;
