import { createSelector } from 'reselect';
import { SEARCH } from '../../../constants';

export const getSearchValue = createSelector(
	[({ search }) => search.searchValue],
	(searchValue) => searchValue
);

export const isReplaceContext = createSelector(
	[({ search }) => search.context],
	(context) => context === SEARCH.REPLACE
);

export const getSelectionId = createSelector(
	[({ search }) => search.selectionId],
	(selectionId) => selectionId
);
