import * as TYPES from './types';
import { authTypes } from '../authentication';
import { productsTypes } from '../products';
import { ridesTypes } from '../rides';

const search = (state = {}, { type, payload }) => {
	switch (type) {
		case TYPES.SET_SEARCH_VALUE:
		case TYPES.SET_SEARCH_CONTEXT:
			return {
				...state,
				...payload
			};
		case productsTypes.CLEAR_ACTIVE_SELECTION:
		case productsTypes.CANCEL_RIDE:
		case ridesTypes.SEND_RIDE_TO_INDEXED_DB:
		case authTypes.ATTEMPT_LOGOUT:
			return {};
		default:
			return state;
	}
};

export default search;
