import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { partition, propEq } from 'ramda';
import {
	ApplyButton,
	InvoiceScreenWrapper,
	InvoiceTable,
	ResetButton,
	ResetButtonsContainer,
	VerticalDivider
} from '../../styles/InvoiceScreen/InvoiceScreen';
import InvoiceItem from './InvoiceItem';
import { MODAL, PATHS, STRINGS } from '../../../constants';
import DelayedButton from '../Common/DelayedButton';
import { modalActions, modalSelectors } from '../../../state/ducks/modal';
import { deliveriesActions, deliveriesSelectors } from '../../../state/ducks/deliveries';
import { useEnterClicked } from '../../../utils/hooks/useEnterClicked';

const InvoiceScreen = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const invoiceItems = useSelector(deliveriesSelectors.getMappedInvoice);
	const isAnySelected = useSelector(deliveriesSelectors.isAnyItemSelected);
	const modalType = useSelector(modalSelectors.getModalType);
	const isSplitInvoice = useSelector(deliveriesSelectors.isSplitInvoice);

	const partitionedItemsBySplit = useMemo(() => {
		return partition(propEq('isSplit', false), invoiceItems);
	}, [invoiceItems]);

	const handleApplyClicked = () => {
		dispatch(deliveriesActions.setFurtherWarehouseForAllSelected());
		history.push(PATHS.INVOICE_SUMMARY);
	};

	const handleSyncClicked = () => {
		dispatch(modalActions.showModal({ modalType: MODAL.ABAS_SYNC }));
	};

	const handleResetClicked = () => {
		dispatch(modalActions.showModal({ modalType: MODAL.RESET_INVOICE }));
	};

	const isFraction = (value) => !Number.isInteger(value);

	useEnterClicked(() => {
		if (!modalType) {
			handleApplyClicked();
		}
	});

	return (
		<InvoiceScreenWrapper>
			<ResetButtonsContainer>
				<ResetButton onClick={handleSyncClicked}>
					{STRINGS.SYNC_WITH_ABAS}
				</ResetButton>
				<VerticalDivider/>
				<ResetButton onClick={handleResetClicked}>
					{STRINGS.RESET}
				</ResetButton>
			</ResetButtonsContainer>
			{partitionedItemsBySplit.map((items, index) => {
				return !!items.length && (
					<InvoiceTable key={index}>
						{items.map((item) => {
							return (
								<InvoiceItem
									key={item.id}
									id={item.id}
									isSubmitted={isSplitInvoice && !item.isSplit}
									isFraction={isFraction(item.currentAmount)}
									index={item.index}
									name={item.name}
									shortName={item.shortName}
									dimensions={item.dimensions}
									articleNr={item.articleNr}
									destination={item.destination}
									unit={item.unit}
									capacity={item.maxCapacity}
									initialCapacity={item.initialCapacity}
									currentAmount={item.currentAmount}
									primaryType={item.primaryType}
									secondaryType={item.secondaryType}
									splittable={item.splittable}
								/>
							);
						})}
					</InvoiceTable>
				);
			})}
			<DelayedButton
				onClick={handleApplyClicked}
				selected={isAnySelected}
				component={ApplyButton}
			>
				{STRINGS.PROCEED_TO_SUMMARY}
			</DelayedButton>
		</InvoiceScreenWrapper>
	);
};

export default InvoiceScreen;
