import React from 'react';
import {
	ListDivider,
	ProductItemListCell,
	RowContentWrapper,
	TitleContainer
} from '../../styles/SearchScreen/SearchScreen';

const SearchResultsItem = ({
	handleProductClicked,
	id,
	nr,
	shortName,
	dimensions,
	name
}) => {
	return (
		<>
			<RowContentWrapper onClick={() => handleProductClicked(id)}>
				<ProductItemListCell col={2} row={1}>
					{nr}
				</ProductItemListCell>
				<ProductItemListCell isGrey col={3} row={1}>
					{shortName}
				</ProductItemListCell>
				<ProductItemListCell
					col={2}
					row={2}
					dangerouslySetInnerHTML={{
						__html: dimensions
					}}
				/>
				<TitleContainer>{name}</TitleContainer>
			</RowContentWrapper>
			<ListDivider/>
		</>
	);
};

export default SearchResultsItem;
