import { authOperations } from '../ducks/authentication';
import { destinationOperations } from '../ducks/destination';
import { trucksOperations } from '../ducks/trucks';
import { generalOperations } from '../ducks/general';
import { productsOperations } from '../ducks/products';
import { ridesOperations } from '../ducks/rides';
import { deliveriesOperations } from '../ducks/deliveries';
import { reportsOperations } from '../ducks/reports';

export const sagas = [
	authOperations.checkStorageForTokenSaga,
	authOperations.attemptLoginSaga,
	authOperations.attemptLogoutSaga,
	authOperations.fetchUsersSaga,
	destinationOperations.fetchDestinationsSaga,
	destinationOperations.fetchAdminDestinationsSaga,
	destinationOperations.addLocationSaga,
	destinationOperations.deleteLocationSaga,
	trucksOperations.fetchTrucksSaga,
	generalOperations.checkDarkThemeSaga,
	generalOperations.toggleDarkThemeSaga,
	productsOperations.fetchProductsSaga,
	productsOperations.fetchAllProductsSaga,
	productsOperations.fetchLastProductsSaga,
	productsOperations.uploadExcelSaga,
	productsOperations.acceptDatabaseSaga,
	productsOperations.downloadExcelSaga,
	ridesOperations.fetchRidesSaga,
	ridesOperations.deleteRideSaga,
	ridesOperations.sendRideToIndexedDbSaga,
	ridesOperations.removeFromDbSaga,
	ridesOperations.resendRidesSaga,
	deliveriesOperations.fetchInvoiceSaga,
	deliveriesOperations.resetInvoiceSaga,
	deliveriesOperations.resetInvoiceAbasSyncSaga,
	deliveriesOperations.submitInvoiceSaga,
	deliveriesOperations.bookInvoiceImmediatelySaga,
	deliveriesOperations.getInvoiceDataSaga,
	reportsOperations.watchFiltersUpdatedSaga,
	reportsOperations.sendCorrectionSaga,
	reportsOperations.downloadCorrectionReportSaga,
	reportsOperations.downloadRidesReportSaga
];
