import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
	DestinationGrid,
	DestinationGridItem,
	DestinationScreenWrapper,
	SelectDestinationWrapper,
	SelectStartWrapper,
	SelectValueLabel
} from '../../styles/DestinationScreen/DestinationScreen';
import { PATHS, STRINGS } from '../../../constants';
import Dropdown from '../Dropdown/Dropdown';
import { destinationActions, destinationSelectors } from '../../../state/ducks/destination';
import DelayedButton from '../Common/DelayedButton';

const DestinationScreen = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const destinations = useSelector(destinationSelectors.getAvailableStartLocations);
	const selectedStartLocation = useSelector(destinationSelectors.getStartLocation);
	const defaultStartLocation = useSelector(destinationSelectors.getDefaultStartLocation);

	const [startLocation, setStartLocation] = useState(selectedStartLocation || defaultStartLocation);
	const [displayedDestinations, setDisplayedDestinations] = useState([]);

	const handleDestinationSelected = (destination) => {
		dispatch(destinationActions.setDefaultDestination({
			defaultDestination: destination
		}));
		history.push(PATHS.SEARCH);
	};

	useEffect(() => {
		if (!startLocation) {
			setStartLocation(defaultStartLocation);
		}
	}, [defaultStartLocation, startLocation]);

	useEffect(() => {
		setDisplayedDestinations(
			destinations?.filter((destination) => destination !== startLocation)
		);
		dispatch(destinationActions.setStartLocation({ startLocation }));
	}, [destinations, dispatch, startLocation]);

	return (
		<DestinationScreenWrapper>
			<SelectStartWrapper>
				<SelectValueLabel>{STRINGS.START}</SelectValueLabel>
				<Dropdown
					items={destinations}
					updateValue={setStartLocation}
					initialValue={startLocation}
					origin={'top left'}
					top={'4rem'}
				/>
			</SelectStartWrapper>
			<SelectDestinationWrapper>
				<SelectValueLabel>{STRINGS.DESTINATION}</SelectValueLabel>
				<DestinationGrid>
					{displayedDestinations?.map((item) => {
						return (
							<DelayedButton
								key={item}
								onClick={() => handleDestinationSelected(item)}
								component={DestinationGridItem}
							>
								{item}
							</DelayedButton>
						);
					})}
				</DestinationGrid>
			</SelectDestinationWrapper>
		</DestinationScreenWrapper>
	);
};

export default DestinationScreen;
