import React from 'react';
import { useSelector } from 'react-redux';
import { CheckedImage, ModalContent, ModalTextSuccessfullySubmitted } from '../../styles/Modal/Modal';
import { MODAL, STRINGS } from '../../../constants';
import CheckedIcon from '../../../../icons/checked.png';
import CheckedIconDark from '../../../../icons/checked_dark.png';
import { generalSelectors } from '../../../state/ducks/general';
import { modalSelectors } from '../../../state/ducks/modal';

const ModalSuccessfullySubmitted = ({
	showModal,
	handleDismiss
}) => {
	const darkTheme = useSelector(generalSelectors.isDarkTheme);
	const modalType = useSelector(modalSelectors.getModalType);

	const getModalText = () => {
		switch (modalType) {
			case MODAL.SUCCESSFULLY_BOOKED:
				return STRINGS.SUCCESSFULLY_BOOKED_MSG;
			case MODAL.SUCCESSFUL_CORRECTION:
				return STRINGS.SUCCESSFUL_CORRECTION_MSG;
			default:
				return '';
		}
	};

	return (
		<ModalContent show={showModal} onClick={handleDismiss}>
			<ModalTextSuccessfullySubmitted dangerouslySetInnerHTML={{
				__html: getModalText()
			}}/>
			<CheckedImage src={darkTheme ? CheckedIconDark : CheckedIcon}/>
		</ModalContent>
	);
};

export default ModalSuccessfullySubmitted;
