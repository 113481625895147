import React, { useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { useSelector } from 'react-redux';
import {
	HourLabel,
	ProductsDivider,
	RideItemContainer,
	RideItemsList,
	RouteItem,
	RoutesList
} from '../../styles/ReportsScreen/ReportsScreen';
import { ReactComponent as DownArrow } from '../../../../icons/report-down-arrow.svg';
import ProductDetails from './ProductDetails';
import { reportsSelectors } from '../../../state/ducks/reports';

const RideItem = ({
	rideTime,
	rideItems,
	timeSlot
}) => {
	const allDetails = useSelector(reportsSelectors.getAllDetails) || {};
	const generalListRef = useRef(null);
	const routesListRef = useRef(null);
	const [generalListHeight, setGeneralListHeight] = useState(0);
	const [routesListHeight, setRoutesListHeight] = useState(0);
	const [expanded, setExpanded] = useState(false);

	const expandList = () => setExpanded((prev) => !prev);

	useEffect(() => {
		setGeneralListHeight(generalListRef.current.getBoundingClientRect().height);
		setRoutesListHeight(routesListRef.current.getBoundingClientRect().height);
	}, [generalListRef]);

	useEffect(() => {
		const { toggled } = allDetails;
		const [, value] = Object.entries(allDetails).find(([key]) => key === timeSlot) || [];

		if (toggled === timeSlot) {
			setExpanded(value);
		}
	}, [allDetails, timeSlot]);

	return (
		<RideItemContainer
			expanded={expanded}
			generalHeight={generalListHeight}
			routesHeight={routesListHeight}
			min={66}
			moveTop={rideItems.length - 1}
		>
			<HourLabel>
				{moment(rideTime).format('HH:mm').toLocaleString()}
			</HourLabel>
			<RoutesList expanded={expanded} ref={routesListRef}>
				{rideItems.map((item) => {
					return <RouteItem key={uuidv4()}>
						{item.start_location} &#8594; {item.end_location}
					</RouteItem>;
				})}
			</RoutesList>
			<DownArrow onClick={expandList}/>
			<RideItemsList
				expanded={expanded}
				moveTop={rideItems.length - 1}
				ref={generalListRef}
			>
				{rideItems.map((item) => {
					return <React.Fragment key={uuidv4()}>
						<div>
							{item.start_location} &#8594; {item.end_location}
						</div>
						<ProductsDivider/>
						{item.products.map((product) => {
							return <ProductDetails
								key={uuidv4()}
								name={product.name}
								shortName={product.name_short}
								amount={product.amount}
								articleNr={product.article_nr}
								dimensions={`${product.height}x${product.length}x${product.width}`}
								unit={product.item_type}
							/>;
						})}
					</React.Fragment>;
				})}
			</RideItemsList>
		</RideItemContainer>
	);
};

export default RideItem;
