export const SET_FILTER = 'SET_FILTER';
export const SET_CORRECTION = 'SET_CORRECTION';
export const SET_REPORT = 'SET_REPORT';
export const SEND_CORRECTION = 'SEND_CORRECTION';
export const CLEAR_CORRECTION = 'CLEAR_CORRECTION';
export const TOGGLE_ALL_DETAILS = 'TOGGLE_ALL_DETAILS';
export const SET_CORRECTION_REPORT = 'SET_CORRECTION_REPORT';
export const DOWNLOAD_CORRECTION_REPORT = 'DOWNLOAD_CORRECTION_REPORT';
export const SET_RIDES_REPORT = 'SET_RIDES_REPORT';
export const DOWNLOAD_RIDES_REPORT = 'DOWNLOAD_RIDES_REPORT';
export const CLEAR_REPORT_FILTERS = 'CLEAR_REPORT_FILTERS';
