import React, { useEffect, useState } from 'react';
import {
	DropdownButton,
	DropdownIcon,
	DropdownList,
	DropdownTitle,
	DropdownWrapper,
	SelectButton
} from '../../styles/Dropdown/Dropdown';
import { ReactComponent as DownArrow } from '../../../../icons/down-arrow.svg';
import { useOutsideClick } from './useOutsideClick';

const Dropdown = ({
	placeholder,
	items,
	updateValue,
	initialValue,
	red = false,
	thin = false,
	moveTop = false,
	top = '',
	right = '',
	origin = ''
}) => {
	const [isOpen, setIsOpen] = useState(false);
	const [value, setValue] = useState(initialValue || '');

	const toggleList = () => {
		setIsOpen((prev) => !prev);
	};

	const dropdownRef = useOutsideClick(() => {
		if (isOpen) {
			toggleList();
		}
	});

	const selectItem = (item) => {
		setValue(item);
		updateValue(item);
		toggleList();
	};

	useEffect(() => {
		setValue(initialValue);
	}, [initialValue]);

	return (
		<DropdownWrapper
			large={isOpen}
			origin={origin}
			top={top}
			right={right}
			ref={dropdownRef}
		>
			<DropdownButton
				onClick={toggleList}
				red={red}
				thin={thin && !isOpen}
				moveTop={moveTop}
			>
				<DropdownTitle open={isOpen}>
					{value || placeholder}
				</DropdownTitle>
				<DropdownIcon><DownArrow/></DropdownIcon>
			</DropdownButton>
			<DropdownList moveTop={moveTop} isOpen={isOpen}>
				{items?.map((item) => (
					<SelectButton
						key={item}
						onClick={() => selectItem(item)}
						selected={item === value}
						moveTop={moveTop}
					>
						{item}
					</SelectButton>
				))}
			</DropdownList>
		</DropdownWrapper>
	);
};

export default Dropdown;
