import styled from 'styled-components';
import { FilterDropdownWrapper } from '../ReportsScreen/ReportsScreen';
import { DropdownSmallList } from '../Dropdown/Dropdown';

export const FiltersContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
`;

export const DatePickersContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 4rem;
`;

export const DropdownsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 4rem;
`;

export const DataDropdownWrapper = styled(FilterDropdownWrapper)`
  width: 100%;

  ${DropdownSmallList} {
    max-height: 24rem;
  }
`;
