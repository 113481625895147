import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ProductLayout from './ProductLayout';
import { productsSelectors } from '../../../state/ducks/products';
import { PATHS, REPORTS, STRINGS } from '../../../constants';
import { reportsActions } from '../../../state/ducks/reports';

const ProductCorrectionScreen = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const {
		name,
		article_nr: nr,
		is_ballen: isBallen,
		length,
		width,
		height
	} = useSelector(productsSelectors.getProductForCorrection) || {};
	const secondUnit = isBallen ? STRINGS.BALLEN : STRINGS.PIECES;
	const isMobileDevice = navigator.userAgent.includes('Android');
	const [amount, setAmount] = useState(1);
	const [isPAL, setIsPAL] = useState(true);

	const increaseAmount = () => {
		setAmount((prev) => prev + 1);
	};

	const decreaseAmount = () => {
		setAmount((prev) => prev > 1 ? prev - 1 : prev);
		document.activeElement.blur();
	};

	const handleInputChange = (event) => {
		const { value } = event.target;

		if (value <= 999) {
			setAmount(+value);
		}
	};

	const togglePal = () => setIsPAL((prev) => !prev);

	const handleProductApproved = (event) => {
		event.preventDefault();
		event.stopPropagation();
		event.nativeEvent.stopImmediatePropagation();

		dispatch(reportsActions.setCorrection({
			[REPORTS.CORRECTION_UNIT]: isPAL ? STRINGS.PAL_KEY : secondUnit,
			[REPORTS.CORRECTION_AMOUNT]: amount,
			[REPORTS.CORRECTION_PRODUCT_NR]: nr
		}));
		history.push(PATHS.CORRECTION);
	};

	return <ProductLayout
		name={name}
		nr={nr}
		length={length}
		width={width}
		height={height}
		amount={amount}
		secondUnit={secondUnit}
		isPAL={isPAL}
		isMobileDevice={isMobileDevice}
		isCorrectionContext={true}
		isDesktop={!isMobileDevice}
		hideDropdown={true}
		valid={amount > 0}
		suppressTouch={true}
		increaseAmount={increaseAmount}
		decreaseAmount={decreaseAmount}
		togglePal={togglePal}
		handleInputChange={handleInputChange}
		handleProductApproved={handleProductApproved}
		handleFocus={(event) => event.target.select()}
	/>;
};

export default ProductCorrectionScreen;
