import axios from 'axios';
import { API } from '../constants';

export const login = async ({ username, password }) => {
	return axios.post(`${API.DOMAIN}${API.LOGIN}`, {
		username,
		password
	}).then((res) => res)
		.catch((err) => err);
};

export const loginCheck = async (token) => {
	return axios.get(`${API.DOMAIN}${API.LOGIN_CHECK}`, {
		headers: {
			'Authorization': `Token ${token}`
		}
	}).then((res) => res)
		.catch((err) => err);
};

export const logout = async (token) => {
	return axios.get(`${API.DOMAIN}${API.LOGOUT}`, {
		headers: {
			'Authorization': `Token ${token}`
		}
	}).then((res) => res)
		.catch((err) => err);
};

export const trucks = async () => {
	return axios.get(`${API.DOMAIN}${API.TRUCKS}`)
		.then((res) => res)
		.catch((err) => err);
};

export const locations = async (token) => {
	return axios.get(`${API.DOMAIN}${API.LOCATIONS}`, {
		headers: {
			'Authorization': `Token ${token}`
		}
	}).then((res) => res)
		.catch((err) => err);
};

export const locationsAdmin = async (token) => {
	return axios.get(`${API.DOMAIN}${API.LOCATIONS_ADMIN}`, {
		headers: {
			'Authorization': `Token ${token}`
		}
	}).then((res) => res)
		.catch((err) => err);
};

export const addLocation = async (token, { name, nr }) => {
	return axios.post(`${API.DOMAIN}${API.LOCATIONS}`,
		{ name, location_nr: nr },
		{
			headers: {
				'Authorization': `Token ${token}`
			}
		}).then((res) => res)
		.catch((err) => err);
};

export const deleteLocation = async (token, { id }) => {
	return axios.delete(`${API.DOMAIN}${API.LOCATIONS}${id}/`, {
		headers: {
			'Authorization': `Token ${token}`
		}
	}).then((res) => res)
		.catch((err) => err);
};

export const products = async (token, truckId) => {
	return axios.get(`${API.DOMAIN}${API.TRUCKS}${truckId}${API.PRODUCTS}`, {
		headers: {
			'Authorization': `Token ${token}`
		}
	}).then((res) => res)
		.catch((err) => err);
};

export const allProducts = async (token) => {
	return axios.get(`${API.DOMAIN}${API.ALL_PRODUCTS}`, {
		headers: {
			'Authorization': `Token ${token}`
		}
	}).then((res) => res)
		.catch((err) => err);
};

export const lastProducts = async (token, truckId) => {
	return axios.get(`${API.DOMAIN}${API.TRUCKS}${truckId}${API.LAST_5}`, {
		headers: {
			'Authorization': `Token ${token}`
		}
	}).then((res) => res)
		.catch((err) => err);
};

export const rides = async (token) => {
	return axios.get(`${API.DOMAIN}${API.RIDES}`, {
		headers: {
			'Authorization': `Token ${token}`
		}
	}).then((res) => res)
		.catch((err) => err);
};

export const database = async (token, data) => {
	return axios.post(`${API.DOMAIN}${API.DATABASE}`, data, {
		headers: {
			'Authorization': `Token ${token}`
		}
	}).then((res) => res)
		.catch((err) => err);
};

export const downloadExcel = async (token) => {
	return axios.get(`${API.DOMAIN}${API.DOWNLOAD_EXCEL}`, {
		headers: {
			'Authorization': `Token ${token}`
		},
		responseType: 'arraybuffer'
	}).then((res) => res)
		.catch((err) => err);
};

export const acceptDatabase = async (token) => {
	return axios.get(`${API.DOMAIN}${API.ACCEPT_DATABASE}`, {
		headers: {
			'Authorization': `Token ${token}`
		}
	}).then((res) => res)
		.catch((err) => err);
};

export const invoice = async (token, data) => {
	return axios.post(`${API.DOMAIN}${API.GET_INVOICE}`, data, {
		headers: {
			'Authorization': `Token ${token}`
		},
		timeout: 30000
	}).then((res) => res)
		.catch(({ response }) => response);
};

export const syncInvoice = async (token, data) => {
	return axios.post(`${API.DOMAIN}${API.ABAS_SYNC}`, data, {
		headers: {
			'Authorization': `Token ${token}`
		}
	}).then((res) => res)
		.catch((err) => err);
};

export const submitInvoice = async (token, data) => {
	return axios.post(`${API.DOMAIN}${API.SUBMIT_INVOICE}`, data, {
		headers: {
			'Authorization': `Token ${token}`
		}
	}).then((res) => res)
		.catch(({ response }) => response);
};

export const splitInvoice = async (token, data) => {
	return axios.post(`${API.DOMAIN}${API.SPLIT_INVOICE}`, data, {
		headers: {
			'Authorization': `Token ${token}`
		}
	}).then((res) => res)
		.catch(({ response }) => response);
};

export const gutexUsers = async () => {
	return axios.get(`${API.DOMAIN}${API.GUTEX_USERS}`)
		.then((res) => res)
		.catch((err) => err);
};

export const ridesReport = async (token, query) => {
	return axios.get(`${API.DOMAIN}${API.RIDES_REPORT}${query}`, {
		headers: {
			'Authorization': `Token ${token}`
		}
	})
		.then((res) => res)
		.catch((err) => err);
};

export const sendCorrection = async (token, data) => {
	return axios.post(`${API.DOMAIN}${API.SEND_CORRECTION}`, data, {
		headers: {
			'Authorization': `Token ${token}`
		}
	}).then((res) => res)
		.catch((err) => err);
};

export const sendDoubleCorrection = async (token, data) => {
	return axios.post(`${API.DOMAIN}${API.SEND_DOUBLE_CORRECTION}`, data, {
		headers: {
			'Authorization': `Token ${token}`
		}
	}).then((res) => res)
		.catch((err) => err);
};

export const correctionExcelReport = async (token, query) => {
	return axios.get(`${API.DOMAIN}${API.CORRECTION_EXCEL_REPORT}${query}`, {
		headers: {
			'Authorization': `Token ${token}`
		},
		responseType: 'blob'
	})
		.then((res) => res)
		.catch((err) => err);
};

export const getInvoiceInfo = async (token, invoiceNumber) => {
	return axios.get(`${API.DOMAIN}${API.CHECK_INVOICE}?invoice_number=${invoiceNumber}`, {
		headers: {
			'Authorization': `Token ${token}`
		}
	})
		.then((res) => res)
		.catch((err) => err);
};

export const ridesExcelReport = async (token, query) => {
	return axios.get(`${API.DOMAIN}${API.RIDES_EXCEL_REPORT}?${query}`, {
		headers: {
			'Authorization': `Token ${token}`
		},
		responseType: 'blob'
	})
		.then((res) => res)
		.catch((err) => err);
};
