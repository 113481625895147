import { setupReadyDatabase } from './setupDatabase';

class RideReadyHandler {
	constructor() {
		this.database = setupReadyDatabase();
	}

	getAllRides() {
		return this.database.rides.toArray();
	}

	static getRidesIds(rides) {
		return rides.map(({ dbId }) => dbId);
	}

	async addRide(ride) {
		const rides = await this.getAllRides();
		const duplicate = rides.find(({ id }) => id === ride.id);

		if (duplicate) {
			await this.deleteById(duplicate.dbId);
		}

		this.database.rides.add(ride);
	}

	async deleteAllRides() {
		const rides = await this.database.rides.toArray();
		const ids = RideReadyHandler.getRidesIds(rides);
		await this.database.rides.bulkDelete(ids);
	}

	async deleteByRideId(rideId) {
		const rides = await this.database.rides.toArray();
		const ride = rides.find(({ id }) => id === rideId);

		if (ride) {
			await this.deleteById(ride.dbId);
		}
	}

	async deleteById(id) {
		await this.database.rides.bulkDelete([id]);
	}
}

export default RideReadyHandler;
