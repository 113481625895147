import * as TYPES from './types';

export const setToken = (payload) => ({
	type: TYPES.SET_TOKEN,
	payload
});

export const setAdminToken = (payload) => ({
	type: TYPES.SET_ADMIN_TOKEN,
	payload
});

export const checkStorageForToken = () => ({
	type: TYPES.CHECK_STORAGE_FOR_TOKEN
});

export const attemptLogin = (payload) => ({
	type: TYPES.ATTEMPT_LOGIN,
	payload
});

export const setPasswordValidity = (payload) => ({
	type: TYPES.SET_PASSWORD_VALIDITY,
	payload
});

export const setUsername = (payload) => ({
	type: TYPES.SET_USERNAME,
	payload
});

export const attemptLogout = (payload) => ({
	type: TYPES.ATTEMPT_LOGOUT,
	payload
});

export const fetchGutexUsers = () => ({
	type: TYPES.FETCH_GUTEX_USERS
});

export const setGutexUsers = (payload) => ({
	type: TYPES.SET_GUTEX_USERS,
	payload
});

export const setTruckTokens = (payload) => ({
	type: TYPES.SET_TRUCK_TOKENS,
	payload
});

export const setTruckId = (payload) => ({
	type: TYPES.SET_TRUCK_ID,
	payload
});
