import reducer from './reducers';

import * as ridesActions from './actions';
import * as ridesTypes from './types';
import * as ridesSelectors from './selectors';
import * as ridesOperations from './operations';

export {
	ridesActions,
	ridesTypes,
	ridesSelectors,
	ridesOperations
};

export default reducer;
