import styled from 'styled-components';

export const DestinationScreenWrapper = styled.div`
	position: relative;
	display: flex;
	width: 100%;
	min-height: 100vh;
	flex-direction: column;
	align-items: center;
	background-color: ${({ theme }) => theme.colors.primary};
`;

export const SelectStartWrapper = styled.div`
	display: flex;
	width: 100%;
	align-items: center;
	margin-top: 15rem;
	padding: 0 3.5rem;
`;

export const SelectValueLabel = styled.div`
	font-family: Roboto, sans-serif;
	font-weight: 400;
	font-size: 4.2rem;
	color: black;
	margin-right: 2.5rem;
`;

export const SelectDestinationWrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 0 3.5rem 3.5rem;
	margin-top: 5rem;
`;

export const DestinationGrid = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(55rem, 1fr));
	grid-row-gap: 2.2rem;
	grid-column-gap: 5.8rem;
	width: 100%;
	margin-top: 2rem;
`;

export const DestinationGridItem = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 15rem;
	background-color: ${({ theme }) => theme.colors.primaryRed};
	color: ${({ theme }) => theme.colors.fontWhite};
	border-radius: 3px;
	font-size: 4.8rem;
	font-family: Roboto, sans-serif;
	font-weight: 500;
	border: none;
	transition: all 0.2s;
	
	&:hover,
	&:active,
	&:focus {
		outline: 0;
		background-color: ${({ theme }) => theme.colors.primaryRedHover};
	}
`;
