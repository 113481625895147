import React from 'react';
import { useSelector } from 'react-redux';
import { ButtonGoBack, ButtonsContainerCustom, ModalContent, ModalText } from '../../styles/Modal/Modal';
import DelayedButton from '../Common/DelayedButton';
import { MODAL, STRINGS } from '../../../constants';
import { modalSelectors } from '../../../state/ducks/modal';

const ModalInvoice = ({
	handleDismiss,
	showModal
}) => {
	const modalType = useSelector(modalSelectors.getModalType);

	const getModalText = () => {
		switch (modalType) {
			case MODAL.INVOICE_ALREADY_USED:
				return STRINGS.INVOICE_ALREADY_USED_MSG;
			case MODAL.INVOICE_NOT_EXISTS:
				return STRINGS.INVOICE_NOT_EXISTS;
			case MODAL.INVOICE_IN_USE:
				return STRINGS.INVOICE_IN_USE_MSG;
			case MODAL.INVOICE_QUANTITY_DIFFERS:
				return STRINGS.INVOICE_QUANTITY_DIFFERS_MSG;
			default:
				return '';
		}
	};

	const getDismissText = () => {
		switch (modalType) {
			case MODAL.INVOICE_ALREADY_USED:
			case MODAL.INVOICE_NOT_EXISTS:
				return STRINGS.GO_BACK;
			case MODAL.INVOICE_IN_USE:
			case MODAL.INVOICE_QUANTITY_DIFFERS:
				return STRINGS.MODIFICATION_REJECTED;
			default:
				return '';
		}
	};

	return (
		<ModalContent show={showModal} increaseWidth={10}>
			<ModalText dangerouslySetInnerHTML={{
				__html: getModalText()
			}}/>
			<ButtonsContainerCustom marginTop={4}>
				<DelayedButton onClick={handleDismiss} component={ButtonGoBack}>
					{getDismissText()}
				</DelayedButton>
			</ButtonsContainerCustom>
		</ModalContent>
	);
};

export default ModalInvoice;
