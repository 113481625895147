import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
	Article,
	ArticlesLabel,
	ArticleWrapper,
	DateLabel,
	DeleteRideButton,
	Destination,
	DestinationsLabel,
	DetailDot,
	DetailsLabel,
	Divider,
	DotWrapper,
	EditRideButton,
	LabelBold,
	PreviousRidesListItem,
	StartContainer,
	TimeLabel
} from '../../styles/PreviousRidesScreen/PreviousRidesScreen';
import { STRINGS } from '../../../constants';
import { ReactComponent as EditIcon } from '../../../../icons/edit.svg';
import { EditRideLabel } from '../../styles/SummaryScreen/SummaryScreen';
import { ReactComponent as DeleteIcon } from '../../../../icons/delete.svg';
import DelayedButton from '../Common/DelayedButton';

const PreviousRidesItem = ({
	hour,
	date,
	item,
	destinations,
	articles,
	offline,
	handleEditRideClicked,
	handleDeleteRideClicked
}) => {
	const withNbsp = (str) => str.replace(/ /g, '\u00a0');

	const getSecondUnit = (type) => type === STRINGS.BALLEN ? STRINGS.BALLEN : STRINGS.PIECES;

	const getSizeText = (article) =>
		`<strong>${article.height}</strong>x${article.length}x${article.width}`;

	const getTypeText = (article) =>
		`<strong>${article.amount} ${article.type === STRINGS.PAL_KEY ? STRINGS.PAL : getSecondUnit(article.type)}</strong>`;

	const getArticleText = (article) =>
		`${article.name} </br> ${withNbsp(`${getSizeText(article)} - ${getTypeText(article)}`)}`;

	return (
		<>
			<PreviousRidesListItem offline={offline}>
				<TimeLabel>{`${hour} ${STRINGS.HOUR}`}</TimeLabel>
				<DateLabel>
					{date}
					{offline && <LabelBold addMargin>{STRINGS.OFFLINE}</LabelBold>}
				</DateLabel>
				<StartContainer>
					<LabelBold>{STRINGS.START}</LabelBold>
					<DetailsLabel>{item.start_location?.name}</DetailsLabel>
				</StartContainer>
				<DestinationsLabel>{STRINGS.DESTINATIONS}</DestinationsLabel>
				<ArticlesLabel>{STRINGS.ARTICLES}</ArticlesLabel>
				{destinations?.map((destination) => (
					<Destination key={uuidv4()}>
						<DetailDot/>
						{destination}
					</Destination>
				))}
				{articles?.map((article, index) => (
					<ArticleWrapper key={uuidv4()} row={index}>
						<DotWrapper><DetailDot/></DotWrapper>
						<Article dangerouslySetInnerHTML={{
							__html: getArticleText(article)
						}}>
						</Article>
					</ArticleWrapper>
				))}
				<DelayedButton onClick={handleEditRideClicked} component={EditRideButton}>
					<EditIcon/>
					<EditRideLabel>{STRINGS.EDIT_RIDE}</EditRideLabel>
				</DelayedButton>
				<DelayedButton onClick={handleDeleteRideClicked} component={DeleteRideButton}>
					<DeleteIcon/>
				</DelayedButton>
			</PreviousRidesListItem>
			<Divider/>
		</>
	);
};

export default PreviousRidesItem;
