import produce from 'immer';
import * as TYPES from './types';
import { authTypes } from '../authentication';
import { ridesTypes } from '../rides';

const products = (state = {}, { type, payload }) => {
	switch (type) {
		case TYPES.SET_PRODUCTS:
		case TYPES.SET_LAST_PRODUCTS:
		case TYPES.SET_SELECTED_PRODUCT_ID:
		case TYPES.SET_ACTIVE_PRODUCT:
		case TYPES.SET_CART:
			return {
				...state,
				...payload
			};
		case TYPES.ADD_PRODUCT_TO_CART:
			return produce(state, (draft) => {
				if (draft.cart) {
					draft.cart.push(payload);
				} else {
					draft.cart = [payload];
				}
			});
		case TYPES.EDIT_PRODUCT_IN_CART:
			return produce(state, (draft) => {
				const index = draft.cart.findIndex(
					({ selectionId }) => selectionId === payload.selectionId);
				draft.cart[index] = payload;
			});
		case TYPES.CLEAR_ACTIVE_SELECTION:
			return {
				...state,
				selectedProductId: null,
				activeProduct: null
			};
		case TYPES.DELETE_PRODUCT_FROM_CART:
			return produce(state, (draft) => {
				const index = draft.cart.findIndex(
					({ selectionId }) => selectionId === payload.selectionId);
				draft.cart.splice(index, 1);
			});
		case TYPES.CANCEL_RIDE:
		case ridesTypes.SEND_RIDE_TO_INDEXED_DB:
			return {
				...state,
				selectedProductId: null,
				activeProduct: null,
				cart: []
			};
		case TYPES.SET_EXCEL_CHANGES:
			return produce(state, (draft) => {
				draft.excel = payload;
			});
		case TYPES.TOGGLE_EXCEL_UPDATE_PENDING:
			return produce(state, (draft) => {
				const prev = draft?.excel?.updatePending || false;

				if (!draft.excel) {
					draft.excel = { updatePending: !prev };
				} else {
					draft.excel.updatePending = !prev;
				}
			});
		case TYPES.REPLACE_PRODUCT:
			return produce(state, (draft) => {
				const index = draft.cart.findIndex(({ selectionId }) =>
					selectionId === payload.selectionId);

				draft.cart[index].id = payload.id;
			});
		case authTypes.ATTEMPT_LOGOUT:
			return {};
		default:
			return state;
	}
};

export default products;
