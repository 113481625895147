import styled from 'styled-components';
import { DropdownButtonSmall, DropdownSmallList, DropdownSmallTitle, DropdownSmallWrapper } from '../Dropdown/Dropdown';

export const MenuScreenWrapper = styled.div`
	position: relative;
	height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: ${({ theme }) => theme.colors.primary};
`;

export const SelectOptionContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(${({ columns }) => columns || 2}, 1fr);
	grid-column-gap: 9rem;
	grid-row-gap: ${({ smallGap }) => smallGap ? 1.5 : 2.5}rem;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`;

export const OptionContainer = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 43rem;
	height: 35rem;
	border: 7px solid black;
	border-radius: 6px;
	background-color: ${({ theme }) => theme.colors.primaryWhite};
	
	&:after {
		transition: all 0.2s;
		background-color: transparent;
	}
	
	&:after,
	&:hover:after,
	&:focus:after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 10;
	}
`;

export const EnterRideContainer = styled(OptionContainer)`
	position: relative;
	border: 7px solid ${({ theme }) => theme.colors.primaryGreen};
	
	&:hover:after,
	&:focus:after {
		background-color: ${({ theme }) => theme.colors.optionGreenHover};
	}
`;

export const EditRideContainer = styled(OptionContainer)`
	border: 7px solid ${({ theme }) => theme.colors.primaryRed};	
	
	&:hover:after,
	&:focus:after {
		background-color: ${({ theme }) => theme.colors.optionRedHover};
	}
`;

export const OptionIcon = styled.img`
	position: absolute;
	top: 4rem;
	width: 30rem;
`;

export const NoteIcon = styled(OptionIcon)`
	top: 1.5rem;
	left: 10rem;
	width: 24rem;
`;

export const WarehouseIcon = styled(OptionIcon)`
	top: 1rem;
	width: 22rem;
`;

export const WarehouseRideContainer = styled(OptionContainer)`
	border: 7px solid ${({ theme }) => theme.colors.greyMedium2};	
	height: 32rem;
	
	&:hover:after,
	&:focus:after {
		background-color: ${({ theme }) => theme.colors.optionGrey2Hover};
	}
`;

export const ClientRideContainer = styled(OptionContainer)`
	border: 7px solid ${({ theme }) => theme.colors.primaryBrown};	
	height: 32rem;
	
	&:hover:after,
	&:focus:after {
		background-color: ${({ theme }) => theme.colors.optionBrownHover};
	}
	
	${OptionIcon} {
		width: 18rem;	
	}
`;

export const OptionSvg = styled.div`
	position: absolute;
	top: 5.2rem;
	transform: translateX(1.5rem);
	svg {
		fill: ${({ theme }) => theme.colors.primaryRed};
		width: 16rem;
		height: 16rem;
	}
`;

export const OptionText = styled.div`
	position: absolute;
	bottom: 4.2rem;
	font-size: 4.2rem;
	font-family: Roboto, sans-serif;
	font-weight: 700;
	width: 100%;
	text-align: center;
`;

export const OptionTextEnter = styled(OptionText)`
	color: ${({ theme }) => theme.colors.fontGreen};
`;

export const OptionTextEdit = styled(OptionText)`
	color: ${({ theme }) => theme.colors.fontRed};
`;

export const ClientRideText = styled(OptionText)`
	color: ${({ theme }) => theme.colors.primaryBrown};
	transform: translateY(2rem);
`;

export const WarehouseRideText = styled(OptionText)`
	color: ${({ theme }) => theme.colors.greyMedium2};
	transform: translateY(2rem);
`;

export const DropdownCustomWrapper = styled.div`
	${DropdownSmallWrapper} {
		width: 100%;
		margin-bottom: 0;
	}
	
	${DropdownButtonSmall} {
		border: 5px solid ${({ theme, invalid }) =>
	invalid ? theme.colors.primaryRed : 'currentColor'};
		background-color: ${({ theme, invalid }) => invalid && theme.colors.primaryRedHover};
		border-radius: 6px;
		height: 8rem;
		color: inherit;
		
		${DropdownSmallTitle} {
			margin-top: 0;
		}
		
		svg {
			margin-top: 0;
			width: 5rem;
			fill: currentColor;
		}
		
		& > * {
			color: inherit;
			font-size: 4.2rem;
		}
	}
	
	${DropdownSmallList} {
		border: 5px solid currentColor;
		border-top: 5px solid ${({ theme, invalid }) =>
	invalid ? theme.colors.primaryRed : 'currentColor'};
		border-bottom-left-radius: 6px;
		border-bottom-right-radius: 6px;
		transform: translateY(2.7rem);
		color: inherit;
		max-height: 33rem;
		
		& > * {
			font-size: 4.2rem;
			color: inherit;
		}
	}
`;

export const DropdownWarehouse = styled(DropdownCustomWrapper)`
	color: ${({ theme }) => theme.colors.greyMedium2};
`;

export const DropdownClients = styled(DropdownCustomWrapper)`
	color: ${({ theme }) => theme.colors.primaryBrown};
`;

export const AdminButton = styled.button`
	width: 100%;
	height: 9rem;
	border: 6px solid black;
	border-radius: 6px;
	background-color: ${({ theme }) => theme.colors.primaryWhite};
	font-family: Roboto, sans-serif;
	font-size: 3.2rem;
	font-weight: 700;
	
	&:focus,
	&:hover {
		transition: all .2s;
		background-color: ${({ theme }) => theme.colors.primaryBlackHover};
	}
`;
