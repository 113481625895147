import React, { useCallback, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { productsActions, productsSelectors } from '../../../state/ducks/products';
import { MODAL, PATHS, STRINGS } from '../../../constants';
import { destinationSelectors } from '../../../state/ducks/destination';
import { modalActions } from '../../../state/ducks/modal';
import { trucksSelectors } from '../../../state/ducks/trucks';
import ProductLayout from './ProductLayout';

const ProductScreen = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const {
		id,
		name,
		article_nr: nr,
		is_ballen: isBallen,
		capacity,
		length,
		width,
		height
	} = useSelector(productsSelectors.getSelectedProduct) || {};
	const activeProduct = useSelector(productsSelectors.getActiveProduct);
	const defaultDestination = useSelector(destinationSelectors.getDefaultDestination);
	const lastDestination = useSelector(productsSelectors.getLastSelectedDestinationFromCart);
	const destinations = useSelector(destinationSelectors.getAvailableEndLocations);
	const products = useSelector(productsSelectors.getProducts);
	const cart = useSelector(productsSelectors.getCart) || [];
	const palletsAmount = useSelector(productsSelectors.getPalletsAmount);
	const isSpare = useSelector(trucksSelectors.isSpareTruck);
	const [disabled, setDisabled] = useState(false);
	const secondUnit = isBallen ? STRINGS.BALLEN : STRINGS.PIECES;

	const isMobileDevice = navigator.userAgent.includes('Android');

	const getInitialAmount = () => {
		const prodId = cart?.[0]?.id || '';
		const firstProdCapacity = products?.find(({ product }) => product.id === prodId)?.capacity;

		if (palletsAmount >= firstProdCapacity) return 1;
		if (firstProdCapacity >= 0) return (firstProdCapacity - palletsAmount) || 1;
		return capacity;
	};

	const [selectedDestination, setSelectedDestination] = useState(
		activeProduct?.selectedDestination ||
		defaultDestination ||
		lastDestination ||
		destinations[0]
	);
	const [amount, setAmount] = useState(activeProduct?.amount || getInitialAmount());
	const [isPAL, setIsPAL] = useState(activeProduct ? activeProduct.isPAL : true);
	const [isLimit, setIsLimit] = useState(true);
	const [valid, setValid] = useState(true);

	useEffect(() => {
		if (isPAL && !isSpare) {
			setIsLimit(amount >= capacity);
		} else {
			setIsLimit(false);
		}
	}, [amount, capacity, isPAL, isSpare]);

	const togglePal = () => {
		if (isPAL) {
			setAmount(1);
		} else {
			setAmount(getInitialAmount());
		}
		setIsPAL((prev) => !prev);
	};

	const increaseAmount = () => {
		setAmount((prev) => prev < capacity || !isPAL || isSpare ? prev + 1 : prev);
		document.activeElement.blur();
	};

	const decreaseAmount = () => {
		setAmount((prev) => prev > 1 ? prev - 1 : prev);
		document.activeElement.blur();
	};

	const handleInputChange = (event) => {
		const { value } = event.target;

		if (value <= 999) {
			setAmount(+value);
		}
	};

	const handleFocus = (event) => {
		event.target.select();
	};

	const prepareProductData = useCallback(() => ({
		id,
		selectionId: activeProduct?.selectionId || uuidv4(),
		isPAL,
		isBallen,
		amount,
		selectedDestination
	}), [activeProduct?.selectionId, amount, id, isBallen, isPAL, selectedDestination]);

	const handleProductApproved = useCallback(() => {
		if (disabled) return;
		setDisabled(true);

		setTimeout(() => {
			const productData = prepareProductData();
			dispatch(productsActions.setActiveProduct({ activeProduct: productData }));
			if (activeProduct) {
				dispatch(productsActions.editProductInCart(productData));
			} else {
				dispatch(productsActions.addProductToCart(productData));
			}
			history.push(PATHS.SUMMARY);
		}, 200);
	}, [activeProduct, disabled, dispatch, history, prepareProductData]);

	const handleEnterClicked = useCallback((event) => {
		if (event.key === 'Enter') {
			document.activeElement.blur();
		}
	}, []);

	useEffect(() => {
		if (amount >= 1 && isSpare) {
			setValid(true);
		} else if (amount >= 1 && amount <= capacity && isPAL) {
			setValid(true);
		} else if (amount >= 1 && amount <= 999 && !isPAL) {
			setValid(true);
		} else {
			setValid(false);
		}
	}, [amount, capacity, isPAL, isSpare]);

	useEffect(() => {
		window.addEventListener('keyup', handleEnterClicked);
		return () => window.removeEventListener('keyup', handleEnterClicked);
	}, [handleEnterClicked]);

	useEffect(() => {
		if (isPAL && amount > capacity && !isSpare) {
			dispatch(modalActions.showModal({ modalType: MODAL.TOO_MUCH_PALLETS }));
		}
	}, [amount, capacity, dispatch, isPAL, isSpare]);

	return (
		<ProductLayout
			name={name}
			width={width}
			length={length}
			height={height}
			amount={amount}
			nr={nr}
			destinations={destinations}
			isMobileDevice={isMobileDevice}
			isLimit={isLimit}
			isPAL={isPAL}
			secondUnit={secondUnit}
			valid={valid}
			togglePal={togglePal}
			decreaseAmount={decreaseAmount}
			increaseAmount={increaseAmount}
			handleFocus={handleFocus}
			handleInputChange={handleInputChange}
			handleProductApproved={handleProductApproved}
			selectedDestination={selectedDestination}
			setSelectedDestination={setSelectedDestination}
		/>
	);
};

export default ProductScreen;
