import { createSelector } from 'reselect';
import { GENERAL } from '../../../constants';

export const isDarkTheme = createSelector(
	[({ general }) => general.darkTheme],
	(darkTheme) => darkTheme
);

export const isQuickMode = createSelector(
	[({ general }) => general.quickMode],
	(quickMode) => quickMode
);

export const isInitialSearch = createSelector(
	[({ general }) => general.initialSearch],
	(initialSearch) => initialSearch
);

export const getRideContext = createSelector(
	[({ general }) => general.rideContext],
	(rideContext) => rideContext
);

export const getDbType = createSelector(
	[({ general }) => general.dbType],
	(dbType) => dbType
);

export const getDbId = createSelector(
	[({ general }) => general.dbId],
	(dbId) => dbId
);

export const getRideId = createSelector(
	[({ general }) => general.rideId],
	(rideId) => rideId
);

export const isEditRide = createSelector(
	[getRideContext],
	(context) => context === GENERAL.CONTEXT_EDIT
);

export const isReportsContext = createSelector(
	[getRideContext],
	(context) => context === GENERAL.CONTEXT_REPORTS
);
