import Dexie from 'dexie';
import { DATABASE } from '../../constants';

export const setupPostDatabase = () => {
	const database = new Dexie(DATABASE.DB_POST);

	database.version(DATABASE.DB_VERSION).stores({
		rides: '++dbId, ride'
	});

	return database;
};

export const setupPutDatabase = () => {
	const database = new Dexie(DATABASE.DB_PUT);

	database.version(DATABASE.DB_VERSION).stores({
		rides: '++dbId, ride'
	});

	return database;
};

export const setupDeleteDatabase = () => {
	const database = new Dexie(DATABASE.DB_DELETE);

	database.version(DATABASE.DB_VERSION).stores({
		rides: '++dbId, ride'
	});

	return database;
};

export const setupReadyDatabase = () => {
	const database = new Dexie(DATABASE.DB_READY);

	database.version(DATABASE.DB_VERSION).stores({
		rides: '++dbId, ride'
	});

	return database;
};
