import styled from 'styled-components';
import { ButtonSubmit } from '../Common/Common';
import { ArticleNr, Capacity, Dimensions, Id, Name, ShortName, TextPrimary } from '../InvoiceScreen/InvoiceScreen';

export const InvoiceSummaryScreenWrapper = styled.div`
	position: relative;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: ${({ theme }) => theme.colors.primary};
`;

export const TablesContainer = styled.div`
	width: calc(100% - 3rem);
	margin-top: 14rem;
`;

export const InvoiceSummaryTable = styled.div`
	border: 2px solid	black;
	border-radius: 1.2rem;
	width: 100%;
	margin-bottom: 4rem;
`;

export const InvoiceSummaryCard = styled.div`
	display: grid;
	grid-template-columns: max-content 1fr max-content 12rem 25rem 23rem;
	align-items: center;
	grid-row-gap: 2rem;
	padding: 2rem 2.7rem 1.5rem 0;
	
	&:not(:last-child) {
		border-bottom: 2px solid	black;;
	}
	
	& > p {
		margin: 0;
	}
`;

export const SummaryId = styled(Id)`
	grid-column: 1 / 2;
	grid-row: 1 / span 2;
	padding: 0 2.7rem;
	text-align: center;
	min-width: 10rem;
`;

export const SummaryName = styled(Name)`
	grid-column: 2 / span 5;
	grid-row: 1 / 2;
`;

export const SummaryShortName = styled(ShortName)`
	grid-column: 2 / 3;
	grid-row: 2 / 3;
`;

export const SummaryDimensions = styled(Dimensions)`
	grid-column: 3 / 4;
	grid-row: 2 / 3;
`;

export const SummaryArticleNr = styled(ArticleNr)`
	justify-self: center;
	grid-column: 4 / 5;
	grid-row: 2 / 3;
`;

export const Destination = styled(TextPrimary)`
	grid-column: 5 / 6;
	grid-row: 2 / 3;
	font-weight: 700;
	font-size: 3.2rem;
	color: ${({ theme, red }) => red ? theme.colors.primaryRed : theme.colors.primaryGreen};
`;

export const AmountContainer = styled.div`
	display: flex;
	grid-column: 6 / 7;
	grid-row: 2 / 3;
	justify-self: flex-end;
`;

export const Amount = styled(Capacity)`
	color: black;
	margin-right: 0.5rem;
`;

export const ButtonsContainer = styled.div`
	display: grid;
	grid-template-columns: max-content 1fr;
	grid-column-gap: 2rem;
	width: calc(100% - 3rem);
	margin-bottom: 6.4rem;
	margin-top: auto;
`;

const InvoiceSummaryButton = styled(ButtonSubmit)`
	font-size: 3.2rem;
	height: 9rem;
`;

export const NoteButton = styled(InvoiceSummaryButton)`
	display: flex;
	align-items: center;
	background-color: ${({ theme }) => theme.colors.greyMedium};
	padding-right: 4rem;
	transition: all .2s;
	
	&:hover,
	&:focus {
		background-color: ${({ theme }) => theme.colors.greyMediumHover};
	}
	
	svg {
		width: 7.5rem;
		height: 6rem;
		margin: 0 3rem;
		fill: ${({ theme }) => theme.colors.primaryWhite};
	}
`;

export const EditNoteButton = styled(NoteButton)`
	background-color: ${({ theme }) => theme.colors.primaryBrown};
	border: 2px solid black;
	color: black;
	text-align: left;
	max-width: 35rem;
	
	&:hover,
	&:focus {
		background-color: ${({ theme }) => theme.colors.primaryBrownHover};
	}
	
	svg {
		width: 7.5rem;
		height: 6rem;
		margin: 0 3rem;
		fill: black;
	}
`

export const ApplyButton = styled(InvoiceSummaryButton)`
	width: 100%;
`;
