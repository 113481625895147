import { call, put, select, takeEvery } from 'redux-saga/effects';
import * as TYPES from './types';
import { getItemFromStorage, putItemIntoStorage } from '../../../utils/storage/storage';
import { GENERAL } from '../../../constants';
import { setDarkTheme, toggleDarkTheme } from './actions';
import * as generalSelectors from './selectors';

function* checkDarkTheme() {
	const theme = yield call(getItemFromStorage, GENERAL.DARK_THEME_KEY);

	if (theme === 'true') {
		yield put(toggleDarkTheme());
	} else {
		yield call(putItemIntoStorage, GENERAL.DARK_THEME_KEY, 'false');
	}
}

function* toggleTheme() {
	yield put(setDarkTheme());
	const darkThemeEnabled = yield select(generalSelectors.isDarkTheme);
	yield call(putItemIntoStorage, GENERAL.DARK_THEME_KEY, `${darkThemeEnabled}`);
}

export const checkDarkThemeSaga = takeEvery(
	TYPES.CHECK_DARK_THEME,
	checkDarkTheme
);

export const toggleDarkThemeSaga = takeEvery(
	TYPES.TOGGLE_DARK_THEME,
	toggleTheme
);
