import { v4 as uuidv4 } from 'uuid';

export const calculateConversion = (unit, stockUnit, conversionFactor, amount) => {
	if (!Number.isInteger(amount) || unit === stockUnit) {
		return {};
	}
	if (amount % conversionFactor === 0) {
		return {
			primaryType: `${amount / conversionFactor} ${stockUnit}`
		};
	}

	return {
		primaryType: `${Math.floor(amount / conversionFactor)} ${stockUnit}`,
		secondaryType: `${Math.floor(amount % conversionFactor)} ${unit}`
	};
};

export const mapInvoice = (invoice) => invoice.items.map(({
	pos_number: posNumber,
	invoice_position_char: index,
	amount,
	is_split: isSplit,
	initial_amount: initialAmount,
	unit,
	stock_unit: stockUnit,
	warehouse_name: destination,
	article_nr: articleNr,
	article_short_description: shortName,
	article_size: dimensions,
	article_name: name,
	initial_item_id: initialId,
	conversion_factor: conversionFactor,
	splittable
}) => {
	const {
		primaryType,
		secondaryType
	} = calculateConversion(unit, stockUnit, conversionFactor, amount);

	return {
		id: uuidv4(),
		splitId: uuidv4(),
		index,
		posNumber,
		isSplit,
		maxCapacity: initialAmount,
		capacity: amount,
		initialCapacity: amount,
		currentAmount: amount,
		unit,
		stockUnit,
		conversionFactor,
		destination,
		articleNr,
		shortName: name,
		dimensions,
		name: shortName,
		initialId,
		primaryType,
		secondaryType,
		selected: false,
		splittable
	};
}).sort(({ index: a }, { index: b }) => a > b ? 1 : -1);
