import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { IconWrapper, SearchBarWrapper, SearchInput } from '../../styles/SearchScreen/SearchScreen';
import { ReactComponent as LoupeIcon } from '../../../../icons/loupe.svg';
import { PATHS, STRINGS } from '../../../constants';
import { searchActions, searchSelectors } from '../../../state/ducks/search';
import { productsActions, productsSelectors } from '../../../state/ducks/products';
import { generalSelectors } from '../../../state/ducks/general';

const SearchBar = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const products = useSelector(productsSelectors.getProducts);
	const searchValue = useSelector(searchSelectors.getSearchValue);
	const isReplaceContext = useSelector(searchSelectors.isReplaceContext);
	const isReportsContext = useSelector(generalSelectors.isReportsContext);
	const selectionId = useSelector(searchSelectors.getSelectionId);

	const getScannedProduct = (productId) =>
		products.find(({ product: { article_nr: id } = {} }) => id === productId);

	const getScannedProductForCorrection = (productId) =>
		products.find(({ article_nr: nr }) => nr === productId);

	const handleInputChanged = (event) => {
		const { value } = event.target;

		if (!searchValue && value.length > 1) {
			const { product: { id } = {} } = getScannedProduct(parseInt(value)) || {};

			if (isReportsContext) {
				const { id: correctionId } = getScannedProductForCorrection(parseInt(value));
				dispatch(productsActions.setSelectedProductId({ selectedProductId: correctionId }));
				history.push(`${PATHS.PRODUCTS_CORRECTION}/${id}`);
			} else if (id && !isReplaceContext) {
				dispatch(productsActions.setSelectedProductId({ selectedProductId: id }));
				history.push(`${PATHS.PRODUCTS}/${id}`);
			} else if (id && isReplaceContext) {
				dispatch(productsActions.replaceProduct({ id, selectionId }));
				history.push(PATHS.SUMMARY);
			}
		} else {
			dispatch(searchActions.setSearchValue({ searchValue: value }));
		}
	};

	return (
		<SearchBarWrapper>
			<IconWrapper><LoupeIcon/></IconWrapper>
			<SearchInput
				autoFocus
				onChange={handleInputChanged}
				defaultValue={searchValue}
				placeholder={STRINGS.TYPE_ARTICLE_NAME}/>
		</SearchBarWrapper>
	);
};

export default SearchBar;
