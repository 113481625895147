export const lightTheme = {
	colors: {
		primary: '#f4f4f4',
		primaryHover: '#e0e0e0',
		fontDark: '#333333',
		fontGreen: '#638d3e',
		fontRed: '#b53333',
		fontGrey: '#7b7b7b',
		fontGreyLight: '#c3c3c3',
		fontWhite: '#ffffff',
		primaryGreen: '#638d3e',
		darkGreen: '#32BA7C',
		excelGreen: '#1E6C41',
		lightGreenHover: '#ABC091',
		lightGreenHover2: '#DDE4D4',
		primaryRed: '#b53333',
		mediumRed: '#D08585',
		mediumRed2: '#E1BABA',
		primaryRedHover: '#D49494',
		lightRed: '#DEB1B1',
		primaryPink: '#E4C4C4',
		primaryGrey: '#7b7b7b',
		greyLight2: '#E7E7E6',
		greyLight3: '#929292',
		greyLight4: '#999999',
		greyLight5: '#757575',
		greyLight6: '#E6E6E6',
		greyLight7: '#A6A6A6',
		greyMedium: '#758190',
		greyMediumHover: '#BAC0C7',
		greyMedium2: '#8892A0',
		greyMedium3: '#DCDFE3',
		greyDark: '#787878',
		secondaryGrey: '#c3c3c3',
		tertiaryGrey: '#f0f0f0',
		tertiaryGreyHover: '#DCDCDC',
		primaryBrown: '#C18E59',
		primaryBrownHover: '#DAC1A6',
		primaryBlack: '#000000',
		primaryBlackHover: '#ABABAB',
		primaryWhite: '#ffffff',
		greyLight: '#dedede',
		greyMid: '#adadad',
		optionGreenHover: 'rgba(99, 141, 46, 0.5)',
		optionRedHover: 'rgba(181, 51, 51, 0.5)',
		optionGreyHover: 'rgba(120, 120, 120, 0.4)',
		optionExcelHover: 'rgba(99, 141, 46, 0.5)',
		optionBrownHover: 'rgba(193, 142, 89, 0.2)',
		optionGrey2Hover: 'rgba(117, 129, 144, 0.2)'
	}
};

export const darkTheme = {
	colors: {
		primary: '#929292',
		primaryHover: '#868686',
		fontDark: '#1F1F1F',
		fontGreen: '#3B551C',
		fontRed: '#6D1F1F',
		fontGrey: '#4A4A4A',
		fontGreyLight: '#757575',
		fontWhite: '#999999',
		primaryGreen: '#3B551C',
		darkGreen: '#1E704A',
		excelGreen: '#1E6C41',
		lightGreenHover: '#677357',
		lightGreenHover2: '#85897F',
		primaryRed: '#6D1F1F',
		mediumRed: '#7D5050',
		mediumRed2: '#877070',
		primaryRedHover: '#7F5959',
		lightRed: '#856A6A',
		primaryPink: '#897676',
		primaryGrey: '#4A4A4A',
		greyLight2: '#8B8B8A',
		greyLight3: '#585858',
		greyLight4: '#999999',
		greyLight5: '#464646',
		greyLight6: '#8A8A8A',
		greyLight7: '#646464',
		greyMedium: '#464D56',
		greyMediumHover: '#707377',
		greyMedium2: '#525860',
		greyMedium3: '#848688',
		greyDark: '#787878',
		secondaryGrey: '#757575',
		tertiaryGrey: '#909090',
		tertiaryGreyHover: '#848484',
		primaryBrown: '#745535',
		primaryBrownHover: '#837464',
		primaryBlack: '#000000',
		primaryBlackHover: '#ABABAB',
		primaryWhite: '#999999',
		greyLight: '#858585',
		greyMid: '#adadad',
		optionGreenHover: 'rgba(59, 85, 28, 0.5)',
		optionRedHover: 'rgba(109, 31, 31, 0.5)',
		optionGreyHover: 'rgba(120, 120, 120, 0.4)',
		optionExcelHover: 'rgba(99, 141, 46, 0.5)',
		optionBrownHover: 'rgba(116, 85, 53, 0.2)',
		optionGrey2Hover: 'rgba(82, 88, 96, 0.2)'
	}
};

// export const STRINGS = {
// 	GUTEX_INTRALOGISTIK: 'Gutex Intralogistik',
// 	LOGIN: 'Login',
// 	LOG_IN: 'Log in',
// 	LOGOUT: 'Log Out',
// 	USERNAME: 'Username',
// 	PASSWORD: 'Password',
// 	NO_MATCHING_ROUTE: 'No matching route!',
// 	MENU: 'Menu',
// 	ENTER_A_RIDE: 'Enter a Ride',
// 	CORRECT_A_RIDE: 'Correct a Ride',
// 	GO_BACK: 'go back',
// 	SELECT_DESTINATION: 'Select Destination',
// 	START: 'Start:',
// 	DESTINATION: 'Destination:',
// 	TYPE_ARTICLE_NAME: 'Type Article Name...',
// 	PLEASE_SEARCH_FOR_ARTICLE: 'Please search for an article.',
// 	ADD_ARTICLE: 'Add Article',
// 	APPROVE: 'Approve',
// 	PAL: 'PAL',
// 	DEST: 'Dest',
// 	PALLETS: 'Pallets',
// 	PIECES: 'Pieces',
// 	PAL_KEY: 'PALLETS',
// 	PIECES_KEY: 'PIECES',
// 	RIDE_SUMMARY: 'Ride Summary',
// 	START_RIDE: 'Start Ride',
// 	EDIT_RIDE: 'Edit Ride',
// 	EDIT_ARTICLE: 'Edit Article',
// 	FULLY_LOADED: 'FULLY LOADED',
// 	ADMIN_PANEL: 'Admin Panel',
// 	WAREHOUSE_PROVISION: 'Warehouse Provision',
// 	EXCEL_PROVISION: 'Excel Provision',
// 	CURRENT_WAREHOUSES: 'Current Warehouses',
// 	REMOVE: 'Remove',
// 	PROVIDE: 'Provide',
// 	WAREHOUSE_NAME: 'Warehouse Name',
// 	WAREHOUSE_NUMBER: 'Warehouse Number',
// 	DRAG_N_DROP_HERE: 'Drop here your data - Drag\'n Drop',
// 	YOUR_CHANGES: 'Your Changes',
// 	DESTINATIONS: 'Destinations',
// 	ARTICLES: 'Articles',
// 	YOUR_PREVIOUS_RIDES: 'Your Previous Rides',
// 	HOUR: 'Hour',
// 	CANCEL: 'cancel',
// 	ARE_YOU_SURE: 'Are you sure?',
// 	YES: 'Yes',
// 	NO: 'No',
// 	OKAY: 'Okay',
// 	APPLY_EXCEL: 'Apply Excel',
// 	APPROVE_CORRECTION: 'Approve Correction',
// 	ERROR: 'Error',
// 	LOADING: 'Loading...',
// 	DEFAULT_WAREHOUSE: 'Default warehouse',
// 	DELETE_RIDE: 'Delete Ride',
// 	ADMIN: 'Admin',
// 	TO_MUCH_PALLETS_MSG: 'Sie haben mehr Paletten ausgewählt als die sortenreine max. Beladung zulässt. Bitte wählen Sie weniger aus, um fortfahren zu können.',
// 	LOGOUT_MSG: 'abmelden?',
// 	CANCEL_RIDE_MSG: 'Fahrt abbrechen?',
// 	CANCEL_RIDE_EDIT_MSG: 'Änderungen verwerfen?',
// 	DELETE_ARTICLE_MSG: 'Artikel löschen?',
// 	DELETE_RIDE_MSG: `Gesamte Fahrt löschen?<br/>(kann nicht Rückgängig gemacht werden)`,
// 	SELECT_YOUR_TRUCK: 'Select your Truck',
// 	WAREHOUSE: 'Lager',
// 	PROVISION: 'Hinzufugen',
// 	EXCEL: 'Excel',
// 	UPLOAD: 'Hochladen'
// };
export const STRINGS = {
	GUTEX_INTRALOGISTIK: 'Gutex Intralogistik',
	LOGIN: 'Anmeldung',
	LOG_IN: 'Anmelden',
	LOGOUT: 'Abmelden',
	USERNAME: 'Nutzername',
	PASSWORD: 'Passwort ...',
	NO_MATCHING_ROUTE: 'No matching route!',
	MENU: 'Menü',
	MAIN_MENU: 'Hauptmenü',
	ENTER_A_RIDE: 'Fahrt eintragen',
	CORRECT_A_RIDE: 'Fahrt korrigieren',
	GO_BACK: 'zurück',
	SELECT_DESTINATION: 'Ziellager auswählen',
	START: 'Start:',
	DESTINATION: 'Ziel:',
	TYPE_ARTICLE_NAME: 'Artikel-Name, Nummer ...',
	PLEASE_SEARCH_FOR_ARTICLE: 'Bitte suchen Sie nach einem Artikel.',
	ADD_ARTICLE: 'Artikel hinzufügen',
	APPROVE: 'Hinzufügen',
	PAL: 'PAL',
	DEST: 'Ziel',
	PALLETS: 'PALETTEN',
	PIECES: 'STÜCK',
	BALLEN: 'BALLEN',
	PAL_KEY: 'PALLETS',
	PIECES_KEY: 'PIECES',
	RIDE_SUMMARY: 'Fahrtübersicht',
	START_RIDE: 'Fahrt starten',
	EDIT_RIDE: 'bearbeiten',
	EDIT_ARTICLE: 'bearbeiten',
	FULLY_LOADED: 'VOLLBELADEN',
	ADMIN_PANEL: 'Admin Bereich',
	WAREHOUSE_PROVISION: 'Lager hinzufügen',
	WAREHOUSE_MANAGEMENT: 'Lager verwalten',
	MANAGE: 'Verwalten',
	EXCEL_PROVISION: 'Datenbank ändern',
	CURRENT_WAREHOUSES: 'Aktuelle Lager',
	REMOVE: 'Entfernen',
	PROVIDE: 'Hinzufügen',
	WAREHOUSE_NAME: 'Lager Name',
	WAREHOUSE_NUMBER: 'Lager ID',
	DRAG_N_DROP_HERE: 'Bitte ziehen Sie Ihre Excel-Datei per Drag\'n\'Drop in dieses Feld.',
	YOUR_CHANGES: 'Ihre Änderungen',
	DESTINATIONS: 'Lager:',
	ARTICLES: 'Artikel',
	YOUR_PREVIOUS_RIDES: 'Ihre Fahrten',
	HOUR: 'Uhr',
	CANCEL: 'abbrechen',
	ARE_YOU_SURE: 'Are you sure?',
	YES: 'Ja',
	NO: 'Nein',
	OKAY: 'Okay',
	APPLY_EXCEL: 'Datei verarbeiten',
	APPROVE_CORRECTION: 'Korrektur bestätigen',
	ERROR: 'Ihre Excel scheint fehlerhaft zu sein. Kontaktieren Sie uns gerne per Telefon: +49 1514 1233 439',
	LOADING: 'Ihre Excel wird verarbeitet...',
	DEFAULT_WAREHOUSE: '',
	DELETE_RIDE: 'Fahrt löschen',
	ADMIN: 'Admin',
	TO_MUCH_PALLETS_MSG: 'Sie haben mehr Paletten ausgewählt als die sortenreine max. Beladung zulässt. Bitte wählen Sie weniger aus, um fortfahren zu können.',
	LOGOUT_MSG: 'abmelden?',
	CANCEL_RIDE_MSG: 'Fahrt abbrechen?',
	CANCEL_RIDE_EDIT_MSG: 'Änderungen verwerfen?',
	DELETE_ARTICLE_MSG: 'Artikel löschen?',
	DELETE_RIDE_MSG: 'Gesamte Fahrt löschen?<br/>(kann nicht rückgängig gemacht werden)',
	OFFLINE_RIDES_MSG: 'noch nicht gesendet.<br/>Abmeldung nicht möglich.</br>Bitte Internetverbindung herstellen.',
	DATABASE_ACCEPTED_MSG: 'Ihre Excel-Datei wurde akzeptiert.</br>Um 01:00 Uhr wird die Datenbank entsprechend aktualisiert.',
	SELECT_YOUR_TRUCK: 'Wählen Sie Ihren LKW',
	WAREHOUSE: 'Lager',
	PROVISION: 'Hinzufügen',
	EXCEL: 'Excel',
	UPLOAD: 'Hochladen',
	OFFLINE: 'OFFLINE',
	RIDES: 'Fahrten',
	CHANGE_DATABASE: 'Datenbank ändern',
	LAST_RIDES: 'Ihre letzten Fahrten',
	SAVE_NOTE: 'Bemerkung speichern',
	YOUR_NOTE: 'Ihre Bemerkung:',
	DELIVERY: 'Lieferschein',
	GOOD_RIDE: 'Gute Fahrt!',
	RIDE_BOOKED_SUCCESSFULLY: 'Lieferschein erfolgreich verbucht.',
	SECOND_WAREHOUSE_WAITING: '<small>Die</small> <strong>nächste Ladestation wartet</strong></br><small>auf Ihren Besuch.</small>',
	SCAN_INVOICE: 'Lieferschein scannen',
	SCAN: 'scannen',
	START_DELIVERY: 'Auslieferung starten',
	INVOICE_SUMMARY: 'Zusammenfassung',
	NOTE: 'Bemerkung',
	APPLY: 'Bestätigen',
	WAREHOUSE_RIDE: 'Umlagerungen',
	CLIENT_RIDE: 'Kundenfahrten',
	USER_ROLE: 'Nutzer-Rolle',
	EDIT_NOTE: 'Bemerkung ändern',
	SYNC_WITH_ABAS: 'mit abas synchronisieren',
	RESET: 'zurücksetzen',
	ABAS_SYNC_MSG: 'Lieferschein wirklich <strong>mit Abas synchronisieren?</strong></br>Ihre bisherigen Änderungen werden gelöscht.',
	SYNC: 'synchronisieren',
	REJECT: 'abbrechen',
	RESET_MSG: 'Lieferschein wirklich <strong>zurücksetzen?</strong></br>Ihre bisherigen Änderungen werden gelöscht.',
	LKW: 'LKW',
	FURTHER_LAGER: 'Weiteres Lager',
	REPORTS_LOGISTIC: 'Auswertung Intralogistik',
	WAREHOUSE_CORRECTIONS: 'Lagerkorrekturen',
	WAREHOUSE_CORRECTION: 'Lagerkorrektur',
	WAREHOUSE_DELIVERY_CORRECTION: 'Umlagerungskorrektur',
	ARTICLES_DAMAGED: 'Ware beschädigt',
	FILTER: 'Filter',
	START_WAREHOUSE: 'Start-Lager',
	END_WAREHOUSE: 'End-Lager',
	CORRECTION_TYPE: 'Korrektur-Typ',
	REASON: 'Grund',
	PROCEED_WITH_CORRECTION: 'Lagerkorrektur vornehmen',
	ARTICLE_SELECTION: 'Artikel auswählen',
	TOTAL: 'Gesamt',
	ALL_DETAILS: 'Alle Details',
	APPROVE_SELECTION: 'Bestätigen',
	INVOICE_ALREADY_USED_MSG: 'Ihr Lieferschein wurde bereits verwendet.</br>Bearbeitung nicht möglich.',
	INVOICE_NOT_EXISTS: 'Die Lieferscheinnummer existiert in Abas nicht.</br>Bearbeitung nicht möglich.',
	DOWNLOAD_CURRENT_EXCEL: 'aktuelle Excel herunterladen',
	INVOICE_ZERO_ITEMS: '<strong>Buchung kann nicht erfasst werden.</strong><br/><br/>Bitte wenden Sie sich an Ihre Versandabteilung!',
	INVOICE_IN_PROGRESS_MSG: 'Der <strong>Lieferschein wurde zwischenzeitlich schon</strong></br> von einem anderen Mitarbeiter <strong>bearbeitet</strong></br> und an Abas gesendet.</br></br>Ihr Vorgang wird deshalb abgebrochen.',
	END_PROCESS: 'Vorgang beenden',
	BOOK_DELIVERY: 'Lieferschein Buchen',
	BOOK_FURTHER_WAREHOUSE: 'Teillieferschein Buchen',
	PROCEED_TO_SUMMARY: 'weiter zur Buchungsübersicht',
	QUICK_MODE_MSG: '<strong>Achtung:</strong></br>Lieferscheine werden automatisiert verbucht.',
	START_CORRECTION_WAREHOUSE: 'Abgangs-Lager',
	END_CORRECTION_WAREHOUSE: 'Zugangs-Lager',
	SUCCESSFULLY_BOOKED_MSG: 'Lieferschein erfolgreich <strong>verbucht</strong>.',
	SUCCESSFUL_CORRECTION_MSG: 'Lagerkorrektur erfolgreich <strong>verbucht</strong>.',
	REPORTING: 'Reporting',
	GENERATE_REPORT: 'Report erstellen',
	INDIVIDUAL_REPORT: 'Individueller Report',
	FROM: 'Von',
	TO: 'Bis',
	ALL: 'alle',
	INVOICE_IN_USE_MSG: 'Achtung! Der Lieferschein befindet sich aktuell in Bearbeitung durch die Versanddisposition. Eine Bearbeitung ist derzeit nicht möglich!',
	INVOICE_QUANTITY_DIFFERS_MSG: 'Achtung! Die Verbuchung des Lieferscheines wurde aufgrund einer Mengenabweichung abgebrochen! Bitte kontaktieren Sie die Versanddisposition.',
	MODIFICATION_REJECTED: 'Bearbeitung abbrechen',
	PROVIDE_NOTE: 'Notiz hinzufügen ...',
	SLOW_INTERNET_MSG: '<strong>Die nachfolgend gelisteten Fahrten konnten nicht an Abas übertragen werden.</strong></br></br>Bitte probieren Sie es mit einer stabilen Internetverbindung erneut, oder wechseln</br>Sie in den Flugmodus des Tablets, um die Fahrten offline zu speichern.',
	RESEND_REJECT: 'Vorgang abbrechen',
	LOSE_RIDE: 'Fahrten löschen',
	RESEND: 'Erneut senden',
	CHECK_DELIVERY: 'Lieferschein prüfen',
	PROCEED_TO_CHECK_DELIVERY: 'Zu prüfenden Lieferschein scannen',
	REQUIRED: 'Angefragt',
	BOOKED: 'Verbucht',
};

export const GENERAL = {
	DARK_THEME_KEY: 'darkTheme',
	CONTEXT_EDIT: 'editRide',
	CONTEXT_REPORTS: 'reportsContext'
};

export const SEARCH = {
	REPLACE: 'replace'
};

export const MODAL = {
	LOGOUT: 'logout',
	CANCEL_RIDE: 'cancelRide',
	DELETE_PRODUCT: 'deleteProduct',
	DELETE_RIDE: 'deleteRide',
	TOO_MUCH_PALLETS: 'tooMuchPallets',
	DATABASE_ACCEPTED: 'databaseAccepted',
	MAKE_NOTE: 'makeNote',
	ABAS_SYNC: 'abasSync',
	RESET_INVOICE: 'resetInvoice',
	INVOICE_ALREADY_USED: 'invoiceAlreadyUsed',
	INVOICE_NOT_EXISTS: 'invoiceNotExists',
	INVOICE_ZERO_ITEMS: 'invoiceZeroItems',
	INVOICE_IN_PROGRESS: 'invoiceInProgress',
	INVOICE_IN_USE: 'invoiceInUse',
	INVOICE_QUANTITY_DIFFERS: 'invoiceQuantityDiffers',
	SUCCESSFULLY_BOOKED: 'successfullyBooked',
	SUCCESSFUL_CORRECTION: 'successfulCorrection',
	CORRECTION_REPORTS: 'correctionReports',
	SLOW_INTERNET: 'slowInternet',
	CHECK_DELIVERY: 'checkDelivery',
	RIDES_REPORTS: 'ridesReports'
};

export const AUTHENTICATION = {
	TOKEN_KEY: 'authToken',
	ADMIN_TOKEN_KEY: 'adminAuthToken',
	TRUCK_TOKEN_KEYS: 'truckTokens',
	USERNAME: 'username'
};

export const STORAGE = {
	PATH: 'path',
	FILTERS: 'filters',
	LAST_ACTION: 'lastAction',
	IS_GUTEX_DRIVER_LAST_LOGGED_IN: 'isGutexDriverLastLoggedIn',
	IS_GUTEX_ADMIN_LAST_LOGGED_IN: 'isGutexAdminLastLoggedIn'
};

export const REPORTS = {
	NO_TRUCK_SELECTION: 'alle LKW',
	NO_START_SELECTION: 'alle Start-Lager',
	NO_END_SELECTION: 'alle End-Lager',
	DATE: 'date',
	TRUCK: 'truck_name',
	START: 'start_location_nr',
	END: 'end_location_nr',
	CORRECTION_TYPE: 'type',
	CORRECTION_PLUS: 'plus',
	CORRECTION_MINUS: 'minus',
	CORRECTION_REASON: 'reason',
	CORRECTION_AMOUNT: 'amount',
	CORRECTION_UNIT: 'unit',
	CORRECTION_PRODUCT_NR: 'productNr',
	CORRECTION_END_WAREHOUSE: 'endLocation',
	CORRECTION_CUSTOM_NOTE: 'custom_comment',
	REASON_WAREHOUSE: STRINGS.WAREHOUSE_CORRECTION,
	REASON_DELIVERY: STRINGS.WAREHOUSE_DELIVERY_CORRECTION,
	REASON_DAMAGE: STRINGS.ARTICLES_DAMAGED
};

export const DATABASE = {
	DB_POST: 'GUTEX_DB_POST',
	DB_PUT: 'GUTEX_DB_PUT',
	DB_DELETE: 'GUTEX_DB_DELETE',
	DB_READY: 'GUTEX_DB_READY',
	DB_VERSION: 1,
	TYPE_POST: 'post',
	TYPE_PUT: 'put',
	TYPE_READY: 'ready'
};

export const SERVICE_WORKER = {
	POST_RIDE: 'postRide',
	PUT_RIDE: 'putRide',
	DELETE_RIDE: 'deleteRide',
	CHANNEL: 'primaryChannel',
	CONTENT_UPDATE: 'contentUpdate',
	NEW_RIDES_AVAILABLE: 'newRidesAvailable',
	UPDATE_LAST_5: 'updateLast5',
	UPDATE_LIVENESS: 'updateLiveness',
	SLOW_INTERNET: 'slowInternet'
};

export const PATHS = {
	DEFAULT: '/',
	LOGIN: '/',
	PAGE: '/:page',
	RIDES_MENU: '/rides-menu',
	MAIN_MENU: '/main-menu',
	DESTINATION: '/destination',
	SEARCH: '/search',
	SEARCH_CORRECTION: '/search-correction',
	PRODUCTS: '/products',
	PRODUCTS_CORRECTION: '/products-correction',
	PRODUCT_ID: '/:productId',
	SUMMARY: '/summary',
	ADMIN: '/admin',
	ADMIN_MENU: '/admin-menu',
	WAREHOUSE: '/warehouse',
	EXCEL: '/excel',
	PREVIOUS_RIDES: '/previous-rides',
	GOOD_RIDE: '/good-ride',
	SCAN_INVOICE: '/scan-invoice',
	INVOICE: '/invoice',
	INVOICE_SUMMARY: '/invoice-summary',
	REPORTS: '/reports',
	CORRECTION: '/corrections'
};

export const ADMIN_ROUTES = [
	'/admin',
	'/admin-menu',
	'/warehouse',
	'/excel'
];

export const PATH_NAMES = {
	LOGIN: '/',
	RIDES_MENU: 'rides-menu',
	MAIN_MENU: 'main-menu',
	DESTINATION: 'destination',
	SEARCH: 'search',
	SEARCH_CORRECTION: 'search-correction',
	PRODUCTS: 'products',
	PRODUCTS_CORRECTION: 'products-correction',
	SUMMARY: 'summary',
	ADMIN: 'admin',
	ADMIN_MENU: 'admin-menu',
	WAREHOUSE: 'warehouse',
	EXCEL: 'excel',
	PREVIOUS_RIDES: 'previous-rides',
	GOOD_RIDE: 'good-ride',
	SCAN_INVOICE: 'scan-invoice',
	INVOICE: 'invoice',
	INVOICE_SUMMARY: 'invoice-summary',
	REPORTS: 'reports',
	CORRECTION: 'corrections'
};

export const API = {
	DOMAIN: process.env.REACT_APP_BACKEND_URL,
	LOGIN: '/auth/login/',
	LOGOUT: '/auth/logout/',
	LOGIN_CHECK: '/auth-check',
	TRUCKS: '/trucks/',
	LOCATIONS: '/locations/',
	LOCATIONS_ADMIN: '/locations-no-cache',
	PRODUCTS: '/products/',
	ALL_PRODUCTS: '/all-products/',
	RIDES: '/rides/',
	DATABASE: '/database/',
	ACCEPT_DATABASE: '/accept-new-database/',
	LAST_5: '/last5/',
	GET_INVOICE: '/abas-invoices/get-invoice/',
	ABAS_SYNC: '/abas-invoices/sync-invoice/',
	SUBMIT_INVOICE: '/abas-invoices/submit-invoice/',
	SPLIT_INVOICE: '/abas-invoices/split-invoice/',
	SEND_CORRECTION: '/abas-invoices/send-correction/',
	SEND_DOUBLE_CORRECTION: '/abas-invoices/send-double-correction/',
	CORRECTION_EXCEL_REPORT: '/abas-invoices/correction-excel-report/',
	GUTEX_USERS: '/gutex-users/',
	RIDES_REPORT: '/rides-report?',
	DOWNLOAD_EXCEL: '/get-excel-file/',
	CHECK_INVOICE: '/abas-invoices/check-invoice/',
	RIDES_EXCEL_REPORT: '/abas-invoices/rides-report-xlsx/'
};

export const PROOF_OF_LIFE_CALLS = [
	'rides',
	'get-invoice',
	'sync-invoice',
	'submit-invoice',
	'split-invoice',
	'send-correction',
	'rides-report'
];

export const INVOICE = {
	UNKNOWN: 'UNKNOWN',
	UNKNOWN_ID: -1,
	SUCCESS: 'success',
	FAILURE: 'failure'
};

export const CACHE = {
	CACHE_NAME: 'GUTEX_V1',
	CACHEABLE: [
		API.TRUCKS,
		API.LOCATIONS,
		API.PRODUCTS,
		API.LAST_5
	]
};
