import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { authSelectors } from '../../../state/ducks/authentication';
import { PATHS } from '../../../constants';

const AdminRoute = ({ component: Component, ...rest }) => {
	const adminToken = useSelector(authSelectors.getAdminToken);

	return (
		<Route
			{...rest}
			render={(routeProps) =>
				adminToken
					? <Component {...routeProps}/>
					: <Redirect to={PATHS.ADMIN}/>
			}
		/>
	);

};

export default AdminRoute;
