import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isNil } from 'ramda';
import { GeneralSummary, ReportContainer, ReportsScreenWrapper } from '../../styles/ReportsScreen/ReportsScreen';
import FilterSelection from './FilterSelection';
import { reportsSelectors } from '../../../state/ducks/reports';
import { MODAL, STRINGS } from '../../../constants';
import TimeSlot from './TimeSlot';
import { destinationActions } from '../../../state/ducks/destination';
import { ReportsButton } from '../../styles/CorrectionScreen/CorrectionScreen';
import { ReactComponent as ReportIcon } from '../../../../icons/report.svg';
import DelayedButton from '../Common/DelayedButton';
import { modalActions } from '../../../state/ducks/modal';

const ReportsScreen = () => {
	const dispatch = useDispatch();
	const report = useSelector(reportsSelectors.getReport);
	const {
		lkw_count: lkwCount,
		ride_count: rideCount,
		rides
	} = report;

	const toggleReportsModal = () => {
		dispatch(modalActions.showModal({ modalType: MODAL.RIDES_REPORTS }))
	};

	useEffect(() => {
		dispatch(destinationActions.fetchDestinations());
	}, [dispatch]);

	return (
		<ReportsScreenWrapper>
			<DelayedButton
				onClick={toggleReportsModal}
				component={ReportsButton}
			>
				<ReportIcon/>
				{STRINGS.REPORTING}
			</DelayedButton>
			<FilterSelection/>
			<ReportContainer>
				{!isNil(lkwCount) && <GeneralSummary>
					{`${STRINGS.TOTAL}: ${rideCount} ${STRINGS.RIDES} | ${lkwCount} ${STRINGS.LKW}`}
				</GeneralSummary>}
				{rides?.map((item) => {
					return <TimeSlot
						key={item.time_slot}
						timeSlot={item.time_slot}
						lkwCount={item.lkw_count}
						rideCount={item.ride_count}
						ridesData={item.rides_data}
					/>;
				})}
			</ReportContainer>
		</ReportsScreenWrapper>
	);
};

export default ReportsScreen;
