import * as TYPES from './types';

export const setTrucks = (payload) => ({
	type: TYPES.SET_TRUCKS,
	payload
});

export const fetchTrucks = () => ({
	type: TYPES.FETCH_TRUCKS
});
