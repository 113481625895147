import { all, call, delay, fork, put, select, takeEvery, takeLeading } from 'redux-saga/effects';
import * as TYPES from './types';
import {
	getAllCorrectionsReportsQueries,
	getCorrectionPayload,
	getCorrectionReportQuery,
	getDoubleCorrectionPayload,
	getReason,
	getReportsQueryString,
	getRidesReportQuery,
	isCorrectionReportReasonAll
} from './selectors';
import {
	correctionExcelReport,
	ridesExcelReport,
	ridesReport,
	sendCorrection,
	sendDoubleCorrection
} from '../../../api';
import { getToken } from '../authentication/selectors';
import { clearCorrection, setReport } from './actions';
import { MODAL, REPORTS } from '../../../constants';
import { hideModal, showModal } from '../modal/actions';

function* watchFiltersUpdated() {
	const token = yield select(getToken);
	const query = yield select(getReportsQueryString);

	const { status, data } = yield call(ridesReport, token, query);

	if (status === 200) {
		yield put(setReport(data));
	}
}

function* toggleOffModal() {
	yield delay(1000);
	yield put(hideModal());
}

function* handleSendCorrection() {
	const token = yield select(getToken);
	const reason = yield select(getReason);

	if (reason === REPORTS.REASON_DELIVERY) {
		const payload = yield select(getDoubleCorrectionPayload);
		yield call(sendDoubleCorrection, token, payload);
	} else {
		const payload = yield select(getCorrectionPayload);
		yield call(sendCorrection, token, payload);
	}

	yield put(showModal({ modalType: MODAL.SUCCESSFUL_CORRECTION }));
	yield fork(toggleOffModal);
	yield put(clearCorrection());
}

const downloadReport = (response) => {
	if (response) {
		const filename = response
			.headers['content-disposition']
			.split('filename=')[1]
			.split(';')[0];
		const url = window.URL.createObjectURL(response.data);
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute('download', filename);
		document.body.appendChild(link);
		link.click();
	}
};

function* downloadCorrectionReport() {
	const token = yield select(getToken);
	const isAllQuery = yield select(isCorrectionReportReasonAll);

	if (isAllQuery) {
		const queries = yield select(getAllCorrectionsReportsQueries);
		const responses = yield all(queries.map((query) => call(correctionExcelReport, token, query)));
		yield all(responses.map((response) => call(downloadReport, response)));
	} else {
		const query = yield select(getCorrectionReportQuery);
		const response = yield call(correctionExcelReport, token, query);
		yield call(downloadReport, response);
	}

	yield put(hideModal());
}

function* downloadRidesReport() {
	const token = yield select(getToken);
	const query = yield select(getRidesReportQuery);

	const response = yield call(ridesExcelReport, token, query);
	yield call(downloadReport, response);
	yield put(hideModal());
}

export const watchFiltersUpdatedSaga = takeEvery(
	TYPES.SET_FILTER,
	watchFiltersUpdated
);

export const sendCorrectionSaga = takeLeading(
	TYPES.SEND_CORRECTION,
	handleSendCorrection
);

export const downloadCorrectionReportSaga = takeLeading(
	TYPES.DOWNLOAD_CORRECTION_REPORT,
	downloadCorrectionReport
);

export const downloadRidesReportSaga = takeLeading(
	TYPES.DOWNLOAD_RIDES_REPORT,
	downloadRidesReport
);
