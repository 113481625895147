import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { SearchScreenWrapper } from '../../styles/SearchScreen/SearchScreen';
import { PATHS } from '../../../constants';
import { productsActions, productsSelectors } from '../../../state/ducks/products';
import { searchSelectors } from '../../../state/ducks/search';
import SearchResults from './SearchResults';
import LastRides from './LastRides';

const SearchScreen = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const products = useSelector(productsSelectors.getProductsList);
	const searchValue = useSelector(searchSelectors.getSearchValue) || '';
	const isReplaceContext = useSelector(searchSelectors.isReplaceContext);
	const selectionId = useSelector(searchSelectors.getSelectionId);
	const lastRides = useSelector(productsSelectors.getLastRides);
	const [items, setItems] = useState([]);

	const filterMatchingProducts = useCallback(() => {
		const matching = products?.filter(({ name, article_nr: nr }) =>
			name.toLowerCase().includes(searchValue.toLowerCase().trim())
			|| `${nr}`.startsWith(searchValue.trim()));
		setItems(matching);
	}, [products, searchValue]);

	const handleProductClicked = (id) => {
		setTimeout(() => {
			if (isReplaceContext) {
				dispatch(productsActions.replaceProduct({ id, selectionId }));
				history.push(PATHS.SUMMARY);
			} else {
				dispatch(productsActions.setSelectedProductId({ selectedProductId: id }));
				history.push(`${PATHS.PRODUCTS}/${id}`);
			}
		}, 200);
	};

	useEffect(() => {
		filterMatchingProducts();
	}, [filterMatchingProducts, products]);

	useEffect(() => {
		setTimeout(() => {
			dispatch(productsActions.fetchLastProducts());
		}, 0);
	}, [dispatch]);

	return (
		<SearchScreenWrapper>
			{!searchValue && products
				? <LastRides
					lastRides={lastRides}
					handleProductClicked={handleProductClicked}
				/>
				: <SearchResults
					items={items}
					handleProductClicked={handleProductClicked}
				/>}
		</SearchScreenWrapper>
	);
};

export default SearchScreen;
