import { useCallback, useEffect } from 'react';

export const useEnterClicked = (callback) => {
	const handleEnterClicked = useCallback((event) => {
		if (event.key === 'Enter') {
			callback();
		}
	}, [callback]);

	useEffect(() => {
		window.addEventListener('keyup', handleEnterClicked);
		return () => window.removeEventListener('keyup', handleEnterClicked);
	}, [handleEnterClicked]);
};
