import React from 'react';
import SearchResultsItem from './SearchResultsItem';
import { ProductsList } from '../../styles/SearchScreen/SearchScreen';

const SearchResults = ({
	items,
	handleProductClicked
}) => {
	return (
		<ProductsList>
			{items?.map(({
				id,
				name,
				name_short: shortName,
				article_nr: nr,
				length,
				width,
				height
			}) => {
				const dimensions = `<strong>${height}</strong>x${length}x${width}`;

				return (
					<SearchResultsItem
						key={id}
						name={name}
						id={id}
						dimensions={dimensions}
						handleProductClicked={handleProductClicked}
						nr={nr}
						shortName={shortName}
					/>
				);
			})}
		</ProductsList>
	);
};

export default SearchResults;
