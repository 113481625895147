import produce from 'immer';
import * as TYPES from './types';
import { authTypes } from '../authentication';
import { productsTypes } from '../products';
import { ridesTypes } from '../rides';

const destination = (state = {}, { type, payload }) => {
	switch (type) {
		case TYPES.SET_DESTINATIONS:
		case TYPES.SET_DEFAULT_DESTINATION:
		case TYPES.SET_START_LOCATION:
			return {
				...state,
				...payload
			};
		case TYPES.ADD_LOCATION_TO_LIST:
			return produce(state, (draft) => {
				draft.destinations.push(payload);
			});
		case TYPES.REMOVE_LOCATION_FROM_LIST:
			return produce(state, (draft) => {
				const index = draft.destinations.findIndex(({ id }) => id === payload.id);
				draft.destinations.splice(index, 1);
			});
		case productsTypes.CANCEL_RIDE:
		case ridesTypes.SEND_RIDE_TO_INDEXED_DB:
			return {
				...state,
				startLocation: null,
				defaultDestination: null
			};
		case authTypes.ATTEMPT_LOGOUT:
			return {};
		default:
			return state;
	}
};

export default destination;
