import styled, { css } from 'styled-components';

export const NavbarWrapper = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 9999;
	display: flex;
	align-items: center;
	background-color: ${({ theme }) => theme.colors.primary};
	height: ${({ admin }) => admin ? 7 : 8.5}rem;
	box-shadow: 0 4px 9px rgba(0, 0, 0, 0.15);
`;

export const BackButton = styled.button`
	display: flex;
	align-items: center;
	position: relative;
	font-family: 'Roboto', sans-serif;
	font-weight: 700;
	font-size: 2.8rem;
	height: 100%;
	color: ${({ theme }) => theme.colors.fontDark};
	border: none;
	background-color: inherit;
	margin-left: 4.8rem;
	margin-right: 2.4rem;
	
	&:active,
	&:hover,
	&:focus {
		outline: 0;
	}
`;

export const VerticalDivider = styled.span`
	display: block;
	width: 0.2rem;
	height: 4.7rem;
	background-color: ${({ theme }) => theme.colors.primaryBlack};
	margin-right: 2.4rem;
`;

export const PathIndicator = styled.span`
	font-family: Roboto, sans-serif;
	font-weight: ${({ admin }) => admin ? 400 : 700};
	font-size: 3.2rem;
	line-height: 3.8rem;
	color: ${({ theme }) => theme.colors.fontRed};
	margin-right: auto;
	margin-left: ${({ marginLeft }) => marginLeft && '4.8rem'};
	
	strong {
		font-weight: inherit;
		color: black;
		margin-left: 1rem;
	}
`;

export const LogoWrapper = styled.div`
	display: flex;
	align-items: center;
	
	${({ refreshEnabled }) => refreshEnabled && css`
		background-color: ${({ theme }) => theme.colors.mediumRed2};
		padding: 1rem 2.4rem 0.6rem 1.8rem;
		border-radius: 6px;
		border: 1.5px solid black;
		margin-right: 4.6rem;
	`}
	
	svg	{
		width: 3.5rem;
		height: 3.5rem;
		margin-right: 2.5rem;
		margin-bottom: 0.6rem;
	}
`;

export const NavbarIcon = styled.img`
	width: 25rem;
	margin-right: ${({ refreshEnabled }) => refreshEnabled ? 0 : '4.8rem'};
`;

export const IconWrapper = styled.div`
	display: flex;
	align-items: center;
	
	svg {
		width: 2.5rem;
		height: 2.5rem;
		fill: black;
		margin-right: 1rem;
	}
`;

export const BackArrow = styled(IconWrapper)`
	svg {
		transform: rotate(90deg);
	}	
`;
