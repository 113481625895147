import * as TYPES from './types';

export const showModal = (payload) => ({
	type: TYPES.SHOW_MODAL,
	payload
});

export const hideModal = () => ({
	type: TYPES.HIDE_MODAL
});
