import styled from 'styled-components';
import { ButtonSubmit } from '../Common/Common';
import { DropdownButtonSmall, DropdownSmallList, DropdownSmallTitle, DropdownSmallWrapper } from '../Dropdown/Dropdown';

export const LoginScreenWrapper = styled.div`
	position: relative;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: ${({ theme }) => theme.colors.primary};
`;

export const LoginContainer = styled.div`
	transform: translateY(3.5rem);
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-column-gap: 7rem;
`;

export const IconsContainer = styled.div`
	display: grid;
	justify-items: center;
	grid-row-gap: 7rem;
	margin-top: 4rem;
`;

export const InputContainer = styled.div`
	display: grid;
	align-content: center;
	grid-row-gap: 4rem;
`;

export const LogoImage = styled.img`
	width: 48rem;
`;

export const TruckImage = styled.img`
	width: 37rem;
`;

export const PasswordInput = styled.input`
	width: 55rem;
	height: 8.5rem;
	background-color: ${({ theme }) => theme.colors.primaryWhite};
	border: 5px solid ${({ valid, theme }) => valid ? '#333333' : theme.colors.fontRed};
	border-radius: 6px;
	font-family: 'Roboto', sans-serif;
	font-size: 4.2rem;
	font-weight: 300;	
	padding-left: 2.5rem;
	color: black;
	
	::placeholder {
		color: ${({ theme }) => theme.colors.greyLight4};
		opacity: 1;
	}
	
	&:focus {
		outline: 0;
	}
`;

export const LoginButton = styled(ButtonSubmit)`
	font-size: 4.2rem;
	font-weight: 700;
	width: 55rem;
	height: 8.5rem;
	border: none;
	background-color: ${({ theme }) => theme.colors.primaryGreen};
	color: ${({ theme }) => theme.colors.fontWhite};
	text-align: center;
	border-radius: 6px;
	transition: all 0.3s;
	
	&:not(:active) {
		background-color: ${({ theme }) => theme.colors.primaryGreen};
	}
`;

export const DropdownWrapper = styled.div`
	${DropdownSmallWrapper} {
		width: 100%;
	}
	
	${DropdownButtonSmall} {
		height: 8.5rem;
		border-radius: 6px;
		border: 5px solid black;
		
		svg {
			margin-top: 0;
			width: 5rem;
			fill: ${({ theme }) => theme.colors.greyLight4};
		}
		
		${DropdownSmallTitle} {
			margin-top: 0;
		}
		
		& > * {
			color: ${({ theme, selected }) => selected ? 'inherit' : theme.colors.greyLight4};
			font-size: 4.2rem;
			font-weight: 300;
		}
	}
	
	${DropdownSmallList} {
		border: 5px solid black;
		border-bottom-left-radius: 6px;
		border-bottom-right-radius: 6px;
		transform: translateY(3.2rem);
		color: ${({ theme }) => theme.colors.greyLight4};
		max-height: 30rem;
		
		& > * {
			font-size: 4.2rem;
			color: inherit;
			font-weight: 300;
		}
	}
`;
