export const SET_NOTE = 'SET_NOTE';
export const SET_DELIVERY_LOCATION = 'SET_DELIVERY_LOCATION';
export const FETCH_INVOICE = 'FETCH_INVOICE';
export const SET_INITIAL_INVOICE = 'SET_INITIAL_INVOICE';
export const SET_MAPPED_INVOICE = 'SET_MAPPED_INVOICE';
export const TOGGLE_ITEM_SELECTED = 'TOGGLE_ITEM_SELECTED';
export const SET_WAREHOUSE_FOR_SELECTED = 'SET_WAREHOUSE_FOR_SELECTED';
export const SET_FURTHER_WAREHOUSE_FOR_ALL_SELECTED = 'SET_FURTHER_WAREHOUSE_FOR_ALL_SELECTED';
export const RESET_INVOICE = 'RESET_INVOICE';
export const RESET_INVOICE_ABAS_SYNC = 'RESET_INVOICE_ABAS_SYNC';
export const UPDATE_ITEM_AMOUNT = 'UPDATE_ITEM_AMOUNT';
export const SPLIT_ITEM = 'SPLIT_ITEM';
export const CLEAR_INVOICE = 'CLEAR_INVOICE';
export const SUBMIT_INVOICE = 'SUBMIT_INVOICE';
export const SET_SUBMISSION_STATUS = 'SET_SUBMISSION_STATUS';
export const BOOK_INVOICE_IMMEDIATELY = 'BOOK_INVOICE_IMMEDIATELY';
export const SET_IMMEDIATE_BOOKING_PENDING = 'SET_IMMEDIATE_BOOKING_PENDING';
export const FETCH_INVOICE_DATA = 'FETCH_INVOICE_DATA';
export const SET_INVOICE_DATA = 'SET_INVOICE_DATA';
export const CLEAR_INVOICE_DATA = 'CLEAR_INVOICE_DATA';
