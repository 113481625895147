import * as TYPES from './types';

export const fetchProducts = () => ({
	type: TYPES.FETCH_PRODUCTS
});

export const fetchAllProducts = () => ({
	type: TYPES.FETCH_ALL_PRODUCTS
});

export const fetchLastProducts = () => ({
	type: TYPES.FETCH_LAST_PRODUCTS
});

export const setProducts = (payload) => ({
	type: TYPES.SET_PRODUCTS,
	payload
});

export const setLastProducts = (payload) => ({
	type: TYPES.SET_LAST_PRODUCTS,
	payload
});

export const setSelectedProductId = (payload) => ({
	type: TYPES.SET_SELECTED_PRODUCT_ID,
	payload
});

export const setActiveProduct = (payload) => ({
	type: TYPES.SET_ACTIVE_PRODUCT,
	payload
});

export const addProductToCart = (payload) => ({
	type: TYPES.ADD_PRODUCT_TO_CART,
	payload
});

export const setCart = (payload) => ({
	type: TYPES.SET_CART,
	payload
});

export const editProductInCart = (payload) => ({
	type: TYPES.EDIT_PRODUCT_IN_CART,
	payload
});

export const clearActiveSelection = () => ({
	type: TYPES.CLEAR_ACTIVE_SELECTION
});

export const deleteProductFromCart = (payload) => ({
	type: TYPES.DELETE_PRODUCT_FROM_CART,
	payload
});

export const cancelRide = () => ({
	type: TYPES.CANCEL_RIDE
});

export const uploadExcel = (payload) => ({
	type: TYPES.UPLOAD_EXCEL,
	payload
});

export const setExcelChanges = (payload) => ({
	type: TYPES.SET_EXCEL_CHANGES,
	payload
});

export const toggleExcelUpdatePending = () => ({
	type: TYPES.TOGGLE_EXCEL_UPDATE_PENDING
});

export const replaceProduct = (payload) => ({
	type: TYPES.REPLACE_PRODUCT,
	payload
});

export const acceptDatabase = () => ({
	type: TYPES.ACCEPT_DATABASE
});

export const downloadExcel = () => ({
	type: TYPES.DOWNLOAD_EXCEL
});
