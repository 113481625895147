import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
	ActionButtonContainer,
	AddArticleButton,
	AddArticleTextWrapper,
	AddProductWrapper,
	DeleteRideButton,
	DetailsLabel,
	Divider,
	FullyLoaded,
	ListId,
	ProductsListContainer,
	StartRideButton,
	SummaryScreenWrapper,
	TruckDataWrapper,
	TruckIdWrapper,
	TruckLabel
} from '../../styles/SummaryScreen/SummaryScreen';
import { DATABASE, MODAL, PATHS, SEARCH, STRINGS } from '../../../constants';
import Dropdown from '../Dropdown/Dropdown';
import { destinationActions, destinationSelectors } from '../../../state/ducks/destination';
import { authSelectors } from '../../../state/ducks/authentication';
import Lorry from '../Lorry/Lorry';
import { productsActions, productsSelectors } from '../../../state/ducks/products';
import SummaryListItem from './SummaryListItem';
import { ReactComponent as PlusIcon } from '../../../../icons/add_article.svg';
import { prepareRidePayload } from '../../../utils/requests/requestOperations';
import { trucksSelectors } from '../../../state/ducks/trucks';
import { ridesActions, ridesSelectors } from '../../../state/ducks/rides';
import { generalSelectors } from '../../../state/ducks/general';
import { modalActions } from '../../../state/ducks/modal';
import DelayedButton from '../Common/DelayedButton';
import { searchActions } from '../../../state/ducks/search';

const SummaryScreen = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const token = useSelector(authSelectors.getTruckToken);
	const truckName = useSelector(authSelectors.getTruckId);
	const truckId = useSelector(trucksSelectors.getAuthenticatedTruckId);
	const locations = useSelector(destinationSelectors.getAvailableStartLocations);
	const destinations = useSelector(destinationSelectors.getDestinations);
	const defaultStart = useSelector(destinationSelectors.getStartLocation);
	const cart = useSelector(productsSelectors.getCart);
	const isCartEmpty = useSelector(productsSelectors.isCartEmpty);
	const products = useSelector(productsSelectors.getProductsList);
	const isEditRide = useSelector(generalSelectors.isEditRide);
	const dbType = useSelector(generalSelectors.getDbType);
	const dbId = useSelector(generalSelectors.getDbId);
	const rideId = useSelector(generalSelectors.getRideId);
	const currentRideId = useSelector(ridesSelectors.getCurrentRideId);
	const timestamp = useSelector(ridesSelectors.getCurrentRideTimestamp);
	const isFullyLoaded = useSelector(productsSelectors.isFullyLoaded);
	const [startLocation, setStartLocation] = useState(defaultStart);
	const [isApproved, setIsApproved] = useState(false);
	const isMobileDevice = navigator.userAgent.includes('Android');

	const getProductForGivenId = (productId) =>
		products?.find(({ id }) => id === productId);

	const handleAddArticle = () => {
		setTimeout(() => {
			dispatch(productsActions.clearActiveSelection());
			history.push(PATHS.SEARCH);
		}, 200);
	};

	const handleEditRide = (productSelectionId) => {
		const product = cart.find(({ selectionId }) => selectionId === productSelectionId);

		dispatch(productsActions.setSelectedProductId({ selectedProductId: product.id }));
		dispatch(productsActions.setActiveProduct({ activeProduct: product }));
		history.push(`${PATHS.PRODUCTS}/${productSelectionId}`);
	};

	const handleDeleteArticle = (productSelectionId) => {
		dispatch(modalActions.showModal({
			modalType: MODAL.DELETE_PRODUCT,
			selectionId: productSelectionId
		}));
	};

	const handleStartRideClicked = () => {
		if (isApproved) return;
		setIsApproved(true);

		const ride = prepareRidePayload({
			truckId,
			startLocation,
			destinations,
			cart,
			timestamp,
			rideId: currentRideId,
			token,
			products
		});

		setTimeout(() => {
			if (dbType) {
				dispatch(ridesActions.removeFromDb({ dbType, dbId }));
			}

			if (isEditRide && dbType !== DATABASE.TYPE_POST) {
				dispatch(ridesActions.sendRideToIndexedDb({
					ride,
					db: DATABASE.DB_PUT
				}));
			} else {
				dispatch(ridesActions.sendRideToIndexedDb({
					ride,
					db: DATABASE.DB_POST
				}));
			}

			if (isEditRide) {
				history.push(PATHS.PREVIOUS_RIDES);
			} else {
				history.push(PATHS.RIDES_MENU);
			}
		}, 200);
	};

	const handleDeleteRide = () => {
		const rideData = {
			id: rideId,
			dbType,
			dbId
		};

		dispatch(modalActions.showModal({
			modalType: MODAL.DELETE_RIDE,
			rideData
		}));
	};

	const handleReplaceArticle = (selectionId) => {
		dispatch(searchActions.setSearchContext({
			context: SEARCH.REPLACE,
			selectionId
		}));
		dispatch(searchActions.setSearchValue({ searchValue: '' }));
		history.push(PATHS.SEARCH);
	};

	useEffect(() => {
		dispatch(destinationActions.setStartLocation({ startLocation }));
	}, [dispatch, startLocation]);

	return (
		<SummaryScreenWrapper>
			<TruckDataWrapper>
				<DetailsLabel>{STRINGS.START}</DetailsLabel>
				<Dropdown
					items={locations}
					initialValue={defaultStart}
					updateValue={setStartLocation}
					origin={'top left'}
					top={'4rem'}
					thin
					red
				/>
				<TruckIdWrapper>
					<TruckLabel>{truckName}</TruckLabel>
					{isFullyLoaded && <FullyLoaded>{STRINGS.FULLY_LOADED}</FullyLoaded>}
				</TruckIdWrapper>
				<Lorry/>
			</TruckDataWrapper>
			<Divider/>
			<ProductsListContainer>
				{cart?.map((item, index) => {
					const {
						length,
						width,
						height,
						article_nr: nr,
						is_ballen: isBallen,
						name
					} = getProductForGivenId(item.id);

					return (
						<SummaryListItem
							key={item.selectionId}
							item={item}
							name={name}
							nr={nr}
							index={index}
							length={length}
							width={width}
							height={height}
							ballen={isBallen}
							handleEditRide={handleEditRide}
							handleDeleteArticle={handleDeleteArticle}
							handleReplaceArticle={handleReplaceArticle}
						/>
					);
				})}
				{!isFullyLoaded && <>
					<AddProductWrapper>
						<ListId center>{cart?.length + 1}</ListId>
						<AddArticleButton onClick={handleAddArticle}>
							<PlusIcon/>
							<AddArticleTextWrapper>
								{STRINGS.ADD_ARTICLE}
							</AddArticleTextWrapper>
						</AddArticleButton>
					</AddProductWrapper>
					<Divider/>
				</>}
			</ProductsListContainer>
			{!isCartEmpty && <ActionButtonContainer>
				<StartRideButton
					onClick={!isMobileDevice ? handleStartRideClicked : null}
					onTouchEnd={isMobileDevice ? handleStartRideClicked : null}
				>
					{isEditRide ? STRINGS.APPROVE_CORRECTION : STRINGS.START_RIDE}
				</StartRideButton>
			</ActionButtonContainer>}
			{isCartEmpty && isEditRide && <ActionButtonContainer>
				<DelayedButton onClick={handleDeleteRide} component={DeleteRideButton}>
					{STRINGS.DELETE_RIDE}
				</DelayedButton>
			</ActionButtonContainer>}
		</SummaryScreenWrapper>
	);
};

export default SummaryScreen;
