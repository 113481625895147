import styled from 'styled-components';
import { ModalContent, ModalText } from './Modal';
import { BarcodeField, ScannerImage } from '../ScannerScreen/ScannerScreen';

export const ModalCheckDeliveryContent = styled(ModalContent)`
  height: 51rem;
  
  ${ScannerImage} {
    width: 11rem;
    margin-top: 4rem;
  }

  ${ModalText} {
    margin-top: 8rem;
  }
  
  ${BarcodeField} {
    margin-top: 4rem;
  }
`;

export const CheckDeliveryStatusBar = styled.div`
  display: flex;
  gap: 5rem;
  margin-top: 3rem;
`;

export const DeliveryStatus = styled.div`
  display: flex;
  align-items: center;
  font-weight: 400;
  font-family: Roboto, sans-serif;
  font-size: 3.5rem;
  gap: 1rem;
`;

export const StatusIcon = styled.img`
  width: 3.5rem;
  height: 3.5rem;
`;
