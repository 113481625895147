import reducer from './reducers';

import * as trucksActions from './actions';
import * as trucksTypes from './types';
import * as trucksSelectors from './selectors';
import * as trucksOperations from './operations';

export {
	trucksActions,
	trucksTypes,
	trucksSelectors,
	trucksOperations
};

export default reducer;
