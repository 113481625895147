import reducer from './reducers';

import * as productsActions from './actions';
import * as productsTypes from './types';
import * as productsSelectors from './selectors';
import * as productsOperations from './operations';

export {
	productsActions,
	productsTypes,
	productsSelectors,
	productsOperations
};

export default reducer;
