import React from 'react';
import { useDispatch } from 'react-redux';
import {
	AllDetails,
	DescriptionHeader,
	DescriptionTitle,
	Divider,
	TimeLabel,
	TimeSlotContainer,
	TimeSlotHeader
} from '../../styles/ReportsScreen/ReportsScreen';
import { STRINGS } from '../../../constants';
import RidesData from './RidesData';
import { reportsActions } from '../../../state/ducks/reports';

const TimeSlot = ({
	timeSlot,
	lkwCount,
	rideCount,
	ridesData
}) => {
	const dispatch = useDispatch();

	const toggleAllDetails = () => {
		dispatch(reportsActions.toggleAllDetails({ timeSlot }));
	};

	return (
		<TimeSlotContainer>
			<Divider/>
			<TimeSlotHeader>
				<TimeLabel>{`${timeSlot} ${STRINGS.HOUR}`}</TimeLabel>
				{!!ridesData.length && <AllDetails onClick={toggleAllDetails}>
					{STRINGS.ALL_DETAILS}
				</AllDetails>}
			</TimeSlotHeader>
			{!!ridesData.length && <DescriptionHeader>
				<DescriptionTitle>{STRINGS.LKW}</DescriptionTitle>
				<DescriptionTitle>{STRINGS.RIDES}</DescriptionTitle>
				<DescriptionTitle>
					{`${rideCount} ${STRINGS.RIDES} | ${lkwCount} ${STRINGS.LKW}`}
				</DescriptionTitle>
			</DescriptionHeader>}
			{ridesData.map((item) => {
				return <RidesData
					key={item.truck_name}
					truckName={item.truck_name}
					rides={item.rides}
					timeSlot={timeSlot}
				/>;
			})}
		</TimeSlotContainer>
	);
};

export default TimeSlot;
