import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { includes } from 'ramda';
import {
	AmountButton,
	ApplyButton,
	CorrectionItemRow,
	CorrectionItemsContainer,
	CorrectionScreenWrapper,
	Divider,
	DropdownWrapper,
	ItemBold,
	ItemLight,
	NoteArea,
	ProductSelectionWrapper,
	ReasonButton,
	ReasonButtonsContainer,
	ReplaceButton,
	ReportsButton,
	SelectArticleButton,
	SelectedProduct
} from '../../styles/CorrectionScreen/CorrectionScreen';
import { GENERAL, MODAL, PATHS, REPORTS, STRINGS } from '../../../constants';
import { deliveriesSelectors } from '../../../state/ducks/deliveries';
import { ReactComponent as PlusIcon } from '../../../../icons/add.svg';
import { ReactComponent as MinusIcon } from '../../../../icons/minus.svg';
import { ReactComponent as LoupeIcon } from '../../../../icons/loupe.svg';
import { ReactComponent as PenIcon } from '../../../../icons/pen.svg';
import { ReactComponent as ReportIcon } from '../../../../icons/report.svg';
import { reportsActions, reportsSelectors } from '../../../state/ducks/reports';
import { generalActions } from '../../../state/ducks/general';
import { productsActions } from '../../../state/ducks/products';
import { searchActions } from '../../../state/ducks/search';
import DelayedButton from '../Common/DelayedButton';
import { destinationSelectors } from '../../../state/ducks/destination';
import { ReactComponent as FilterDownArrow } from '../../../../icons/filter_down_arrow.svg';
import DropdownCustom from '../Dropdown/DropdownCustom';
import { modalActions } from '../../../state/ducks/modal';

const CorrectionScreen = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const location = useSelector(deliveriesSelectors.getDeliveryLocation);
	const destinations = useSelector(destinationSelectors.getDestinationsList);
	const correctionReason = useSelector(reportsSelectors.getReason);
	const endLocation = useSelector(reportsSelectors.getEndLocation);
	const unit = useSelector(reportsSelectors.getUnit);
	const amount = useSelector(reportsSelectors.getAmount);
	const product = useSelector(reportsSelectors.getProduct);
	const customNote = useSelector(reportsSelectors.getCustomNote);
	const allDetailsProvided = useSelector(reportsSelectors.allCorrectionDetailsProvided);
	const [applyInteracted, setApplyInteracted] = useState(false);
	const applyRef = useRef(null);
	const {
		name,
		name_short: shortName,
		height,
		length,
		width,
		article_nr: nr
	} = product || {};

	const reasons = [
		REPORTS.REASON_WAREHOUSE,
		REPORTS.REASON_DAMAGE,
		REPORTS.REASON_DELIVERY
	];

	const getDisplayedUnit = () => {
		switch (unit) {
			case STRINGS.PAL_KEY:
				return STRINGS.PALLETS;
			default:
				return unit;
		}
	};

	const showMinus = useMemo(() => {
		return correctionReason && correctionReason !== REPORTS.REASON_DELIVERY;
	}, [correctionReason]);

	const handleReasonSelected = (value) => {
		dispatch(reportsActions.setCorrection({
			[REPORTS.CORRECTION_REASON]: value
		}));
	};

	const handleArticleSelection = () => {
		dispatch(generalActions.setRideContext({ rideContext: GENERAL.CONTEXT_REPORTS }));
		dispatch(searchActions.setSearchValue({ searchValue: '' }));
		history.push(PATHS.SEARCH_CORRECTION);
	};

	const updateEndWarehouse = (value) => {
		dispatch(reportsActions.setCorrection({
			[REPORTS.CORRECTION_END_WAREHOUSE]: value
		}));
	};

	const handleApplyClicked = () => {
		setApplyInteracted(true);

		if (allDetailsProvided) {
			dispatch(reportsActions.sendCorrection());
			setApplyInteracted(false);
			applyRef.current.blur();
		} else {
			setTimeout(() => {
				applyRef.current.focus();
			}, 0);
		}
	};

	const toggleReportsModal = () => {
		dispatch(modalActions.showModal({ modalType: MODAL.CORRECTION_REPORTS }));
	};

	const handleNoteChanged = (event) => {
		const { value } = event.target;

		dispatch(reportsActions.setCorrection({
			[REPORTS.CORRECTION_CUSTOM_NOTE]: value
		}));
	};

	const ButtonApply = () => <ApplyButton ref={applyRef} onClick={handleApplyClicked}>
		{STRINGS.PROCEED_WITH_CORRECTION}
	</ApplyButton>;

	useEffect(() => {
		dispatch(productsActions.fetchAllProducts());
	}, [dispatch]);

	return (
		<CorrectionScreenWrapper>
			<CorrectionItemsContainer>
				<CorrectionItemRow>
					<ItemLight>{STRINGS.START_CORRECTION_WAREHOUSE}:</ItemLight>
					<ItemBold>{location}</ItemBold>
					<DelayedButton
						onClick={toggleReportsModal}
						component={ReportsButton}
					>
						<ReportIcon/>
						{STRINGS.REPORTING}
					</DelayedButton>
				</CorrectionItemRow>
				<Divider/>
				<CorrectionItemRow top>
					<ItemLight>{STRINGS.REASON}:</ItemLight>
					<ReasonButtonsContainer>
						{reasons.map((reason) => (
								<ReasonButton
									key={reason}
									selected={correctionReason === reason}
									invalid={applyInteracted && !correctionReason}
									onClick={() => handleReasonSelected(reason)}
									wide={reason === REPORTS.REASON_DELIVERY}
								>
									<AmountButton
										selected={correctionReason === reason}
										invalid={applyInteracted && !correctionReason}
									>
										<MinusIcon/>
									</AmountButton>
									{reason === REPORTS.REASON_DELIVERY && <AmountButton
										selected={correctionReason === reason}
										invalid={applyInteracted && !correctionReason}
									>
										<PlusIcon/>
									</AmountButton>}
									{reason}
								</ReasonButton>
							)
						)}
					</ReasonButtonsContainer>
				</CorrectionItemRow>
				<Divider/>
				<CorrectionItemRow top>
					<ProductSelectionWrapper>
						<ItemLight>{STRINGS.ARTICLES}:</ItemLight>
						{product
							? <SelectedProduct>
								<ItemBold wide col={'1 / 3'}>{name}</ItemBold>
								<ItemLight wide col={'1 / 3'}>{shortName}</ItemLight>
								<ItemBold>{`${height}x${length}x${width}`}</ItemBold>
								<ItemLight>{nr}</ItemLight>
							</SelectedProduct>
							: <SelectArticleButton
								invalid={applyInteracted && !product}
								onClick={handleArticleSelection}
							>
								<div><LoupeIcon/></div>
								{STRINGS.ARTICLE_SELECTION}
							</SelectArticleButton>}
						{product && <ReplaceButton onClick={handleArticleSelection}>
							<PenIcon/>
						</ReplaceButton>}
					</ProductSelectionWrapper>
				</CorrectionItemRow>
				{product && <CorrectionItemRow removeMarginTop>
					<ItemLight>{getDisplayedUnit()?.toLowerCase()}:</ItemLight>
					<ItemLight>{`${showMinus ? '- ' : ''}${amount}`}</ItemLight>
				</CorrectionItemRow>}
				<Divider/>
				{correctionReason === REPORTS.REASON_DELIVERY && <>
					<CorrectionItemRow>
						<ItemLight>{STRINGS.END_CORRECTION_WAREHOUSE}:</ItemLight>
						<DropdownWrapper invalid={!endLocation}>
							<DropdownCustom
								items={destinations}
								placeholder={STRINGS.WAREHOUSE}
								initialValue={endLocation}
								updateValue={updateEndWarehouse}
								downArrow={FilterDownArrow}
							/>
						</DropdownWrapper>
					</CorrectionItemRow>
					<Divider/>
				</>}
				{includes(correctionReason, [
					REPORTS.REASON_DAMAGE,
					REPORTS.REASON_WAREHOUSE
				]) && <>
					<CorrectionItemRow top>
						<ItemLight>{STRINGS.NOTE}:</ItemLight>
						<NoteArea
							placeholder={STRINGS.PROVIDE_NOTE}
							value={customNote}
							onChange={handleNoteChanged}
						/>
					</CorrectionItemRow>
					<Divider/>
				</>}
			</CorrectionItemsContainer>
			<DelayedButton component={ButtonApply}/>
		</CorrectionScreenWrapper>
	);
};

export default CorrectionScreen;
