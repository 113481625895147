import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { authSelectors } from '../../../state/ducks/authentication';
import { PATHS } from '../../../constants';

const PrivateRoute = ({ component: Component, ...rest }) => {
	const token = useSelector(authSelectors.getToken);

	return (
		<Route
			{...rest}
			render={(routeProps) =>
				token
					? <Component {...routeProps}/>
					: <Redirect to={PATHS.LOGIN}/>
			}
		/>
	);
};

export default PrivateRoute;
